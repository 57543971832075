import * as t from '@/store/mutations'
import http from '@/http'
const INCLUDE_COMPANY_FIELD_WITH_REGISTRATION = ['home_state']
const STATE = {
  company: {},
  schema: null,
  processedCompanyDetails: {},
}

const GETTERS =  {
  company: (state, _getters, _rootState, rootGetters) => {
    return { ...rootGetters['companies/currentCompany'], details: state.processedCompanyDetails }
  },
  schema:  state => state.schema,

  peopleSchema: (state, getters) => {
    const schema = state.schema || []
    let filteredSchema = schema.filter(field => field.meta && field.meta.sub_type === 'person') || []

    if (getters.company?.details?.management_type?.toLowerCase() !== 'manager managed') {
      filteredSchema = filteredSchema.filter(field => field.name !== 'official.manager')
    }

    return filteredSchema
  },
  registrationSchema: state => {
    const schema = state.schema || []
    return schema.filter(field =>
      (field.corptools_glossary_term_id &&
      field.corptools_glossary_term_data_store === 'registration' &&
      field.meta?.sub_type !== 'person') ||
      INCLUDE_COMPANY_FIELD_WITH_REGISTRATION.includes(field.name)
    ) || []
  },
  companySchema: state => {
    const schema = state.schema || []
    return schema.filter(field =>
      field.corptools_glossary_term_id &&
      field.corptools_glossary_term_data_store === 'company' &&
      field.meta?.sub_type !== 'person' &&
      !INCLUDE_COMPANY_FIELD_WITH_REGISTRATION.includes(field.name)
    ) || []
  },
}

const ACTIONS = {
  async loadCompanyDetailSchema({ commit, getters }) {
    try {
      const response = await http.get(`client/companies/${getters.company.id}/details_schema`)
      let schema = response.data.result
      commit(t.SET_COMPANY_OVERVIEW_DETAILS_SCHEMA, schema)
    } catch (error) {
      console.log(error)
    }
  },
  async processCompanyDetails({ commit, getters }) {
    try {
      const response =
        await http.get(`client/companies/${getters.company.id}/process_company_detail_hints`)
      let details = response.data.result.details
      commit(t.SET_PROCESSED_COMPANY_DETAILS, details)
    } catch (error) {
      console.log(error)
    }
  },
}

const MUTATIONS = {
  [t.SET_COMPANY_OVERVIEW_DETAILS_SCHEMA](state, schema) {
    state.schema = schema
  },
  [t.SET_PROCESSED_COMPANY_DETAILS](state, details) {
    state.processedCompanyDetails = details
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
