<template>
  <div class="ct-file-drop">
    <div
      v-if="loaded"
      class="dropzone-container"
      :class="{ dragging, filesUploading }"
      @dragover.prevent="dragover"
      @dragleave="dragleave"
      @drop.prevent="uploadFiles"
    >
      <input
        id="file-input"
        ref="file-input"
        type="file"
        name="file-input"
        :accept="acceptableFileTypes.join(',')"
        @change.prevent="uploadFiles"
      >

      <label for="file-input" class="file-label">
        <div class="label-content">
          <template v-if="files && files.length <= 0">
            <div v-if="dragging">Release to drop files here.</div>
            <div v-if="!dragging && !filesUploading" class="d-flex justify-content-center align-items-center flex-column">
              <div class="file-icon-container">
                <feather-icon type="file-text" width="74" height="74" :stroke-width="1.2" />
                <div class="ct-plus">
                  +
                </div>
              </div>
              <span class="ct-link">Select document to upload</span>
              <span v-show="!['xs', 'sm'].includes($mq)">Or drag and drop document here</span>
            </div>

            <div v-if="filesUploading" class="uploading">
              <feather-icon type="upload-cloud" height="80" width="80" stroke="#000864" />
              <div>Uploading file...</div>
              <ct-fake-loading-bar
                class="loading-bar"
                :seconds-estimate="secondsEstimate"
                :actually-complete="!filesUploading && filesUploaded"
                @progress-bar-complete="progressBarComplete = true"
              />
            </div>
          </template>
        </div>
      </label>
    </div>
    <ct-centered-spinner v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '../../mixins/makeToastMixin'

export default {
  name: 'CtFileDrop',
  components: {
    CtCenteredSpinner:  () => import('@/components/shared/CtCenteredSpinner'),
    CtFakeLoadingBar:   () => import('@/components/shared/CtFakeLoadingBar'),
    FeatherIcon:        () => import('@/components/shared/FeatherIcon'),
  },
  mixins: [
    makeToastMixin,
  ],
  props: {
    maxSizeKb: {
      type: Number,
      default: 1024,
    },
    secondsEstimate: {
      type: Number,
      default: 8,
    },
    acceptableFileTypes: {
      type: Array,
      default: () => [],
    },
    fileTypeErrorText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loaded: false,
      dragging: false,
      files: [],
      progressBarComplete: false,
    }
  },
  computed: {
    ...mapGetters('fileDrop', [
      'filesUploading',
      'filesUploaded',
    ]),
    acceptableFileTypeRegex() {
      return `^(${this.acceptableFileTypes.join('|')})`
    },
  },
  mounted() {
    this.setFilesUploading(false)

    this.loaded = true
  },
  methods: {
    ...mapActions('fileDrop', [
      'resetFileDrop',
      'setFilesUploading',
      'setFilesUploaded',
    ]),
    openFileDialogBox() {
      this.$refs['file-input'].click()
    },
    dragover() {
      this.dragging = true
    },
    dragleave() {
      this.dragging = false
    },
    async uploadFiles(e) {
      this.dragging = false

      const files = e.dataTransfer?.files || e.currentTarget?.files

      let reset = false

      // Prevent multiple files from being uploaded
      if ([...files].length > 1) {
        this.errorToast('Error', 'Multiple files not allowed.')
        reset = true
      }

      // Check file sizes. Array allows js enumerable methods
      if ([...files].some(file => (file.size / 1000) > this.maxSizeKb)) {
        const limit = Math.floor(this.maxSizeKb / 1024 * 10) / 10
        this.errorToast('Error', `Files must be ${limit}MB or smaller.`)
        reset = true
      }

      // Check file types. Array allows js enumerable methods
      if ([...files].some(file => !file.type.match(this.acceptableFileTypeRegex))) {
        this.errorToast('Error', this.fileTypeErrorText)
        reset = true
      }

      if (reset) {
        this.resetFileDrop()
        return
      }

      // can cause recursive event calling otherwise, on old Firefox / Safari versions
      if (this.$refs['file-input'].files.length === 0) this.$refs['file-input'].files = files

      this.setFilesUploaded(false)
      this.setFilesUploading(true)

      this.$emit('dropped', [...files])
    },
    resetFileDrop() {
      this.setFilesUploaded(false)
      this.setFilesUploading(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.ct-file-drop {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  .dropzone-container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 600px;
    min-height: 240px;
    max-height: 400px;
    background: #f5f5f5;
    border: 1px solid #bbbbbb;
    border-radius: 6px;
    &.dragging {
      background: $ct-ui-primary-light;
      border: 2px solid $ct-ui-primary;
    }
    &.filesUploading {
      pointer-events: none;
    }
    #file-input {
      opacity: 0;
      overflow: hidden;
      position: absolute;
      width: 1px;
      height: 1px;
    }
    .file-label {
      font-size: 20px;
      display: block;
      cursor: pointer;
      .label-content {
        .file-icon-container {
          position: relative;
          width: fit-content;
          height: fit-content;
        }
        .uploading {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          height: 150px;
        }
      }
    }
  }

  .preview-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .preview-card {
      max-height: 400px;
      padding: 10px;
      .preview-img {
        height: 100%;
        border-radius: 5px;
        background-color: #a2a2a2;
      }
    }
  }

  .slide-viewer-button-row {
    .btn-default {
      border: 2px $ct-ui-primary solid !important;
    }
  }

  ::v-deep .ct-plus {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $ct-ui-primary;
    position: absolute;
    bottom: -1px;
    right: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1em;
    font-weight: 800;
  }
  ::v-deep .ct-link {
    font-weight: 800;
    color: $ct-ui-primary;
    text-decoration: underline;
  }
  ::v-deep .progress {
    width: 342px;
    .progress-bar {
      background: $ct-ui-color-6;
    }
  }
}
</style>
