import * as t from '../mutations'
import http from '@/http'
import Vue from 'vue'
import {
  createOrFindClientInteractionLog,
  logInteractionByTypeAndName,
  logProgress,
} from '@/common/modules/clientInteractionLog'

const VERIFY_ORDER_SECTIONS = [
  'company_name_lookup',
  'business_address',
  'verify_data',
  'processing_time',
  'business_identity',
  'additional_products',
  'submit',
]

const initialState = () => ({
  allUpsellOptions: [],
  orderRequiringVerification: null,
  optionalCategories: [],
  ordersRequiringVerificationLoaded: false,
  progress: null,
  redirected: false,
  sections: VERIFY_ORDER_SECTIONS,
  upsellOption: null,
  verifyOrderInteraction: null,
  verifyOrderInteractionVersion: '',
  orderVerificationRequiredCount: 0,
  dependentFilingsFilingMethod: null,
  privateBusinessAddress: null,
  vouchers: [],
})

const STATE = initialState()

const GETTERS = {
  sections: state => state.sections,
  company: (_state, getters, _rootState, rootGetters) => rootGetters['companies/currentCompany'],
  selectedPaymentMethod: (_state, getters, _rootState, rootGetters) => rootGetters['paymentMethods/selectedPaymentMethod'],
  internalProcessingOverride: (_state, _getters, _rootState, rootGetters) => rootGetters['website/website']?.internal_processing_override,
  orderRequiringVerification: state => state.orderRequiringVerification,
  vouchers:  (_state,getters) => getters.orderRequiringVerification?.vouchers || [],
  orderItemsRequiringAttentionCount: (_state, getters, _rootState, rootGetters) => rootGetters['dashpanel/orderItemsRequiringAttentionCount'],
  verifyOrderInteraction: (state, _getters) => state.verifyOrderInteraction,
  verifyOrderInteractionVersion: (state, _getters) => state.verifyOrderInteraction?.version,
  dependentFilingsFilingMethod: (state, _getters) => state.dependentFilingsFilingMethod,
  progress: (state, _getters) => state.progress,
  currentSection: (_state, getters) => getters.progress?.current_section,
  completedSections: (_state, getters) => getters.progress?.completed_sections || [],
  currentObject: (_state, _getters, _rootState, rootGetters) => rootGetters['schema/currentObject'],
  currentSectionIndex: (_state, getters) => getters.sections.findIndex(section => section === getters.currentSection),
  lastSection: (_state, getters) => getters.sections.at(-1),
  sectionIsLast: (_state, getters) => getters.lastSection === getters.currentSectionIndex,
  sectionIsFirst: (_state, getters) => getters.currentSectionIndex === 0,
  sectionIsComplete: (_state, getters) => section => getters.completedSections.some(s => s === section),
  sectionsRequiredForSubmit: (_state, getters) => getters.sections.slice(0, -1),
  requiredSectionsComplete: (_state, getters) => getters.sectionsRequiredForSubmit.every(section => getters.completedSections.includes(section)),
  redirected: state => state.redirected,
  loadingOrdersRequiringVerification: state => state.loadingOrdersRequiringVerification,
  optionalCategories: state => state.optionalCategories,
  allUpsellOptions: state => state.allUpsellOptions,
  upsellOption: (state, getters) => {
    if (getters.allUpsellOptions?.length) {
      return getters.allUpsellOptions.length > 1 ?
        getters.allUpsellOptions?.reduce((prev, current) => (prev.price > current.price) ? prev : current) :
        getters.allUpsellOptions[0]
    }
  },
  privateBusinessAddress: (_state, getters) => getters.orderRequiringVerification?.private_business_address || null,
  upsellOptionCartObject: (_state, getters) => {
    const data = {}
    if (getters.orderRequiringVerificationType === 'ShoppingBasketItem')  {
      data.shopping_cart_filing_product_id = getters.orderRequiringVerification.product.id
    } else {
      data.order_item_id = getters.orderRequiringVerification.id
    }

    return {
      id: getters.upsellOption?.id,
      data: data,
      displayInfo:  getters.upsellOption?.name,
      kind: 'OrderItemUpsell',
      name: getters.upsellOption?.name || '',
    }
  },
  upsellProductInCart: (_state, getters, _rootState, rootGetters) => {
    return rootGetters['checkout/productIdInCart'](getters.upsellOption?.id)
  },
  showSection: (_state, getters) => section => getters.sections.includes(section),
  sectionIsDisabled: (_state, getters) => section => {
    if (getters.sections.indexOf(section) === 0) return false

    const previousSection = getters.sections[getters.sections.indexOf(section) - 1]
    return (section === getters.lastSection) ?
      !getters.requiredSectionsComplete :
      !getters.sectionIsComplete(previousSection)
  },
  standardInternalProcessingDays: (_state, getters) => {
    if (!getters.currentObject) return ''

    const defaultDays = 5
    const internalProcessingTime = getters.currentObject?.product?.filing_method?.internal_processing_time || 0
    return internalProcessingTime?.days > defaultDays
      ? internalProcessingTime?.days
      : defaultDays
  },
  stateProcessingDays: (_state, getters) => {
    if (!getters.currentObject) return ''

    const filedIn = getters.currentObject?.product?.filing_method?.filed_in
    return getters.filedInProcessingDays(filedIn)
  },
  dependentFilingStateProcessingDays: (_state, getters) => {
    if (!getters.dependentFilingsFilingMethod) return 0

    const filedIn = getters.dependentFilingsFilingMethod?.filed_in
    return getters.filedInProcessingDays(filedIn)
  },
  filedInProcessingDays: (_state) => (filedIn) => {
    return filedIn?.hours > 0
      ? 1
      : filedIn?.days || 0
  },
  stateProcessingWithDependentFilingDate: (_state, getters) => {
    return getters.processDate(getters.dependentFilingStateProcessingDays || 0)
  },
  expectedFileByDate: (_state, getters) => {
    if (!getters.dependentFilingStateProcessingDays || !getters.standardInternalProcessingDays) {
      return getters.standardInternalProcessingDate
    }

    const days = getters.standardInternalProcessingDays + getters.dependentFilingStateProcessingDays
    return getters.processDate(days)
  },
  expeditedFileByDate: (_state, getters) => {
    if (!getters.upsellInternalProcessingDays) return ''

    const days = getters.upsellInternalProcessingDays + getters.dependentFilingStateProcessingDays
    return getters.processDate(days)
  },
  upsellInternalProcessingDays: (_state, getters) => {
    return getters?.upsellOption
      ? getters.upsellOption?.number_days
      : ''
  },
  internalProcessingOverrideDays: (_state, getters) => {
    if (!getters.internalProcessingOverride) return null

    return getters.internalProcessingOverride.days +
      (getters.internalProcessingOverride.hours > 0 ? 1 : 0)
  },
  processDate: (_state, getters) => (days = 0) => {
    if(!getters.stateProcessingDays) return ''

    const totalDays = getters.stateProcessingDays + days
    return getters.dateOfBusinessDaysFromNow(totalDays).toString()
  },
  standardInternalProcessingDate: (_state, getters) => {
    return getters.standardInternalProcessingDays ?
      getters.processDate(getters.standardInternalProcessingDays) : ''
  },
  // Dynamic, based on whether upsell product is in cart
  expectedInternalProcessingDays: (_state, getters) => {
    return getters.internalProcessingOverride ? getters.internalProcessingOverrideDays
      : getters.upsellProductInCart ? getters.upsellInternalProcessingDays
        : getters.standardInternalProcessingDays
  },
  // Dynamic, based on whether upsell product is in cart
  expectedProcessingDate: (_state, getters) => {
    if (getters.stateProcessingDays === '' || getters.expectedInternalProcessingDays === '') return ''

    const totalDays = getters.stateProcessingDays + getters.expectedInternalProcessingDays + getters.dependentFilingStateProcessingDays
    return getters.dateOfBusinessDaysFromNow(totalDays).toString()
  },
  dateOfBusinessDaysFromNow: (_state) => days => {
    const isWeekend = (date) => { return [0, 6].includes(date.getDay()) }
    const nextBusinessDay = (date) => {
      const nextDay = new Date(date.getTime())
      const nextMonday = new Date(date.getTime())
      nextDay.setDate(date.getDate() + 1)
      nextMonday.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7)
      return isWeekend(nextDay) ? nextMonday : nextDay
    }

    let futureDate = new Date()
    if (isWeekend(futureDate)) futureDate = nextBusinessDay(futureDate)
    while (days > 0) {
      futureDate = nextBusinessDay(futureDate)
      days--
    }

    const dateString = futureDate.toLocaleString('default', { month: 'long', day: 'numeric' })
    const nth = () => {
      const date = parseInt(dateString.slice(-2))
      if (date >= 4 && date <= 20) return 'th'
      switch (date % 10) {
        case 1:  return "st"
        case 2:  return "nd"
        case 3:  return "rd"
        default: return "th"
      }
    }
    return dateString + nth()
  },
  ghostMode: (_state, _getters, _rootState, rootGetters) => rootGetters['stageline/ghostMode'],
  orderRequiringVerificationType: (_state, getters) => {
    return getters.orderRequiringVerification?.type === 'shopping-cart-filing' ?
      'ShoppingBasketItem' :
      'OrderItem'
  },
  voucherByProductCategory: (_state, getters) => productCategory => {
    return getters.vouchers.find(voucher => voucher.product.category === productCategory)
  },
}

const ACTIONS = {
  async loadLatestOrderRequiringVerification({ commit, dispatch }, { companyId = null, filingName = null, orderItemName = null }) {
    let params = {}
    if (companyId != null)     params.company_id  = companyId
    if (filingName != null)    params.filing_name = filingName

    const response = await http.get('client/order_items/order_verification_required',{
      params: params,
    })

    if (response?.data?.success && response.data.result.length) {
      const orderItems = response.data.result
      const result = orderItemName != null
        ? orderItems.find(orderItem => orderItem.name === orderItemName) || orderItems[0]
        : orderItems[0]

      if (!result?.private_business_address) dispatch('filterOutSection', 'business_address')

      const filingName =  result?.product.filing_name.toLowerCase()
      if (filingName !== 'form a company')  dispatch('filterOutSection', 'company_name_lookup')

      commit(t.SET_ORDER_REQUIRING_VERIFICATION, result)

      if (companyId === null) {
        await dispatch('companies/setCurrentCompany', { id: result.company.id, force: true }, { root: true })
      }
    } else {
      commit(t.SET_ORDER_REQUIRING_VERIFICATION, null)
    }
    commit(t.SET_ORDERS_REQUIRING_VERIFICATION_LOADED, true)
  },

  setOrderRequiringVerification({ commit, dispatch }, orderRequiringVerification) {
    if (orderRequiringVerification) {
      commit(t.SET_ORDER_REQUIRING_VERIFICATION, orderRequiringVerification)

      if (!orderRequiringVerification?.private_business_address) {
        dispatch('filterOutSection', 'business_address')
      }

      commit(t.SET_ORDERS_REQUIRING_VERIFICATION_LOADED, true)
    }
  },

  async loadDependentFilingMethod({ commit, getters }) {
    if (!getters.currentObject) return

    try {
      const response = await http.get('client/products/filing_products/dependent_filings_filing_method', {
        params: {
          filing_method_id: getters.currentObject?.product?.filing_method.id,
          company_id: getters.company.id,
        },
      })
      commit(t.SET_DEPENDENT_FILINGS_FILING_METHOD, response?.data?.dependent_filing_info)
    } catch(error) {
      // Just suppressing the error
    }
  },

  async loadVerifyOrderInteraction({ commit, getters, _rootState }) {
    const normalizedSubCategory =  getters.orderRequiringVerification.product.filing_name.toLowerCase().replace(/\s+/g, '-')
    const params = {
      category: 'verify-order',
      subCategory: normalizedSubCategory,
      objectId: getters.orderRequiringVerification.id,
      objectTable: getters.orderRequiringVerificationType,
      companyId: getters.company.id,
    }

    const verifyOrderInteraction = await createOrFindClientInteractionLog(params)

    if (verifyOrderInteraction) commit(t.SET_VERIFY_ORDER_INTERACTION, verifyOrderInteraction)
    if (verifyOrderInteraction?.progress) commit(t.SET_VERIFY_ORDER_PROGRESS, verifyOrderInteraction.progress)
  },

  async loadOrderItemUpsells({ commit, getters, dispatch }) {
    const response = await http.get('client/products/available_upsells', {
      params: {
        id: getters.orderRequiringVerification.product.id,
        company_id: getters.company.id,
      },
    })

    if (response.data.order_item_upsells.length) {
      commit(t.SET_UPSELL_OPTIONS, response.data.order_item_upsells)
    } else {
      dispatch('filterOutSection', 'processing_time')
    }
  },

  async loadOptionalCategories({ commit, getters, dispatch }) {
    let optionalCategories = []
    if (getters.orderRequiringVerification?.optional_categories) {
      optionalCategories = getters.orderRequiringVerification.optional_categories
    } else {
      const response = await http.get('client/products/optional_categories', {
        params: {
          id: getters.orderRequiringVerification.product.id,
          company_id: getters.company.id,
        },
      })
      if (response.data.optional_categories) optionalCategories = response.data.optional_categories
    }
    if (!optionalCategories.length) {
      dispatch('filterOutSection', 'additional_products')
    }

    commit(t.SET_VERIFY_ORDER_OPTIONAL_CATEGORIES, optionalCategories)
  },

  async loadBusinessIdentity({ dispatch, getters, rootGetters }) {
    const businessIdentity = 'Business Identity'
    const sectionName = businessIdentity.toLowerCase().split(' ').join('_')
    const orderItem = getters.orderRequiringVerification
    const hasNoBundleAvailable = !rootGetters['checkout/findProductBy']('product_bundle', { 'product_categorization.subcategory' : sectionName })
    const hasActiveProductBundle = rootGetters['companies/activeProductBundles']?.some(bundle => bundle?.product_categorization?.subcategory === sectionName)
    const isNotFormation = orderItem.product.filing_name.toLowerCase() !== 'form a company'
    const orderId = orderItem?.order_id
    let invoiceHasPhoneService = false

    const fetchPhoneService = async (orderId) => {
      if (orderId) {
        try {
          const response = await http.get(`client/orders/${orderId}/order_has_phone_service`)
          invoiceHasPhoneService = response.status === 200 ? response.data.has_phone_service : false
        } catch (error) {
          // Just suppressing the error
        }
      }
    }

    await fetchPhoneService(orderId)

    if (hasNoBundleAvailable || hasActiveProductBundle || isNotFormation || invoiceHasPhoneService) dispatch('filterOutSection', sectionName)
  },

  async filterOutSection({ commit, getters }, section) {
    const filteredSections = getters.sections.filter(s => s !== section)
    commit(t.SET_VERIFY_ORDER_SECTIONS, filteredSections)
  },

  async logVerifyOrderInteraction({ getters }, name) {
    if (getters.ghostMode) return

    if (getters.verifyOrderInteraction) {
      await logInteractionByTypeAndName({
        id: getters.verifyOrderInteraction.id,
        type: 'button',
        name: name,
      })
    }
  },

  async updateVerifyOrderInteraction({ commit, getters }) {
    if (getters.ghostMode) return

    const result = await logProgress({
      clientInteractionLogId: getters.verifyOrderInteraction.id,
      progress: getters.progress,
    })

    if (result?.progress) {
      commit(t.SET_VERIFY_ORDER_PROGRESS, result.progress)
    }
  },

  async completeUpsell({ getters, rootGetters, commit }) {
    if (getters.ghostMode) return

    try {
      const result = await http.post(`client/client_interaction_logs/${getters.verifyOrderInteraction.id}/complete_verify_order/`, {
        token: !rootGetters['checkout/cartEmpty'] && getters.selectedPaymentMethod ? getters.selectedPaymentMethod.id : null,
        orderItemUpsellId: getters.upsellProductInCart ? getters.upsellOption?.id : null,
        isComplete: true,
        origin: 'verify-order',
      })

      const verifyOrderInteraction = getters.verifyOrderInteraction
      verifyOrderInteraction.completed_at = result.data.response.completed_at

      commit(t.SET_VERIFY_ORDER_INTERACTION, verifyOrderInteraction)

      return result.data
    } catch(error) {
      return error
    }
  },

  setCurrentSection({ commit, dispatch, getters }, section) {
    if (section !== getters.currentSection) {
      commit(t.SET_VERIFY_ORDER_CURRENT_SECTION, section)
      dispatch('updateVerifyOrderInteraction')
    }
  },

  setNextSection({ dispatch, getters }) {
    if (!getters.sectionIsLast) {
      dispatch('setCurrentSection', getters.sections[getters.currentSectionIndex + 1])
    }
  },


  markSectionAsComplete({ getters, dispatch, commit }, section) {
    let completedSections = getters.completedSections

    if (!completedSections.includes(section)) {
      completedSections.push(section)
      commit(t.SET_VERIFY_ORDER_COMPLETED_SECTIONS, completedSections)
      dispatch('updateVerifyOrderInteraction')
    }
    dispatch('setNextSection')
  },
}

const MUTATIONS = {
  [t.SET_ORDER_REQUIRING_VERIFICATION](state, orderRequiringVerification) {
    state.orderRequiringVerification = orderRequiringVerification
  },
  [t.SET_VERIFY_ORDER_INTERACTION](state, verifyOrderInteraction) {
    state.verifyOrderInteraction = verifyOrderInteraction
  },
  [t.SET_ORDERS_REQUIRING_VERIFICATION_LOADED](state, loading) {
    state.ordersRequiringVerificationLoaded = loading
  },
  [t.SET_VERIFY_ORDER_REDIRECTED](state, value) {
    state.redirected = value
  },
  [t.SET_VERIFY_ORDER_PROGRESS](state,progress) {
    state.progress = progress
  },
  [t.SET_VERIFY_ORDER_CURRENT_SECTION](state, section) {
    Vue.set(state.progress, 'current_section', section)
  },
  [t.SET_VERIFY_ORDER_COMPLETED_SECTIONS](state, completedSections) {
    Vue.set(state.progress, 'completed_sections', completedSections)
  },
  [t.SET_VERIFY_ORDER_SECTIONS](state, sections) {
    state.sections = sections
  },
  [t.SET_VERIFY_ORDER_OPTIONAL_CATEGORIES](state, optionalCategories) {
    state.optionalCategories = optionalCategories
  },
  [t.SET_UPSELL_OPTIONS](state, allUpsellOptions) {
    state.allUpsellOptions = allUpsellOptions
  },
  [t.SET_DEPENDENT_FILINGS_FILING_METHOD ](state, filingMethod) {
    state.dependentFilingsFilingMethod = filingMethod
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
