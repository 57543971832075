import { Client, GET, POST } from '@/api/client'
import {
  ACCOUNT_GUEST_FIND_SERVICE,
  ADD_COMPLIANCE_SERVICE, CLIENT_COMPANIES_INDEX_SIMPLE, CLIENT_COMPANIES_SHARED_SIMPLE,
} from '@/api/v3/endpoints'

import {
  state as BaseState,
  actions as BaseActions,
  getters as BaseGetters,
  mutations as BaseMutations,
} from '@/store/base'
import http, { paramsSerializer } from '@/http'
import * as t from '@/store/mutations'
import { yearsSinceDue } from '@/helpers'

const STATE = {
  ...BaseState,
  namespace: 'services',
  services: [],
  serviceBillingDetails: {},
  selectedServices: [],
  complianceEvents: [],
}

const GETTERS = {
  ...BaseGetters,
  services: state => state.services,
  service: state => serviceId => state.services.find(svc => svc.id === serviceId),
  serviceBillingDetails: state => state.serviceBillingDetails,
  companyServices: state => companyId => state.services.filter(svc => svc.company_id === companyId),
  selectedServices: state => state.selectedServices,
  complianceEvents: state => state.complianceEvents,
  accountServices: (state, getters, rootState, rootGetters) => {
    return rootGetters['account/activeAccountServices']
  },
}

const ACTIONS = {
  ...BaseActions,
  async getServices({ commit }, params) {
    const { data } = await http.get(`client/services`, { params, paramsSerializer })

    if(data?.success) commit(t.SET_SERVICES, data.result)

    return data
  },
  async fetchService ({ rootGetters }, { params }) {
    try {
      const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
      const { data } = await client.call({
        method: GET,
        path: ACCOUNT_GUEST_FIND_SERVICE,
        params: params,
      })
      return data
    } catch (error) {
      return error
    }
  },
  async companiesIndexSimple(_, params) {
    const { data } = await http.get(CLIENT_COMPANIES_INDEX_SIMPLE, { params })

    return data
  },
  async companiesSharedSimple(_, params) {
    const { data } = await http.get(CLIENT_COMPANIES_SHARED_SIMPLE, { params })

    return data
  },
  async getAndReloadServices({ commit }, { params, ids }) {
    if(ids?.length > 0) params.filter = [{ scope: 'filter_by_id', value: ids }]
    const { data } = await http.get(`client/services`, { params, paramsSerializer })
    if(data?.success){
      commit(t.ADD_UPDATE_SERVICES, data.result)
    }
    return data
  },
  async processComplianceServices({ dispatch }, vm) {
    try {
      await dispatch('setComplianceServicesDueDate')
      await dispatch('setComplianceServicePricing')

    } catch(_error) {
      vm.$bvToast.toast(
        `Error setting compliance services`,
        {
          title: 'Error',
          variant: 'danger',
          solid: true,
          noAutoHide: true,
        }
      )
    }
  },
  async setComplianceServicesDueDate({ commit, getters }, params = {}) {
    if (!getters.accountServices) return

    const response = await http.get(`client/compliance_events`, {
      params: {
        limit: params['limit'] || 25,
        offset: params['offset'] || 0,
        include_count: true,
        generic_search: params['generic_search'] || '',
        order_by: params['order_by'] || 'due_date',
        order_direction: params['desc'] ? 'desc' : 'asc',
      },
      paramsSerializer,
    })

    let complianceEvents = response.data.result
    const serviceBillingDetails = []
    const currentDate = new Date()
    const timeZone = 'T00:00:00'

    complianceEvents = complianceEvents.filter((event) => {
      const eventDueDate = new Date(event.due_date + timeZone)
      return eventDueDate > currentDate
    })

    complianceEvents = complianceEvents.filter((event) => {
      return event.registration.has_compliance_service
    })

    complianceEvents.forEach(event => {
      serviceBillingDetails[event.registration_id] = {
        companyId: event.company_id,
        filingId: event.filing_id,
        dueDate: event.due_date,
        lateFee: event.late_fee,
        stateFees: 'pending...',
        cost: 'pending...',
      }
    })

    commit(t.SET_SERVICE_BILLING_DETAILS, serviceBillingDetails)
    commit(t.SET_COMPLIANCE_EVENTS, complianceEvents)
  },
  async setComplianceServicePricing({ getters, dispatch }) {
    if (!getters.accountServices?.length || !getters.serviceBillingDetails) return

    const requestParams = {}

    getters.accountServices.forEach(service => {
      if (service.type !== 'compliance') return

      const companyId = service.company_id
      const registrationId = service.object_id
      const filingId = getters.serviceBillingDetails[registrationId]?.filingId

      if(!filingId) return

      if (!requestParams[companyId]) requestParams[companyId] = []

      const currentServiceInfo = {
        registration_id: registrationId,
        filing_id: filingId,
      }

      requestParams[companyId].push(currentServiceInfo)
    })

    if (Object.keys(requestParams).length === 0) return

    const response = await http.get(`filings/purchasable_website_filing_products_for_multiple_companies`, {
      params: {
        company_compliance_details: requestParams,
      },
      paramsSerializer,
    })

    await dispatch('addCostAndFees', response.data.result)
  },
  addCostAndFees({ commit, getters }, filingProductsWithAssociations) {
    const serviceBillingDetails = getters.serviceBillingDetails
    const updateCost = (complianceInfo) => {
      const lateFee = complianceInfo.lateFee

      return yearsSinceDue(complianceInfo) >= 0 && lateFee ?
        lateFee : 0
    }

    filingProductsWithAssociations.forEach((data) => {
      const registrationId = data.registration_id
      const stateFees = data.filing_methods[0].cost
      const complianceInfo = serviceBillingDetails[registrationId]

      let cost = data.filing_product.price
      cost += updateCost(complianceInfo)

      serviceBillingDetails[registrationId].cost = cost
      serviceBillingDetails[registrationId].stateFees = stateFees
    })

    commit(t.SET_SERVICE_BILLING_DETAILS, serviceBillingDetails)
  },
  async addComplianceService({ _commit, rootGetters }, { params }) {
    const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
    try {
      const payload = { autopay_payable_id: params.autopayPayableId, ...params }

      const response = await client.call({
        method: POST,
        path: ADD_COMPLIANCE_SERVICE.mapping({
          ':companyId': params.companyId,
          ':registrationId': params.registrationId,
        }),
        params: payload,
      })

      return response
    } catch (error) {
      console.log(error)
      return error
    }
  },
  async cancelServiceAndFilings(_, params) {
    try {
      const response = await http.post('client/cancellations/cancel_services_and_filings',
        params)
      return response.data
    } catch (e) {
      return { success: false, e }
    }
  },

  async cancelServiceAndFilingsGuest(_, params) {
    try {
      const response = await http.post('client/cancellations/cancel_services_and_filings_guest',
        params)
      return response.data
    } catch (e) {
      return { success: false, e }
    }
  },
}

const MUTATIONS = {
  ...BaseMutations,
  [t.SET_SERVICES](state, services) {
    state.services = services
  },
  [t.ADD_UPDATE_SERVICES](state, services) {
    state.services = [
      ...state.services.filter(svc => services.every(s => s.id !== svc.id)),
      ...services,
    ]
    state.selectedServices = [
      ...services.filter(svc => state.selectedServices.some(ss => ss.id === svc.id)),
      ...state.selectedServices.filter(ss => services.every(svc => svc.id !== ss.id)),
    ]
  },
  [t.SET_SELECTED_SERVICES](state, services) {
    state.selectedServices = services
  },
  [t.SET_SERVICE_BILLING_DETAILS](state, billingDetails) {
    state.serviceBillingDetails = billingDetails
  },
  [t.SET_COMPLIANCE_EVENTS](state, complianceEvents) {
    state.complianceEvents = complianceEvents
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
