import * as t from '../mutations'
import Vue from 'vue'
import axiosClient from '@/http'
import _ from 'lodash'

const initialState = () => ({
  resourceFields: [],
  resource: {},
  currentPagePdfFields: [],
  formData: {},
  processedFormData: {},
  currentField: null,
  currentFieldIndex: 0,
  currentPage: 1,
  raAddressReset: false,
  autosaveStatus: 'Saving',
  pdfAddressCheckboxState: {},
  pdfFieldsOptional: false,
  verifyOrderPeople: [],
  debugDocumentViewerEditMode: false,
})

const STATE = initialState()

const GETTERS = {
  companyId:               (_state, getters, _rootState, rootGetters) => rootGetters['companies/currentCompanyId'],
  resourceFields:          state => state.resourceFields,
  resourceFieldById:       (_state, getters) => id => getters.resourceFields.find(field => field.id === id),
  resource:                state => state.resource,
  agencyResourceId:        state => state.resource?.id,
  formData:                state => state.formData,
  processedFormData:       state => state.processedFormData,
  currentField:            state => state.currentField,
  currentFieldIndex:       (_state, getters) => getters.allSchemaFields.indexOf(getters.currentField) || 0,
  currentPage:             state => state.currentPage,
  pdfAddressCheckboxState: state => state.pdfAddressCheckboxState,
  pdfFieldsOptional:       state => state.resource?.pdf_fields_optional || false,
  pdfGroupField:           (_state, getters) => getters.pdfGroupId(getters.currentField),
  singlePagePdfFields:     (_state, getters) => (page) => {
    return getters.resourceFields.length ? getters.resourceFields.filter(field => field.page === page - 1) : []
  },
  currentPagePdfFields:    (_state, getters) => {
    return getters.singlePagePdfFields(getters.currentPage)
  },
  currentGroupSchemaFields:  (_state, getters) => {
    return getters.allSchemaFields.filter(field => getters.groupId(field) === getters.groupId(getters.currentField))
  },
  allSchemaFields:          (_state, getters) => {
    return getters.resourceFields
      .filter(f => (f.title && f.title !== '') || (f.type === 'radio'))
      .sort((a, b) => a.tabIndex - b.tabIndex)
  },
  schemaTabIndex:           (_state, getters) => (fieldId) => {
    // defineTabIndexes sets the pdf field tabIndexes in the thousands by page (page 1 -> 1000, 1001... page 2 -> 2000...)
    // these tabIndexes are for the current page schema fields only and are converted to be in the hundreds (100, 101...)
    return getters.resourceFields.find(field => field.id === fieldId)?.tabIndex % 1000 + 100
  },
  groupId:                  (_state) => (field) => {
    return field?.data?.pdf_group_id || field?.id
  },
  formCompleted:           (_state, getters) => {
    return getters.resourceFields.every(field => {
      return getters.fieldRequired(field) ? getters.fieldIdPassesValidation(field.id) : true
    })
  },
  mapFieldIdsToGroupedFieldsParts: (_state, getters) => (group) => {
    const mapped = {}
    for (const field of group) {
      if (field?.data?.parts?.length) {
        const partName = getters.normalizedPartName(field.data.parts[0])
        mapped[partName] = field.id
      }
    }
    return mapped
  },
  normalizedPartName: (_state) => (fieldPart) => {
    const splitParts = fieldPart.split('.')
    return splitParts.length > 1 ? splitParts[1] : fieldPart
  },
  groupIdentifier: (_state, getters) => (field) => {
    if (getters.pdfGroupId(field)) return `pdfGroupId-${getters.pdfGroupId(field)}`
    if (getters.buttonGroupId(field)) return `buttonGroupId-${getters.buttonGroupId(field)}`
  },
  soloFields: (_state, getters) => {
    return getters.allSchemaFields.filter(field =>
      field.id === getters.currentField?.id  && !getters.buttonGroupHasMissingData(field))
  },
  resourceGroupFields: (_state, getters) => field => {
    const groupId = getters.groupIdentifier(field)
    return getters.resourceFieldGroups[groupId]
  },
  groupFields: (_state, getters) => field => {
    const groupId = getters.groupIdentifier(field)
    return getters.fieldGroups[groupId]
  },
  currentFieldGroup: (_state, getters) => {
    return getters.groupFields(getters.currentField)
  },
  currentFields: (_state, getters) => {
    return getters.pdfGroupField ? getters.currentFieldGroup : getters.soloFields
  },
  currentFilteredFields: (_state, getters) => {
    return getters.pdfGroupField ? getters.filteredGroupFields : getters.soloFields
  },
  filteredGroupFields: (_state, getters) => {
    if (getters.currentFields) {
      let filteredFieldGroup = getters.currentFieldGroup?.length ?
        getters.currentFieldGroup.filter(f => f.type === 'string') :
        []

      if (getters.currentAddressFields.length) filteredFieldGroup.push(getters.currentAddressFields[0])
      if (getters.currentPersonFields.length) filteredFieldGroup.push(getters.currentPersonFields[0])
      if (getters.currentBankInformationFields.length) filteredFieldGroup.push(getters.currentBankInformationFields[0])
      if (getters.currentDateTimeFields.length) filteredFieldGroup.push(getters.currentDateTimeFields[0])
      if (getters.currentInitialContributionsFields.length) filteredFieldGroup.push(getters.currentInitialContributionsFields[0])
      if (getters.currentObjectGroupFields.length) filteredFieldGroup.push(getters.currentObjectGroupFields[0])
      for(const i in getters.distinctButtonGroups) {
        filteredFieldGroup.push(getters.distinctButtonGroups[i])
      }
      return filteredFieldGroup
    }
    return []
  },
  currentAddressFields: (_state, getters) => {
    return getters.currentFields?.filter(f => f.meta.type === 'address') || []
  },
  currentPersonFields: (_state, getters) => {
    return getters.currentFields?.filter(f => f.meta.type === 'person' ||
      f.meta.type === 'registered_agent'
    ) || []
  },
  currentBankInformationFields: (_state, getters) => {
    return getters.currentFields?.filter(f => f.meta.type === 'bank_information') || []
  },
  currentDateTimeFields: (_state, getters) => {
    return getters.currentFields?.filter(f => f.meta.type === 'date' && f.data?.parts?.length) || []
  },
  currentButtonGroupFields: (_state, getters) => (field) => {
    return getters.currentFields.filter(f => f.data && f.data.button_group_id === field.data.button_group_id)
  },
  currentInitialContributionsFields: (_state, getters) => {
    return getters.currentFields.filter(f => f.meta.type === 'contributions')
  },
  currentObjectGroupFields: (_state, getters) => {
    return getters.currentFields.filter(f => f.meta.type === 'object')
  },
  resourceFieldGroups: (_state, getters) => {
    return getters.resourceFields
      .filter(field => getters.groupIdentifier(field))
      .reduce((group, field) => {
        const groupIdentifier = getters.groupIdentifier(field)
        group[groupIdentifier] = group[groupIdentifier] || []
        group[groupIdentifier].push(field)
        return group
      }, {})
  },
  fieldGroups: (_state, getters) => {
    return getters.allSchemaFields
      .filter(field => getters.groupIdentifier(field))
      .reduce((group, field) => {
        const groupIdentifier = getters.groupIdentifier(field)
        group[groupIdentifier] = group[groupIdentifier] || []
        group[groupIdentifier].push(field)
        return group
      }, {})
  },
  distinctButtonGroups: (_state, getters) => {
    let distinctButtonGroupFields = []

    const buttonGroupsFields = getters.currentFields
      .filter(f => f.data && f.data?.button_group_id && !getters.buttonGroupHasMissingData(f))
    for (const field of buttonGroupsFields) {
      if (!distinctButtonGroupFields.some(f => f.data.button_group_id === field.data.button_group_id)) {
        distinctButtonGroupFields.push(field)
      }
    }

    return distinctButtonGroupFields
  },
  buttonGroupHasMissingData: (_state, getters) => field => {
    // this should be able to be removed after all radio buttons have been mapped properly
    if (field.type !== 'radio') return false
    if (!getters.buttonGroupId(field)) return true
    return getters.groupFields(field).some(f => !f.title || !f.data.pdf_group_id)
  },
  pdfGroupId: (_state) => (field) => {
    return field?.data?.pdf_group_id
  },
  buttonGroupId: (_state) => (field) => {
    return field?.data?.button_group_id
  },
  buttonGroupIsCurrent: (_state, getters) => field => {
    return getters.buttonGroupId(field) ?
      getters.buttonGroupId(field) === getters.buttonGroupId(getters.currentField) :
      false
  },
  groupIsCurrent: (_state, getters) => field => {
    return getters.pdfGroupIsCurrent(field) ||
      getters.buttonGroupIsCurrent(field)
  },
  pdfGroupIsCurrent: (_state, getters) => field => {
    return getters.pdfGroupId(field) ?
      getters.pdfGroupId(field) === getters.pdfGroupId(getters.currentField) :
      false
  },
  raAddressReset: state => state.raAddressReset,
  isDisabledField: (_state) => field => {
    return field?.data?.disabled
  },
  validationRules: (_state, getters) => field => {
    if (!field) return {}
    const rules = {}
    if (getters.fieldRequired(field)) rules['required'] = true
    if (field.meta?.character_limit) rules['max'] = field.meta?.character_limit
    if (field.meta?.type === 'phone' || (field.data?.parts?.length && field.data.parts[0].includes('phone'))) rules['phone_number'] = true
    if (field.meta?.type === 'ssn' || (field.data?.parts?.length && field.data.parts[0].includes('ssn'))) rules['ssn'] = true
    if (field.data?.parts?.length && field.data.parts[0].includes('zip')) rules['zipcode'] = true
    if (field.data?.parts?.length && field.data.parts[0].includes('email')) rules['email'] = true
    if ((field.data?.parts?.length && field.data.parts[0].includes('company_ownership_percentage')) ||
      (field.meta?.type === 'number' && field.meta?.min_value === 1 && field.meta?.max_value === 100)) rules['percentage'] = true

    return rules
  },
  fieldRequired: (_state, getters) => (field, skipOptionalCheck = false)  => {
    if (getters.pdfFieldsOptional && !skipOptionalCheck) return false

    const partName = field?.data?.parts?.length ? getters.normalizedPartName(field.data.parts[0]) : ''

    return field?.required &&
      (!getters.pdfGroupIsOptional(field) || skipOptionalCheck) &&
      partName != 'line2'
      && !getters.signatureField(field)
      && (field.title && field.title !== '')
  },
  validationRulesForFieldId: (_state, getters) => id => {
    return getters.validationRules(getters.resourceFieldById(id))
  },
  validationState: _state => ({ dirty, validated, valid = null }) => {
    return (dirty || validated) ? valid : null
  },
  pdfGroupIsOptional: (_state, getters) => field => {
    return getters.pdfGroupId(field) && getters.resourceGroupFields(field)?.some(f => f.data?.pdf_group_optional)
  },
  signatureField: (_state) => field => {
    return field?.data?.parts?.includes('signature') || field.type === 'signature'
  },
  autosaveStatus: state => state.autosaveStatus,
  fieldIdPassesValidation: (_state, getters) => fieldId => {
    if (!getters.formData[fieldId]) return false
    const rules = getters.validationRulesForFieldId(fieldId)
    let valid = true
    Object.entries(rules).forEach(([rule, value]) => {
      if (rule === 'max' && getters.formData[fieldId].length > value) valid = false
      if (rule === 'phone_number') {
        const pattern = new RegExp(/^\d+$/)
        if (!(getters.formData[fieldId].toString().length >= 10 && pattern.test(getters.formData[fieldId]))) valid = false
      }
      if (rule === 'zipcode') {
        const pattern = new RegExp(/^\d{5}([-\s]\d{4})?$/, 'g')
        if (!pattern.test(getters.formData[fieldId])) valid = false
      }
      if (rule === 'email') {
        const pattern = new RegExp(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z-]*[a-z])+/
          , 'gi')
        if (!pattern.test(getters.formData[fieldId])) valid = false
      }
      if (rule === 'ssn') {
        const pattern = new RegExp(/^(\d{3}-?\d{2}-?\d{4})$/, 'gi')
        if (!pattern.test(getters.formData[fieldId])) valid = false
      }
      if (rule === 'percentage') {
        const pattern = new RegExp(/^(?:[1-9][0-9]?|100)$/)
        if (!pattern.test(getters.formData[fieldId])) valid = false
      }
    })
    return valid
  },
  getAllRequiredFieldIdAndTitle: (_state, getters) => {
    const requiredFieldList = getters.resourceFields.filter(field => getters.fieldRequired(field))

    return requiredFieldList.map(field => {
      const { id, title, ..._otherFields } = field
      return { id, title }
    })
  },
  listMissingRequiredFieldTitles: (_state, getters) => {
    if (!getters.formCompleted) {
      let updatedList = []
      getters.getAllRequiredFieldIdAndTitle.forEach(requiredField => {
        if (!getters.fieldIdPassesValidation(requiredField.id))
          updatedList.push(requiredField.title.replace(':', ' '))
      })

      updatedList = updatedList.map(item => {
        return item === getters.resourceFieldById(item.id) ? getters.resourceFieldById(item.id).title : item
      })
      return updatedList.filter((item, index, ref) => ref.indexOf(item) === index)
    }

    return []
  },
  formattedProcessedFormData: (_state, getters) => {
    let data = {}
    data = _.merge(data, getters.processedFormData?.__default__)
    data = _.merge(data, getters.processedFormData?.__company__)
    data = _.merge(data, getters.processedFormData?.__registration__)
    data = _.merge(data, getters.processedFormData?.__misc__)
    return { default: data }
  },
  verifyOrderPeople: state => state.verifyOrderPeople,
  debugDocumentViewerEditMode: state => state.debugDocumentViewerEditMode,
  ghostMode: (_state, _getters, _rootState, rootGetters) => rootGetters['stageline/ghostMode'],

  maxCurrentFieldGroupIndex: (_state, getters) => {
    if (getters.currentGroupSchemaFields.length === 0) {
      return getters.currentFieldIndex
    } else {
      const lastFieldInGroup = getters.currentGroupSchemaFields[getters.currentGroupSchemaFields.length - 1]
      return getters.allSchemaFields.indexOf(lastFieldInGroup)
    }
  },
  documentProgress: (_state, getters) => {
    const total = getters.allSchemaFields?.length || 0
    const complete = getters.maxCurrentFieldGroupIndex || 0

    return total === 0 || complete === 0 ?
      0 :
      Math.round((100 * (complete + 1)) / total)
  },
}

const ACTIONS = {
  setVerifyOrderPeople({ commit }, data) {
    commit(t.SET_VERIFY_ORDER_PEOPLE, data)
  },
  addVerifyOrderPerson({ getters, commit }, person) {
    const newPerson = {}
    Object.keys(person).forEach(key => {
      newPerson[key.replace('_attributes', '')] = person[key]
    })
    const newPeople = getters.verifyOrderPeople
    newPeople.push(newPerson)
    commit(t.SET_VERIFY_ORDER_PEOPLE, newPeople)
  },
  resetStagelineSchemaForm({ commit }) {
    commit(t.RESET_STAGELINE_SCHEMA_FORM)
  },
  async loadResourceFieldSchema({ commit, dispatch, getters }, { resourceId }) {
    const response = await axiosClient.get(`client/companies/${getters.companyId}/resource_fields_schema/${resourceId}`)
    const fields = response.data.result ? response.data.result.form_fields : {}
    commit(t.SET_RESOURCE_FIELDS, fields)
    await dispatch('defineTabIndexes')
  },
  async setResourceFields({ commit, dispatch }, fields) {
    commit(t.SET_RESOURCE_FIELDS, fields)
    await dispatch('defineTabIndexes')
  },
  async loadResourceFieldsForCompany({ commit }, { companyId, resourceId }) {
    if (companyId && resourceId) {
      const response = await axiosClient.get(`client/companies/${companyId}/fields_for/${resourceId}`).catch((_) => {
        // Catch for pre-filling fields, let the client at least fill out the document
        // This is a fallback value
        return { data: {} }
      })

      const fields = response.data?.result ? response.data.result.resource_field_values : {}
      commit(t.SET_FORM_DATA, fields)
    } else {
      commit(t.SET_FORM_DATA, {})
    }
  },
  async updateFormData({ commit, getters }) {
    if (getters.ghostMode) return
    commit(t.SUBMITTING_FORM_DATA, true)
    await axiosClient
      .post(`client/companies_agency_resources/${getters.companyId}/update_company_agency_resource/${getters.agencyResourceId}`,
        { form_data: getters.formData })
      .then(
        () => commit(t.SET_AUTOSAVE_STATUS, 'Saved'),
        () => commit(t.SET_AUTOSAVE_STATUS, 'Failed'))

    commit(t.SUBMITTING_FORM_DATA, false)
  },
  async processFormData({ commit, getters, rootGetters, dispatch }) {
    if (getters.ghostMode) return

    const agencyResourceId = getters.agencyResourceId || rootGetters['stageline/pdfViewerAgencyResourceId']
    if (!agencyResourceId) return

    await axiosClient
      .post(`client/companies_agency_resources/${getters.companyId}/process_company_agency_resource/${agencyResourceId}`)
      .then((res) => {
        if (res.data.status === 200) {
          commit(t.SET_PROCESSED_FORM_DATA, res.data.response)
          dispatch('updateSubTypeProductInCart')
        } else
          commit(t.SET_PROCESSED_FORM_DATA, {})
        }
      )
  },
  async processCaptureMonsterFormData({ commit, getters, dispatch }) {
    if (getters.ghostMode) return

    await axiosClient
      .post(`client/companies_agency_resources/${getters.companyId}/process_company_capture_monster_agency_resource`)
      .then((res) => {
          if (res.data.status === 200) {
            commit(t.SET_PROCESSED_FORM_DATA, res.data.response)
            dispatch('updateSubTypeProductInCart')
          } else
            commit(t.SET_PROCESSED_FORM_DATA, {})
        }
      )
  },
  async fetchProcessedFormData({ commit, getters, rootGetters }) {
    commit(t.SET_PROCESSED_FORM_DATA, {})
    const agencyResourceId = getters.agencyResourceId || rootGetters['stageline/pdfViewerAgencyResourceId']
    const response = await axiosClient.get(`client/companies_agency_resources/${getters.companyId}/${agencyResourceId}`)
    if (response.data.success) {
      commit(t.SET_PROCESSED_FORM_DATA, response.data.result.processed_form_data)
    } else {
      commit(t.SET_PROCESSED_FORM_DATA, {})
    }
  },
  async updateSubTypeProductInCart({ dispatch, getters, _rootState, rootGetters }) {
    if (_.isEmpty(getters.formattedProcessedFormData)) return

    const cartItem = rootGetters['stageline/currentStageDocumentSubTypeProductCartItem']
    const bundleCartItem = rootGetters['stageline/currentStageDocumentSubTypeProductBundleCartItem']

    if (cartItem) {
      await dispatch('checkout/updateCartItemData', {
        id: cartItem.id,
        data: getters.formattedProcessedFormData,
      }, { root: true })
    } else if (bundleCartItem) {
      const cartItemData = bundleCartItem.data
      const subTypeProductInBundle = Object.values(cartItemData).find(value =>
        value.filing_name === rootGetters['stageline/currentStageDocumentSlide']?.layout_sub_type
      )
      subTypeProductInBundle.data = getters.formattedProcessedFormData

      await dispatch('checkout/updateCartItemData', {
        id: bundleCartItem.id,
        data: cartItemData,
      }, { root: true })
    }
  },
  async defineTabIndexes({ commit, getters }) {
    const sortedPages = sortPages(getters.resourceFields)
    const newResourceFields = getters.resourceFields

    sortedPages.forEach((page, pageNum) => {
      const sortedGroups = sortGroups(page)
      let sortedFields

      // Check to see whether all fields contain a unique position key
      let fieldPositionsValid = false
      const positions = [...newResourceFields].map(field => field.position)
      if (new Set(positions).size === positions.length) fieldPositionsValid = true

      // Sort by position key if every field has a unique position key
      if (fieldPositionsValid) {
        sortedFields = newResourceFields.sort((a, b) => a.position - b.position)
      // Else, sort by coords
      } else {
        sortedFields = sortFields(sortedGroups)
      }

      // Define tabIndexes
      sortedFields.forEach((field, i) => {
        newResourceFields.find(f => f.id === field.id).tabIndex = (pageNum + 1) * 1000 + i
      })
    })

    commit(t.SET_RESOURCE_FIELDS, newResourceFields)

    function sortPages(resourceFields) {
      const pages = []
      resourceFields.forEach(field => {
        pages[field.page] = pages[field.page] || []
        pages[field.page].push(field)
      })
      return pages
    }
    function sortGroups(pageFields) {
      const [groups, coordArray] = getGroupsAndCoordArray(pageFields)

      const groupOrder = sortByRows(coordArray)
      groups.sort((a, b) =>
        groupOrder.findIndex(group => group.id === a[0].groupId) -
        groupOrder.findIndex(group => group.id === b[0].groupId)
      )
      return groups
    }
    function sortFields(groups) {
      groups = groups.map(group => sortByRows(group))

      const fields = []
      groups.forEach(group => group.forEach(field => fields.push(field)))
      return fields
    }
    function getGroupsAndCoordArray(pageFields) {
      const groups = {}
      const groupCoords = {}
      pageFields.forEach(field => {
        const groupId = field?.data?.pdf_group_id || field.id
        groups[groupId] = groups[groupId] || []
        groupCoords[groupId] = groupCoords[groupId] || { id: groupId, x: 1, y1: 1, y2: 0 }

        const { id, x, y, height } = field
        groups[groupId].push({ groupId, id, x, y1: y, y2: y + height })
        groupCoords[groupId].x = Math.min(x, groupCoords[groupId].x)
        groupCoords[groupId].y1 = Math.min(y, groupCoords[groupId].y1)
        groupCoords[groupId].y2 = Math.max((y + height), groupCoords[groupId].y2)
      })
      return [Object.values(groups), Object.values(groupCoords)]
    }
    function sortByRows(coordArray) {
      const firstItem = sortByTopLeft(coordArray)[0]
      const rows = { 0: [firstItem] }
      let rowCount = 1
      coordArray
        .filter(item => item.id !== firstItem.id)
        .forEach(item => {
          let foundRow = -1
          for (const [row, rowItems] of Object.entries(rows)) {
            if (isSameRow(rowItems, item)) foundRow = row
          }
          if (foundRow >= 0) {
            rows[foundRow].push(item)
          } else {
            rows[rowCount] = []
            rows[rowCount].push(item)
            rowCount++
          }
        })

      const sortedRows = Object.values(rows)
        .sort((a, b) => (a[0].y1 - b[0].y1))
        .map(row => row.sort((a, b) => a.x - b.x))

      const sortedCoordArray = []
      sortedRows.forEach(row => {
        row.forEach(rowItem =>
          sortedCoordArray.push(coordArray.find(item => item.id === rowItem.id))
        )
      })
      return sortedCoordArray
    }
    function sortByTopLeft(array) {
      return array.sort((a, b) => Math.hypot(a.x, a.y1) - Math.hypot(b.x, b.y1))
    }
    function isSameRow(rowItems, item) {
      const minOverlap = 0.6
      return rowItems.some(ri => {
        // if (overlap) return encompassing ? true : sufficient overlap
        if (item.y1 <= ri.y1 && ri.y1 <= item.y2) return ri.y2 <= item.y2 ? true : (item.y2 - ri.y1) / (ri.y2 - ri.y1) >= minOverlap
        if (item.y1 <= ri.y2 && ri.y2 <= item.y2) return ri.y1 >= item.y1 ? true : (ri.y2 - item.y1) / (ri.y2 - ri.y1) >= minOverlap
        if (ri.y1 <= item.y1 && item.y1 <= ri.y2) return item.y2 <= ri.y2 ? true : (ri.y2 - item.y1) / (item.y2 - item.y1) >= minOverlap
        if (ri.y1 <= item.y2 && item.y2 <= ri.y2) return item.y1 >= ri.y1 ? true : (item.y2 - ri.y1) / (item.y2 - item.y1) >= minOverlap
        return false
      })
    }
  },
  setPdfAddressCheckboxState({ commit }, { checkboxId, checkboxState }) {
    commit(t.SET_PDF_ADDRESS_CHECKBOX_STATE, { checkboxId, checkboxState })
  },
  setResource({ commit }, resource) {
    commit(t.SET_RESOURCE, resource)
  },
  setFormValue({ commit }, { key, value }) {
    commit(t.SET_FORM_VALUE, { key, value })
  },
  setFormData({ commit }, formData) {
    commit(t.SET_FORM_DATA, formData)
  },
  setCurrentFieldById({ getters, dispatch }, fieldId) {
    const field = getters.resourceFields.find(field => field.id === fieldId)
    if (field) dispatch('setCurrentField', field)
  },
  setCurrentField({ getters, commit }, field) {
    commit(t.SET_CURRENT_FIELD, field)
    if (field.page !== getters.currentPage - 1) commit(t.SET_CURRENT_PAGE, field.page + 1)
  },
  setCurrentFieldIndex({ getters, commit }, index) {
    const field = getters.allSchemaFields[index]
    commit(t.SET_CURRENT_FIELD, field)
    if (field.page !== getters.currentPage - 1) commit(t.SET_CURRENT_PAGE, field.page + 1)
  },
  async getCurrentFieldIndex({ getters, dispatch }) {
    const response = await axiosClient.get(`client/companies_agency_resources/${getters.companyId}/get_current_field_index/${getters.agencyResourceId}`)
    const currentFieldIndex = response?.data?.result?.current_field_index || 0
    await dispatch('setCurrentFieldIndex', currentFieldIndex)
  },
  async updateCurrentFieldIndex({ getters, dispatch }, currentFieldIndex) {
    await axiosClient.post(`client/companies_agency_resources/${getters.companyId}/update_current_field_index/${getters.agencyResourceId}`, { current_field_index: currentFieldIndex })
    await dispatch('setCurrentFieldIndex', currentFieldIndex)
  },
  setCurrentFieldToInitialPageField({ getters, dispatch }, page) {
    const field = getters.allSchemaFields.find(field => field.page === page - 1)
    if (field) dispatch('setCurrentField', field)
  },
  setRaAddressReset({ commit }, raAddressReset) {
    commit(t.SET_RA_ADDRESS_RESET, raAddressReset)
  },
  setCurrentPage({ commit }, currentPage) {
    commit(t.SET_CURRENT_PAGE, currentPage)
  },
  setDebugDocumentViewerEditMode({ commit }, bool) {
    commit(t.SET_DEBUG_DOCUMENT_VIEWER_EDIT_MODE, bool)
  },
}

const MUTATIONS = {
  [t.SET_VERIFY_ORDER_PEOPLE](state, value) {
    Vue.set(state, 'verifyOrderPeople', value)
  },
  [t.RESET_STAGELINE_SCHEMA_FORM] (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  [t.SET_RESOURCE_FIELDS](state, resourceFields) {
    resourceFields = resourceFields.filter(field => field.page < state.resource.pages.length)
    Vue.set(state, 'resourceFields', resourceFields)
  },
  [t.SET_RESOURCE](state, resource) {
    state.resource = resource
  },
  [t.SET_FORM_DATA](state, fields) {
    Vue.set(state, 'formData', fields)
  },
  [t.SET_PROCESSED_FORM_DATA](state, processedFormData) {
    Vue.set(state, 'processedFormData', processedFormData)
  },
  [t.SET_CURRENT_FIELD](state, field) {
    Vue.set(state, 'currentField', field)
  },
  [t.SET_FORM_VALUE](state, { key, value }) {
    Vue.set(state.formData, key, value)
  },
  [t.SET_RA_ADDRESS_RESET](state, raAddressReset) {
    state.raAddressReset = raAddressReset
  },
  [t.SET_CURRENT_PAGE](state, currentPage) {
    state.currentPage = currentPage
  },
  [t.SET_AUTOSAVE_STATUS](state, status) {
    Vue.set(state, 'autosaveStatus', status)
  },
  [t.SUBMITTING_FORM_DATA](state, status) {
    Vue.set(state, 'submittingFormData', status)
  },
  [t.SET_PDF_ADDRESS_CHECKBOX_STATE](state, { checkboxId, checkboxState }) {
    Vue.set(state.pdfAddressCheckboxState, checkboxId, checkboxState)
  },
  [t.SET_DEBUG_DOCUMENT_VIEWER_EDIT_MODE](state, bool) {
    Vue.set(state, 'debugDocumentViewerEditMode', bool)
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
