import * as t from '../mutations'
import http from '@/http'

const STATE = {
  signatures: [],
  signatureTypes: {},
}

const GETTERS = {
  signatures: state => state.signatures,
  signatureTypes: state => state.signatureTypes,
}

const ACTIONS = {
  async getSignatures ({ commit }, { companyId }) {
    const result = await http.get(`client/companies/${companyId}/signatures`)
    commit(t.SET_SIGNATURES, result.data.result)
  },
  async getSignatureTypes ({ commit }) {
    let result = await http.get(`client/signature_types`)
    commit(t.SET_SIGNATURE_TYPES, result.data.result)
  },
  async deleteSignatures ({ dispatch }, { signatureId, companyId }) {
    await http.delete(`client/companies/${companyId}/signatures/${signatureId}`)
    await dispatch('getSignatures', companyId)
  },
  async saveSignature ({ dispatch }, { companyId, data, firstName, lastName, reason }) {
    await http
      .post(`client/companies/${companyId}/signatures`, {
        file: data,
        first_name: firstName,
        last_name: lastName,
        signature_type: reason,
      })
    await dispatch('getSignatures', companyId)
  },
}

const MUTATIONS = {
  [t.SET_SIGNATURES] (state, signatures) {
    state.signatures = signatures
  },
  [t.SET_SIGNATURE_TYPES] (state, signatureType) {
    state.signatureTypes = signatureType
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
