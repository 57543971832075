import * as t from '../mutations'
import http from '@/http'

const STATE = {
  invitationsSent: [],
  invitationsReceived: [],
  invitationsReceivedCount: 0,
}

const GETTERS = {
  invitationsSent: state => state.invitationsSent,
  invitationsReceived: state => state.invitationsReceived,
  invitationsReceivedCount: state => state.invitationsReceivedCount,
}

const ACTIONS = {
  async loadInvitations({ commit }) {
    const invitesSent     = await http.get('client/invitations/sent')
    const invitesReceived = await http.get('client/invitations/received')

    commit(t.SET_SENT_INVITATIONS,           invitesSent.data.result)
    commit(t.SET_RECEIVED_INVITATIONS,       invitesReceived.data.result)
    commit(t.SET_RECEIVED_INVITATIONS_COUNT, invitesReceived.data.result.length)
  },

  async createInvitations({ dispatch }, invitations) {
    for (const invite of invitations) {
      await http.post(`client/companies/${invite.companyId}/invite`, { invite: invite })
    }

    await dispatch('loadInvitations')
  },

  async submitInvitationChoice({ dispatch }, { invite, choice } ){
    await http.post(`client/invitations/${invite.id}/${choice}`)

    await dispatch('loadInvitations')
  },

  async fetchReceivedInvitationsCount({ commit }) {
    const res   = await http.get('client/invitations/received_count')

    commit(t.SET_RECEIVED_INVITATIONS_COUNT, res.data.result.count)
  },
}

const MUTATIONS = {
  [t.SET_SENT_INVITATIONS](state, invites) {
    state.invitationsSent = invites
  },
  [t.SET_RECEIVED_INVITATIONS](state, invites) {
    state.invitationsReceived = invites
  },
  [t.SET_RECEIVED_INVITATIONS_COUNT](state, count) {
    state.invitationsReceivedCount = count
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
