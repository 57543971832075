import * as t from '../mutations'
import http from '@/http'
import axios from 'axios'

const STATE = {
  subscriptions: [],
  subscriptionSummary: {},
  subscriptionBalanceInfo: {},
  convertResponse: null,
}

const GETTERS = {
  subscriptions: state => state.subscriptions,
  subscriptionSummary: state => state.subscriptionSummary,
  subscriptionBalanceInfo: state => state.subscriptionBalanceInfo,
  convertResponse: state => state.convertResponse,
}

const ACTIONS = {
  async loadSubscriptions({ commit }) {
    const response = await http.get(`client/subscriptions`)
    commit(t.SUBSCRIPTIONS_LOADED, response.data.result)
  },
  async loadSubscriptionSummary({ commit }, { id }) {
    const response = await http.get(`client/subscriptions/summary/${id}`)
    commit(t.SUBSCRIPTION_SUMMARY_LOADED, response.data.result)
  },
  async outstandingBalanceInfo( { commit }, { id }) {
    const response = await http.get(`client/subscriptions/outstanding_balance_info/${id}`)
    commit(t.SUBSCRIPTION_BALANCE_LOADED, response.data.result)
  },
  async convertSubscription( { commit }, { id, balance, token, note, signature }) {
    const response = await http.post('client/cancellations/convert_subscription', {
      convert: {
        subscription_id: id,
        balance: balance,
        token: token,
        note: note,
        signature: signature,
      },
    })
    commit(t.SUBSCRIPTION_CONVERT_RESPONSE, response)
  },
}

const MUTATIONS = {
  [t.SUBSCRIPTIONS_LOADED](state, subscriptions) {
    state.subscriptions = subscriptions
  },
  [t.SUBSCRIPTION_SUMMARY_LOADED](state, summary) {
    state.subscriptionSummary = summary
  },
  [t.SUBSCRIPTION_BALANCE_LOADED](state, balance) {
    state.subscriptionBalanceInfo = balance.balance_info
  },
  [t.SUBSCRIPTION_CONVERT_RESPONSE](state, response) {
    state.convertResponse = response
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
