var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "contact-modal",
      attrs: {
        id: "contact-modal",
        title: _vm.title,
        "hide-footer": "",
        size: "lg",
      },
    },
    [
      _c(
        "form",
        {
          ref: "form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.saveContact.apply(null, arguments)
            },
          },
        },
        [
          _c("validation-observer", {
            ref: "observer",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ invalid }) {
                  return [
                    _vm.currentContact.contact_is_company
                      ? _c(
                          "div",
                          { key: "company-fields" },
                          [
                            _c(
                              "b-row",
                              [
                                _c("b-col", [
                                  _c("label", [_vm._v("Company name")]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mb-3" },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "5" } },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        rules: "required",
                                        name: "CompanyName",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    placeholder: "Company Name",
                                                    type: "text",
                                                    state:
                                                      errors.length > 0
                                                        ? !errors[0]
                                                        : null,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.currentContact
                                                        .contact_company_name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.currentContact,
                                                        "contact_company_name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "currentContact.contact_company_name",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { key: "personal-fields" },
                          [
                            _c(
                              "b-row",
                              [
                                _c("b-col", { attrs: { cols: "5" } }, [
                                  _c("label", [_vm._v("First name")]),
                                ]),
                                _c("b-col", { attrs: { cols: "7", md: "5" } }, [
                                  _c("label", [_vm._v("Last name")]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mb-3" },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "5" } },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        rules: "required",
                                        name: "FirstName",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("b-form-input", {
                                                attrs: {
                                                  placeholder:
                                                    "Enter first name",
                                                  type: "text",
                                                  state:
                                                    errors.length > 0
                                                      ? !errors[0]
                                                      : null,
                                                },
                                                model: {
                                                  value:
                                                    _vm.currentContact
                                                      .first_name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.currentContact,
                                                      "first_name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "currentContact.first_name",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "7", md: "5" } },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        rules: _vm.isPhoneContact
                                          ? ""
                                          : "required",
                                        name: "LastName",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("b-form-input", {
                                                attrs: {
                                                  placeholder:
                                                    "Enter last name",
                                                  type: "text",
                                                  state:
                                                    errors.length > 0
                                                      ? !errors[0]
                                                      : null,
                                                },
                                                model: {
                                                  value:
                                                    _vm.currentContact
                                                      .last_name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.currentContact,
                                                      "last_name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "currentContact.last_name",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-checkbox",
                          {
                            staticClass: "mt-1 mb-2 pl-3 ml-4",
                            model: {
                              value: _vm.currentContact.contact_is_company,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.currentContact,
                                  "contact_is_company",
                                  $$v
                                )
                              },
                              expression: "currentContact.contact_is_company",
                            },
                          },
                          [_vm._v("\n          Contact Is a Company\n        ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [_c("b-col", [_c("label", [_vm._v("Email")])])],
                      1
                    ),
                    _vm._l(
                      _vm.currentContact.person_emails,
                      function (record, index) {
                        return _c(
                          "b-row",
                          { key: record.id, staticClass: "mb-1" },
                          [
                            _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "2" } },
                              [
                                index
                                  ? _c("b-form-select", {
                                      attrs: { options: _vm.emailKindOptions },
                                      model: {
                                        value: record.kind,
                                        callback: function ($$v) {
                                          _vm.$set(record, "kind", $$v)
                                        },
                                        expression: "record.kind",
                                      },
                                    })
                                  : _c("b-form-select", {
                                      attrs: {
                                        value: "primary",
                                        options: _vm.emailKindOptions,
                                        disabled: "",
                                      },
                                    }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "pl-0",
                                attrs: { cols: "10", md: "8" },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    rules: _vm.isPhoneContact
                                      ? "email"
                                      : "required|email",
                                    name: "Email",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: "Enter an email",
                                                type: "text",
                                                state:
                                                  errors.length > 0
                                                    ? !errors[0]
                                                    : null,
                                              },
                                              model: {
                                                value: record.email_address,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    record,
                                                    "email_address",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "record.email_address",
                                              },
                                            }),
                                            _c(
                                              "p",
                                              { staticClass: "small red" },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(errors[0]) +
                                                    "\n            "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _vm.currentContact.person_emails.length > 1
                              ? _c(
                                  "b-col",
                                  { attrs: { cols: "2" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "red",
                                        attrs: {
                                          variant: "link",
                                          "aria-label": "remove button",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeEmail(record)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n            Remove\n          "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }
                    ),
                    _c(
                      "b-row",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mt-2 mb-2 pl-3 ml-0",
                            attrs: {
                              variant: "link",
                              "aria-label": "add alternative email button",
                            },
                            on: { click: _vm.addNewEmail },
                          },
                          [
                            _c(
                              "i",
                              [_c("feather-icon", { attrs: { type: "plus" } })],
                              1
                            ),
                            _vm._v(
                              "\n          Add alternative email\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [_c("b-col", [_c("label", [_vm._v("Phone number")])])],
                      1
                    ),
                    _vm._l(
                      _vm.currentContact.person_phones,
                      function (record, index) {
                        return _c(
                          "b-row",
                          { key: record.id },
                          [
                            _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "2" } },
                              [
                                index
                                  ? _c("b-form-select", {
                                      attrs: { options: _vm.phoneKindOptions },
                                      model: {
                                        value: record.kind,
                                        callback: function ($$v) {
                                          _vm.$set(record, "kind", $$v)
                                        },
                                        expression: "record.kind",
                                      },
                                    })
                                  : _c("b-form-select", {
                                      attrs: {
                                        value: "primary",
                                        options: _vm.phoneKindOptions,
                                        disabled: "",
                                      },
                                    }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "pl-0",
                                attrs: { cols: "10", md: "4" },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    rules: "required|phone_number",
                                    name: "Phone",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("b-form-input", {
                                              directives: [
                                                {
                                                  name: "mask",
                                                  rawName: "v-mask",
                                                  value: "###########",
                                                  expression: "'###########'",
                                                },
                                              ],
                                              staticClass: "mb-1",
                                              attrs: {
                                                placeholder:
                                                  "Enter a phone number",
                                                state:
                                                  errors.length > 0
                                                    ? !errors[0]
                                                    : null,
                                              },
                                              model: {
                                                value: record.phone_number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    record,
                                                    "phone_number",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "record.phone_number",
                                              },
                                            }),
                                            _c(
                                              "p",
                                              { staticClass: "small red" },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(errors[0]) +
                                                    "\n            "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _vm.isPhoneContact && !_vm.loading
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "mt-2",
                                        attrs: {
                                          variant: "secondary",
                                          disabled: _vm.loading,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.callContact(
                                              record.phone_number
                                            )
                                          },
                                        },
                                      },
                                      [
                                        !_vm.loading
                                          ? _c("div", [
                                              _vm._v(
                                                "\n              Call\n            "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.currentContact.person_phones.length > 1
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "red",
                                        attrs: {
                                          variant: "link",
                                          "aria-label": "remove button",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removePhone(record)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n            Remove\n          "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    _c(
                      "b-row",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mt-2 mb-2 pl-3 ml-0",
                            attrs: {
                              variant: "link",
                              "aria-label": "add alternative phone button",
                            },
                            on: { click: _vm.addNewPhone },
                          },
                          [
                            _c(
                              "i",
                              [_c("feather-icon", { attrs: { type: "plus" } })],
                              1
                            ),
                            _vm._v(
                              "\n          Add alternative phone\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [_c("b-col", [_c("label", [_vm._v("Address")])])],
                      1
                    ),
                    _vm._l(
                      _vm.currentContact.person_addresses,
                      function (record, index) {
                        return _c(
                          "div",
                          { key: record.id },
                          [
                            _c("address-form", {
                              attrs: {
                                "edit-address": record,
                                "show-kind": true,
                                index: index,
                                "is-phone-contact": _vm.isPhoneContact,
                              },
                              on: { changed: _vm.addressInfoChanged },
                            }),
                            _vm.currentContact.person_addresses.length > 1
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "red",
                                    attrs: {
                                      variant: "link",
                                      "aria-label": "remove button",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeAddress(record, index)
                                      },
                                    },
                                  },
                                  [_vm._v("\n          Remove\n        ")]
                                )
                              : _vm._e(),
                            _c("hr"),
                          ],
                          1
                        )
                      }
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mt-2 mb-2 pl-3 ml-0",
                            attrs: {
                              variant: "link",
                              "aria-label": "add alternative address button",
                            },
                            on: { click: _vm.addNewAddress },
                          },
                          [
                            _c(
                              "i",
                              [_c("feather-icon", { attrs: { type: "plus" } })],
                              1
                            ),
                            _vm._v(
                              "\n          Add alternative address\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-center" },
                      [
                        _c(
                          "div",
                          { attrs: { id: "btn-submit" } },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mt-4 submit-btn-width",
                                attrs: {
                                  variant: "primary",
                                  type: "submit",
                                  "aria-label": "submit button",
                                  disabled: _vm.loading,
                                },
                              },
                              [
                                !_vm.loading
                                  ? _c("div", [
                                      _vm._v(
                                        "\n              Save Contact\n            "
                                      ),
                                    ])
                                  : _c("b-spinner", { attrs: { small: "" } }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }