import * as t from '../../../mutations-stagelineV1'
import http from '@/http'
import router from '@/routes'
import axiosClient from '@/http'

const initialState = () => ({
  jurisdiction: null,
  stages: [],
  currentStage: null,
  currentStep: null,
  currentStagelineType: 'form',
  stageLoaded: false,
  stagelineLoaded: false,
  stepLoaded: false,
  startOver: false,
})

const STATE = initialState()

const GETTERS = {
  company: (_state, getters, _rootState, rootGetters) => rootGetters['companies/currentCompany'],
  jurisdiction: state => state.jurisdiction,
  startOver: state => state.startOver,
  /* ======== stages, steps and routing ========
  =========================================== */
  stages: state => state.stages,
  route: (_state, _getters, rootState) => rootState.appRouter,
  isStagelineRoute: (_state, getters) => getters.route.name && getters.route.name.includes('stageline'),
  isStagelineNonCheckoutRoute: (_state, getters) => getters.route.name && getters.route.name.includes('stageline') && !getters.route.name.includes('checkout'),
  currentStage: state => state.currentStage,
  currentStageIndex: (state, getters) => getters.filteredStages.findIndex(stage => stage.id === getters.currentStage?.id),
  currentStep: state => state.currentStep,
  currentStepIndex: (state, getters) => getters.filteredSteps.findIndex(step => step.id === getters.currentStep?.id),
  currentStageCompletedStepIds: (state, getters) => getters.currentStage.company_stage.completed_step_ids,
  stagelineLoaded: state => state.stagelineLoaded,
  stageLoaded: state => state.stageLoaded,
  stepLoaded: state => state.stepLoaded,
  stepIsLast: (_state, getters) => getters.filteredSteps.length - 1 === getters.currentStepIndex,
  stageIsLast: (_state, getters) => getters.filteredStages.length - 1 === getters.currentStageIndex,
  stagelineComplete: (_state, getters) => (type) => getters.company.config[type + '_stageline'] === 'complete',
  stagelineStatus: (state, getters) => (type) => getters.company.config[type + '_stageline'],
  stagelineType: (_state, _getters, rootState) => rootState.appRouter.params.stagelineType,
  stagelineJurisdiction: (_state, getters, _rootState, rootGetters) => {
    return rootGetters['jurisdictions/findByAbbreviation'](getters.stagelineStatus(getters.stagelineType)?.jurisdiction)
  },
  showFormationStageline: (_state, getters, _rootState, rootGetters) => {
    return !rootGetters['companies/formedElsewhere']
  },
  completedStepIds: (state) => state.currentStage.company_stage.completed_step_ids,

  /* ======== cart items ========
  ============================ */
  // Use for stage and step filtering, not checkout logic.
  cartItemExistsByCategory: (_state, getters, _rootState, rootGetters) => (type) => {
    return rootGetters['checkout/cartItemExistsByCategoryAndJurisdiction'](type, getters.jurisdiction)
  },
  cartEmpty: (_state, _getters, _rootState, rootGetters) => rootGetters['checkout/cartEmpty'],

  /* ======== active filings and services ========
  ============================================= */
  formACompanyFiling: (_state, _getters, _rootState, rootGetters) => rootGetters['companies/formACompanyFiling'],
  freeFormACompanyFiling: (_state, _getters, _rootState, rootGetters) => rootGetters['companies/freeFormACompanyFiling'],
  vehicleFormACompanyFiling: (_state, _getters, _rootState, rootGetters) => rootGetters['companies/vehicleFormACompanyFiling'],
  isFormationFiling: (_state, _getters) => (filingName) => {
    return ['vehicle form a company', 'form a company', 'free form a company'].includes(filingName)
  },
  jurisdictionFilings: (_state, getters, _rootState, rootGetters) => {
    return rootGetters['companies/activeOrderItemsByJurisdiction'](getters.jurisdiction)
  },
  activeServiceExistsByCategory: (_state, getters, _rootState, rootGetters) => (type) => {
    // We have to include subscriptionBundles in this getter because of how the filters work logically, since we're
    // hacking 'subscription-bundle' into the service filter columns even though it's not literally a service type.
    if (type === 'subscription-bundle') {
      return rootGetters['companies/activeSubscriptionsByJurisdiction'](getters.jurisdiction).length > 0
    } else {
      return rootGetters['companies/activeServicesByTypeAndJurisdiction'](type, getters.jurisdiction).length > 0
    }
  },


  /* ======== stage and step filters ========
  ======================================== */
  filterByServicesShow: (_state, getters) => (type, st) => {
    let show = true
    if (st.filter_services_show.length) {
      show = getters.activeServiceExistsByCategory(type) || getters.cartItemExistsByCategory(type)
    }
    return !show
  },
  filterByServices: (_state, getters, _rootState, rootGetters) => (type, st) => {
    let hide = false
    const einFilingEligible = (st.title === 'EIN' || st.filter_filings.includes('tax id'))
      ? !rootGetters['orderItems/einFilingOrderEligibility']() : false

    if (st.filter_services.length) {
      hide = getters.activeServiceExistsByCategory(type)
        || getters.cartItemExistsByCategory(type)
        || einFilingEligible
    }
    return hide
  },
  filterByJurisdictionShow: (_state, getters) => st => {
    let show = true
    if ('filter_jurisdictions_show' in st && st.filter_jurisdictions_show.length) {
      show = st.filter_jurisdictions_show.includes(getters.jurisdiction.abbreviation)
    }
    return !show
  },
  filterByJurisdiction: (_state, getters) => st => {
    let hide = false
    if ('filter_jurisdictions' in st && st.filter_jurisdictions.length) {
      hide = st.filter_jurisdictions.includes(getters.jurisdiction.abbreviation)
    }
    return hide
  },
  filterByEntityTypeShow: (_state, getters) => st => {
    let show = true
    if ('entity_types' in st && st.entity_types.length) {
      show = st.entity_types.includes(getters.company.entity_type_id)
    }
    return !show
  },
  filterByManagementType: (_state, getters) => st => {
    const managementType = (getters.company.details && getters.company.details.management_type) ? getters.company.details.management_type.toLowerCase() : null
    let hide = false
    if ('filter_management_type' in st && st.filter_management_type.length && managementType) {
      hide = st.filter_management_type.includes(managementType)
    }
    return hide
  },
  filterByFilingsShow: (_state, getters, _rootState, rootGetters) => st => {
    let show = true
    const filter_ = st.filter_filings_show

    if (filter_.length) {
      show = (filter_.includes('form a company') && !!getters.formACompanyFiling)
        || (filter_.includes('free form a company') && !!getters.freeFormACompanyFiling)
        || (filter_.includes('vehicle form a company') && !!getters.vehicleFormACompanyFiling)
        || getters.jurisdictionFilings.some(filing => filter_.includes(filing.product.filing_name))
        || filter_.some(category => getters.cartItemExistsByCategory(category))
        || filter_.some(category => {
          return rootGetters['companies/activeOrderItemsByCategoryAndJurisdiction'](category, getters.jurisdiction).length > 0
        })
    }
    return !show
  },
  filterByFilings: (_state, getters, _rootState, rootGetters) => st => {
    let hide = false
    const filter_ = st.filter_filings

    if (filter_.length) {
      hide = (filter_.includes('form a company') && !!getters.formACompanyFiling)
        || (filter_.includes('free form a company') && !!getters.freeFormACompanyFiling)
        || (filter_.includes('vehicle form a company') && !!getters.vehicleFormACompanyFiling)
        || getters.jurisdictionFilings.some(filing => filter_.includes(filing.product.filing_name))
        || filter_.some(category => getters.cartItemExistsByCategory(category))
        || filter_.some(category => {
          return rootGetters['companies/activeOrderItemsByCategoryAndJurisdiction'](category, getters.jurisdiction).length > 0
        })
    }
    return hide
  },
  filterByServiceShow: (_state, getters) => st => {
    return st.filter_services_show.some(type => getters.filterByServicesShow(type, st))
  },
  filterByService: (_state, getters) => st => {
    return st.filter_services.some(type => getters.filterByServices(type, st))
  },

  /* ======== filter functions ========
  ================================== */
  showStageOrStep: (_state, getters) => st => {
    const isStage = ('step_count' in st)
    const stageHasSteps = (isStage && st.step_count > 0)

    if (isStage && !stageHasSteps) { return false }

    // let filterBySubscriptionShow = getters.filterBySubscriptionShow(st)
    let filterByJurisdictionShow = getters.filterByJurisdictionShow(st)
    let filterByFilingsShow = getters.filterByFilingsShow(st)
    let filterByServiceShow = getters.filterByServiceShow(st)
    let filterByEntityTypeShow = getters.filterByEntityTypeShow(st)

    // Filter _filter
    let filterByJurisdiction = getters.filterByJurisdiction(st)
    let filterByManagementType = getters.filterByManagementType(st)
    let filterByFilings = getters.filterByFilings(st)
    let filterByService = getters.filterByService(st)
    // let filterBySubscription = getters.filterBySubscription(st)

    const hide = filterByJurisdictionShow
    || filterByFilingsShow
    || filterByServiceShow
    || filterByEntityTypeShow

    || filterByJurisdiction
    || filterByManagementType
    || filterByFilings
    || filterByService

    // If hide is true, then we want the .filter of javascript to not keep it, so return false
    return !hide
  },
  stageFilterFunction: (_state, getters) => stage => {
    // I really think this should be switched over to the same nomenclature and storing as steps.
    // aka. filter_jurisdictions and it should just be storing the abbreviation
    if (stage.jurisdictions.length) {
      if (!stage.jurisdictions.includes(getters.jurisdiction.abbreviation)) {
        return false
      }
    }

    // TODO we probably don't need these filters anymore, since we now are doing foreign in Expand stageline
    // if (stage.locality === 'foreign') {
    //   if (getters.jurisdiction.state_province_region === getters.company.domestic_registration.jurisdiction.state_province_region) {
    //     return false
    //   }
    // } else if (stage.locality === 'domestic') {
    //   if (getters.jurisdiction.state_province_region !== getters.company.domestic_registration.jurisdiction.state_province_region) {
    //     return false
    //   }
    // }

    return getters.showStageOrStep(stage)
  },
  stepFilterFunction: (_state, getters) => step => {
    if(step.layout_type === 'checkout' && getters.cartEmpty) {
      return false
    }
    return getters.showStageOrStep(step)
  },
  filteredStages: (state, getters) => {
    const filteredStages = getters.stages
      .filter(stage => stage.company_stage.completed_at !== null ||
        stage.id === getters.currentStage?.id ||
        getters.stageFilterFunction(stage)
      )
    return filteredStages
  },
  filteredSteps: (state, getters) => {
    if (getters.currentStage) {
      return getters.currentStage.stage_steps
        .filter(step => getters.completedStepIds.includes(step.id) ||
          step.id === getters.currentStep?.id ||
          getters.stepFilterFunction(step)
        )
    }
  },
}

const ACTIONS = {
  resetStageline({ commit }, startOver) {
    commit(t.RESET_STAGELINE, startOver)
  },
  // Set the local indicators for stage and step complete. This way we don't need to reload them every time
  // Do this before is_complete decisions are made below since it's possible this step was filtered
  // This might unnecessary now that we don't filter current step or stage
  async updateStage({ state, getters, dispatch }, { details }) {
    state.currentStage.company_stage.completed_step_ids.push(getters.currentStep.id)

    let indexOfCurrentStageInStateStages = state.stages.findIndex(s => s.id === state.currentStage.id)

    if(getters.stepIsLast) {
      state.stages[indexOfCurrentStageInStateStages].company_stage.completed_at = ''
    }

    dispatch('companies/updateCompanyStage', {
      stage_id: getters.currentStage.id,
      details: details,
      is_complete: getters.stepIsLast,
      stageline_is_complete: getters.stageIsLast && getters.stepIsLast,
      step_id: getters.currentStep.id,
    }, { root: true })
  },
  async loadStages({ commit, state, getters }) {
    commit(t.SET_STAGELINE_LOADED, false)
    commit(t.CLEAR_STAGES)
    let params = {}
    if (getters.company && state.jurisdiction) {
      params = {
        company_id: getters.company.id,
        jurisdiction_id: state.jurisdiction.id,
        stageline_type: state.currentStagelineType,
      }
    }
    const response = await http.get(`client/stages`, { params })

    commit(t.SET_STAGES, response.data.result)
    commit(t.SET_STAGELINE_LOADED, true)
  },
  async setCurrentStage({ commit, getters, dispatch }, index) {
    commit(t.SET_STAGE_LOADED, false)

    if(getters.filteredStages.length === 0) {
      commit(t.SET_STAGE_LOADED, true)
      return
    }
    if (index < 0) {
      index = 0
    } else if (index >= getters.filteredStages.length) {
      index = getters.filteredStages.length - 1
    }

    const id       = getters.filteredStages[index].id
    const response = await http.get(`client/stages/${id}`, {
      params: {
        company_id: getters.company.id,
        jurisdiction_id: getters.jurisdiction.id,
      },
    })
    commit(t.SET_CURRENT_STAGE, response.data.result)

    const lastCompletedStep = getters.filteredSteps
      .filter(step => getters.completedStepIds.includes(step.id))
      .pop()

    const stepIndex = lastCompletedStep !== undefined ?
      getters.filteredSteps.findIndex(step => step.id == lastCompletedStep.id) + 1 : 0

    dispatch('setCurrentStep', stepIndex)

    commit(t.SET_STAGE_LOADED, true)
  },
  async setCurrentStep({ commit, getters }, index) {
    commit(t.SET_STEP_LOADED, false)
    if (getters.filteredSteps.length == 0) {
      commit(t.SET_STEP_LOADED, true)
      return
    }
    if (index < 0) {
      index = 0
    } else if (index >= getters.filteredSteps.length) {
      index = getters.filteredSteps.length - 1
    }

    // Reset the routing to be normal stageline in case of internal step routing was used in previous step
    if (getters.route.name !== 'stageline') {
      await router.push({ name: 'stageline', params: getters.route.params })
    }

    commit(t.SET_CURRENT_STEP, getters.filteredSteps[index])
    commit(t.SET_STEP_LOADED, true)
  },
  async clearCompanyStages({ getters }, companyId){
    if(getters.startOver){
      await axiosClient.post(`client/stages/clear_company_stages_and_start_over`, {
        company_id: companyId,
      })
    }
  },
  setJurisdiction({ commit }, jurisdiction) {
    commit(t.SET_JURISDICTION, jurisdiction)
  },
  setStagelineType({ commit }, stagelineType) {
    commit(t.SET_STAGELINE_TYPE, stagelineType)
  },
  setStepLoaded({ commit }, loaded) {
    commit(t.SET_STEP_LOADED, loaded)
  },
  setStartOver({ commit }, restart) {
    commit(t.SET_START_OVER, restart)
  },
}

const MUTATIONS = {
  [t.RESET_STAGELINE](state, startOver) {
    const newState = initialState()
    newState.startOver = startOver

    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  [t.SET_STAGES](state, stages) {
    state.stages = stages
  },
  [t.SET_CURRENT_STAGE](state, stage) {
    state.currentStage = stage
  },
  [t.SET_CURRENT_STEP](state, step){
    state.currentStep = step
  },
  [t.SET_JURISDICTION](state, jurisdiction) {
    state.jurisdiction = jurisdiction
  },
  [t.SET_STAGELINE_TYPE](state, stagelineType) {
    state.currentStagelineType = stagelineType
  },
  [t.SET_START_OVER] (state, restart) {
    state.startOver = restart
  },
  [t.CLEAR_STAGES](state) {
    state.stages      = []
    state.stageLoaded = false
  },
  [t.SET_STEP_LOADED](state, loaded) {
    state.stepLoaded = loaded
  },
  [t.SET_STAGE_LOADED](state, loaded) {
    state.stageLoaded = loaded
  },
  [t.SET_STAGELINE_LOADED](state, loaded) {
    state.stagelineLoaded = loaded
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
