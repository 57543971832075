export const caProvinces = [
  { abbreviation: 'AB', stateProvinceRegion: 'Alberta' },
  { abbreviation: 'BC', stateProvinceRegion: 'British Columbia' },
  { abbreviation: 'MB', stateProvinceRegion: 'Manitoba' },
  { abbreviation: 'NB', stateProvinceRegion: 'New Brunswick' },
  { abbreviation: 'NL', stateProvinceRegion: 'Newfoundland and Labrador' },
  { abbreviation: 'NT', stateProvinceRegion: 'Northwest Territories' },
  { abbreviation: 'NS', stateProvinceRegion: 'Nova Scotia' },
  { abbreviation: 'NU', stateProvinceRegion: 'Nunavut' },
  { abbreviation: 'ON', stateProvinceRegion: 'Ontario' },
  { abbreviation: 'PE', stateProvinceRegion: 'Prince Edward Island' },
  { abbreviation: 'QC', stateProvinceRegion: 'Quebec' },
  { abbreviation: 'SK', stateProvinceRegion: 'Saskatchewan' },
  { abbreviation: 'YT', stateProvinceRegion: 'Yukon' },
]

