import { DateTime } from 'luxon'
import {
  fromIso,
  isBeforeStartOfToday,
  isOverAYearOld,
  dayMonthDayFormat,
} from '@/helpers'

// +15091112222 => +1 (509) 111-2222
export const formatPhoneWithCountry = a => a.replace(/(.{2})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4')

// 5091112222 => (509) 111-2222
export const formatPhoneWithoutCountry = a => a.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')

export const formatPhoneToCountryCodeStyle = (virtualPhoneNumber) => {
  return virtualPhoneNumber.replace(/[()\s-]/g, '').replace(/^/g, '+1')
}

// +1 (509) 111-2222 => +15091112222
export const formatPhoneToCountryCodeStyleWithout1 = (virtualPhoneNumber) => {
  return virtualPhoneNumber.replace(/[()\s-]/g, '')
}

// 1 (999) 222-1234 || (888) 707-9877 => 9992221234
export const formatPhoneToString = (number) => {
  if(number.charAt(0) === 1){
    return number.replace(/(1\s[(])|([)]\s)|(-)/g, '')
  } else {
    return number.replace(/([(])|([)]\s)|(-)/g, '')
  }
}

export const formatRemovingCountryCode = (number) => {
  return number.replace(/^(\+\d{1})/g, '')
}

export const capFirstLetter = (eventType) => {
    return eventType.charAt(0).toUpperCase() + eventType.slice(1)
}

export const formatDateTime = (timestamp) => {
  const d = new Date(timestamp)
  return { 'date': parseDate(d), 'time': parseTime(d) }
}

const parseTime = (timestamp) => {
  let hours = timestamp.getHours()
  let minutes = timestamp.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes
  const strTime = hours + ':' + minutes + ampm
  return strTime
}

const parseDate = (timestamp) => {
  const months = ["01", "02", "03", "04", "05", "06", "07",
    "08", "09", "10", "11", "12"]

  return `${months[timestamp.getMonth()]}/${timestamp.getDate()}/${timestamp.getFullYear()}`
}

export const generateFriendlyNumber = (virtualPhoneLog) => {
  const firstCharacter = virtualPhoneLog.slice(0,1)

  switch (firstCharacter) {
    case '+':
      return virtualPhoneLog.slice(0, 2)
        + ` (${virtualPhoneLog.slice(2, 5)}) `
        + virtualPhoneLog.slice(5, 8)
        + '-'
        + virtualPhoneLog.slice(8)
    default:
      return virtualPhoneLog
  }
}

export const formatPhone = number => {
  const numString = number ? number.toString() : ''

  switch (numString.length) {
    case 10:
      return formatPhoneWithoutCountry(numString)
    case 12:
      return formatPhoneWithCountry(numString)
    default:
      return numString
  }
}

export const formattedNumber = input => {
  return '+1' + input.replace(/\D/g,'')
}

export const returnDateForBrowserTimezone = (timestamp) => {
  return DateTime.fromISO(timestamp)
}

// Millis => 'Oct 27, 2020'
export const formatDateString = input => {
  return DateTime.fromMillis(Date.parse(input)).toFormat('DD')
}

// Seconds => 'Oct 27, 2020'
export const formatDateStringFromSeconds = input => {
  return DateTime.fromSeconds(input).toFormat('DD')
}

// 1680658208 => 'Apr 4, 2023'
export const formatDateStringFromUnix = input => {
  return new Date(input * 1000).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
}

// Iso => 'Dec 10, 2018'
export const formatFullDate = input => fromIso(input).toLocaleString(DateTime.DATE_MED)

// Iso => 'Sat, Dec 10'
export const formatSimpleDate = input => fromIso(input).toLocaleString(dayMonthDayFormat)

// Iso => '4:34pm'
export const formatSimpleTime = input => fromIso(input).toLocaleString(DateTime.TIME_SIMPLE)

// Iso => 'Sunday, Dec. 11, 2020 at 4:34pm'
export const formatDateTimeLong = input => {
  const time = fromIso(input).toFormat('t').toLowerCase().split(" ").join("")
  const date = fromIso(input).toFormat('cccc, LLL. d, yyyy')
  return `${date} at ${time}`
}

// ISO => Full date, simple date, or simple time depending on how recent the timestamp is.
export const formatDateFriendly = input => {
  switch (true) {
    case isOverAYearOld(input):
      return formatFullDate(input)
    case isBeforeStartOfToday(input):
      return formatSimpleDate(input)
    default:
      return formatSimpleTime(input)
  }
}

// Fri Jul 21 2023 09:17:44 GMT-0700 (Pacific Daylight Time) => mm/dd/yyyy
export const formatStandardDateFromIso = input => {
  const parsedDate = DateTime.fromJSDate(new Date(input))
  return parsedDate.toUTC().toFormat('MM/dd/yyyy')
}

// Epoch => 'mm/dd/yyyy' ----> 1690387783 => 07/26/2023
export const epochTimeConverter = input => {
  if (typeof(input) === 'undefined') return null

  const toIso = DateTime.fromSeconds(input).toISO()
  return fromIso(toIso).toFormat('MM/dd/yyyy')
}

// 'YYYY-MM-DD' => Epoch
export const formatEpochFromStringDate = input => {
  const dateTime = DateTime.fromISO(input)
  return dateTime.toSeconds()
}

// 'YYYY-MM-DD' => Mon Mar 31 2025 17:00:00 GMT-0700 (Pacific Daylight Time)
export const formatDateObjectFromStringDate = input => {
  const epochDate = formatEpochFromStringDate(input)
  return new Date(epochDate * 1000)
}

export const formatCCExpiration = input => {
  let { month, year } = input

  month = month < 10 ? `0${month}` : month
  year = year?.toString().length === 4 ? year.toString().slice(2,4) : year

  return `${month}/${year}`
}

export const notificationWith = (variant, value) => {
  return {
    variant,
    value,
  }
}
