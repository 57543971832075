// Based on the forms on https://xpsshipper.com/ec/#/ship
// optional = xps has a field for it, but it's not required
export const xpsFields = {
  postalCodeRequired: [
    'US', 'DZ', 'AS', 'AD', 'AR', 'AM', 'AU', 'AT', 'AZ', 'BD', 'BY', 'BE', 'BA', 'BR',
    'BN', 'BG', 'KH', 'CA', 'IC', 'CN', 'HR', 'CU', 'CY', 'CZ', 'DK', 'EC', 'EE', 'FO',
    'FI', 'FR', 'GF', 'GE', 'DE', 'GR', 'GL', 'GP', 'GU', 'GG', 'HU', 'IS', 'IN', 'ID',
    'IL', 'IT', 'JP', 'JE', 'KZ', 'XK', 'KG', 'LV', 'LI', 'LT', 'LU', 'MK', 'MG', 'MY',
    'MV', 'MH', 'MQ', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MA', 'NL', 'NC', 'NZ',
    'MP', 'NO', 'PK', 'PW', 'PG', 'PH', 'PL', 'PT', 'PR', 'RO', 'RU', 'BL', 'RS', 'SG',
    'SK', 'SI', 'ZA', 'KR', 'ES', 'SZ', 'SE', 'CH', 'TW', 'TJ', 'TH', 'TN', 'TR', 'UM',
    'VI', 'UA', 'GB', 'UZ',
  ],
  postalCodeOptional: [
    'AL', 'BM', 'CL', 'CO', 'CR', 'DO', 'ET', 'GH', 'HK', 'IE', 'JO',
    'MT', 'NG', 'PE', 'QA', 'SA', 'LK', 'TZ', 'UG', 'AE', 'VN', 'ZW',
  ],
  stateProvinceRequired: ['US', 'AU', 'BN', 'CA', 'JP', 'KG', 'PH', 'TJ', 'UZ'],
  stateProvinceOptional: [
    'AL', 'AD', 'AR', 'AM', 'AT', 'AZ', 'BD', 'BY', 'BE', 'BA', 'BG', 'CL', 'CN', 'HR',
    'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'GE', 'GH', 'GR', 'GU', 'HK', 'HU', 'IS', 'IN',
    'IE', 'IL', 'IT', 'KZ', 'XK', 'LV', 'LI', 'LT', 'LU', 'MK', 'MT', 'MX', 'MD', 'MC',
    'ME', 'NL', 'AN', 'NZ', 'NG', 'NO', 'PG', 'PL', 'PT', 'RO', 'RU', 'RS', 'SG', 'SK',
    'SI', 'KR', 'ES', 'SE', 'CH', 'SY', 'TW', 'TR', 'UA', 'AE', 'GB',
  ],
}
