// Stageline
export const SET_STAGELINE_LOADED = 'set_stageline_loaded'
export const RESET_STAGELINE = 'reset_stageline'
export const SET_JURISDICTION = 'set_jurisdiction'
export const SET_STAGES = 'set_stages'
export const SET_CURRENT_STAGE = 'set_current_stage'
export const SET_CURRENT_STEP = 'set_current_step'
export const SET_STAGELINE_TYPE = 'set_stageline_type'
export const SET_STAGE_LOADED = 'set_stage_loaded'
export const SET_STEP_LOADED = 'set_step_loaded'
export const CLEAR_STAGES = 'clear_stages'
export const SET_START_OVER = 'set_start_over'
