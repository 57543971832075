<template>
  <b-container class="payment-method-form-container">
    <b-form-row v-if="allowExistingAddresses && availableAddresses().length > 0">
      <b-col class="col-12">
        <b-form-group>
          <b-form-select
            v-model="selectedExistingAddress"
            :options="availableAddresses()"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">
                -- Select an Existing Address --
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col v-if="showContactName" class="col-12">
        <b-form-group label="To:">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Contact Name"
          >
            <b-form-input
              v-model="address.contact_name"
              type="text"
              name="contactName"
              autocomplete="address-contact_name"
              :state="!errors[0] ? null: false"
              :readonly="contactNameReadonly"
            />
            <b-form-invalid-feedback :state="!errors[0] ? null: false">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col class="col-12">
        <b-form-group label="Line 1:">
          <validation-provider
            v-slot="{ errors }"
            :rules="isPhoneContact ? '' : 'required'"
            name="Line 1 of the Address"
          >
            <b-form-input
              v-model="address.line1"
              type="text"
              name="line1"
              autocomplete="address-line1"
              :state="!errors[0] ? null: false"
            />
            <b-form-invalid-feedback :state="!errors[0] ? null: false">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col class="col-12">
        <b-form-group label="Line 2:">
          <validation-provider
            v-slot="{ errors }"
            name="Line 2 of the Address"
          >
            <b-form-input
              v-model="address.line2"
              type="text"
              placeholder="Suite, Apt #..."
              :state="!errors[0] ? null: false"
            />
            <b-form-invalid-feedback :state="!errors[0] ? null: false">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col :cols="cityCols" :md="cityCols">
        <b-form-group label="City:">
          <validation-provider
            v-slot="{ errors }"
            :rules="isPhoneContact ? '' : 'required'"
            name="City"
          >
            <b-form-input
              v-model="address.city"
              type="text"
              :state="!errors[0] ? null: false"
            />
            <b-form-invalid-feedback :state="!errors[0] ? null: false">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col v-if="showStateProvince" cols="12" md="6">
        <b-form-group v-if="usCountrySelected" label="State:">
          <validation-provider
            v-slot="{ errors }"
            :rules="isPhoneContact ? '' : 'required'"
            name="State"
          >
            <b-form-select
              v-model="address.state_province_region"
              :options="usStates()"
              :state="!errors[0] ? null: false"
              :disabled="!usCountrySelected"
            >
              <template slot="first">
                <b-form-select-option :value="null" disabled>
                  -- Select a State --
                </b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback :state="!errors[0] ? null: false">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group v-else-if="canadaSelected" label="Province:">
          <validation-provider
            v-slot="{ errors }"
            :rules="isPhoneContact ? '' : 'required'"
            name="Province"
          >
            <b-form-select
              v-model="address.state_province_region"
              :options="caProvinces()"
              :state="!errors[0] ? null: false"
              :disabled="!canadaSelected"
            >
              <template slot="first">
                <b-form-select-option :value="null" disabled>
                  -- Select a Province --
                </b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback :state="!errors[0] ? null: false">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group v-else label="State/Province:">
          <validation-provider
            v-slot="{ errors }"
            :rules="isPhoneContact ? '' : (stateProvinceRequired ? 'required' : '')"
            name="State Or Province"
          >
            <b-form-input
              v-model="address.state_province_region"
              type="text"
            />
            <b-form-invalid-feedback :state="!errors[0] ? null: false">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col v-if="showPostalCode" :class="showKind ? 'col-3' : 'col-6'" cols="12" md="6">
        <b-form-group v-if="usCountrySelected" label="Zipcode:">
          <validation-provider
            v-slot="{ errors }"
            :rules="isPhoneContact ? 'zipcode' : 'required|zipcode'"
            name="Zipcode"
          >
            <b-form-input
              v-model="address.zip_postal_code"
              type="text"
              maxlength="10"
              :state="!errors[0] ? null: false"
            />
            <b-form-invalid-feedback :state="!errors[0] ? null: false">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group v-else label="Zipcode:">
          <validation-provider
            v-slot="{ errors }"
            :rules="isPhoneContact ? '' : (postalCodeRequired ? 'required' : '')"
            name="Zipcode"
          >
            <b-form-input
              v-model="address.zip_postal_code"
              type="text"
              :state="!errors[0] ? null: false"
            />
            <b-form-invalid-feedback :state="!errors[0] ? null: false">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col :class="showKind ? 'col-5' : 'col-6'" :cols="countryCols" :md="countryCols">
        <b-form-group label="Country:">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Country"
          >
            <b-form-select
              v-model="address.country"
              :options="countries()"
              :state="!errors[0] ? null: false"
            />
            <b-form-invalid-feedback :state="!errors[0] ? null: false">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col v-if="showKind" class="col-12" cols="12" md="12">
        <b-form-group label="Kind:">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Kind"
          >
            <b-form-select
              v-model="address.kind"
              :options="kindOptions"
              :state="!errors[0] ? null: false"
            />
            <b-form-invalid-feedback :state="!errors[0] ? null: false">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-form-row>
  </b-container>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { countries } from '@/common/modules/countries'
import { usStates } from '@/common/modules/usStates'
import { caProvinces } from '@/common/modules/caProvinces'
import { mapGetters, mapActions } from 'vuex'
import { xpsFields } from '@/common/modules/xpsFields'

export default {
  name: 'AddressForm',
  components: { ValidationProvider },
  props: {
    allowExistingAddresses: {
      type: Boolean,
      default: false,
    },
    showKind: {
      type: Boolean,
      default: true,
    },
    editAddress: {
      type: Object,
      default: null,
    },
    kind: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    showContactName: {
      type: Boolean,
      default: false,
    },
    contactNameReadonly:{
      type: Boolean,
      default: false,
    },
    isPhoneContact: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return this.initialData()
  },
  computed: {
    ...mapGetters(
      'account', ['account', 'addresses', 'people'],
    ),
    usCountrySelected() {
      return this.address.country === 'US'
    },
    canadaSelected() {
      return this.address.country === 'CA'
    },
    showPostalCode() {
      return this.xpsFields().postalCodeRequired.includes(this.address.country)
        || this.xpsFields().postalCodeOptional.includes(this.address.country)
    },
    showStateProvince() {
      return this.xpsFields().stateProvinceRequired.includes(this.address.country)
        || this.xpsFields().stateProvinceOptional.includes(this.address.country)
    },
    postalCodeRequired() {
      return this.xpsFields().postalCodeRequired.includes(this.address.country)
    },
    stateProvinceRequired() {
      return this.xpsFields().stateProvinceRequired.includes(this.address.country)
    },
    cityCols() {
      return this.showStateProvince ? 6 : 12
    },
    countryCols() {
      return this.showPostalCode ? 6 : 12
    },
  },
  watch: {
    address: {
      deep: true,
      handler() {
        this.$emit('changed', this.address, this.index)
      },
    },
    selectedExistingAddress() {
      if (this.selectedExistingAddress !== null) {
        this.address = this.selectedExistingAddress
        if (this.countryNeedsFormatting()) {
          this.formatCountry()
        }
      }
    },
    editAddress() {
      if (this.editAddress !== null) {
        this.address = { ...this.address, ...this.editAddress }
      }
      else{
        Object.assign(this.$data, this.initialData())
      }
    },
    kind() {
      if (this.kind !== '') {
        this.address.kind = this.kind
      }
    },
  },
  async beforeMount() {
    if (this.allowExistingAddresses) {
      await this.loadAccount()
    }
  },
  mounted() {
    if (this.editAddress !== null) {
      this.address = { ...this.address, ...this.editAddress }
    }
    if (this.kind !== '') {
      this.address.kind = this.kind
    }
  },
  methods: {
    ...mapActions(
      'account', [
        'updatePersonAddress',
        'createPersonAddress',
        'loadAccount',
      ],
    ),
    usStates() {
      let states = usStates.map((et) => [{
        text: et.stateProvinceRegion,
        value: et.abbreviation,
      }]).flat()
      states.unshift({ text: 'Other', value: '' })
      return states
    },
    caProvinces() {
      return caProvinces.map((provinceObj) => [{
        text: provinceObj.stateProvinceRegion,
        value: provinceObj.abbreviation,
      }]).flat()
    },
    countries: () => countries,
    xpsFields: () => xpsFields,
    availableAddresses() {
      let addresses = this.addresses.map((a) => [{
        text: this.getAvailableAddressText(a),
        value: a,
      }]).flat()
      return addresses
    },
    getAvailableAddressText(a){
      return `${a.line1}, ${a.city}, ${a.state_province_region} ${a.zip_postal_code}, ${a.country}`
    },
    countryNeedsFormatting() {
      return this.address.country.length > 2
    },
    formatCountry() {
      countries.every(country => {
        if(this.address.country === country['text']){
          this.address.country = country['value']
          return false
        }
        return true
      })
    },
    errorToast(error) {
      this.$bvToast.toast(error, { title: 'Error', variant: 'danger' })
    },
    initialData() {
      return {
        editInfo: null,
        address: {
          line1: null,
          line2: null,
          zip_postal_code: null,
          city: null,
          state_province_region: null,
          country: 'US',
          kind: 'primary',
          contact_name: null,
        },
        kindOptions: ['mailing', 'alternate', 'billing', 'primary'],
        selectedExistingAddress: null,
      }
    },
  },
}
</script>

<style scoped>

</style>
