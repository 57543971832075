import http from '@/http'
import * as t from "@/store/mutations"

const state = {
  allOptions: [],
  otherOptions: [],
}

const getters = {
  // Looks a little weird, but ensure feedback form choices are the last options in the list.
  allOptions: state => {
    let feedbackOptions = state.allOptions.filter(o => state.otherOptions.includes(o))
    let allOptions = state.allOptions.filter(o => !state.otherOptions.includes(o))

    return allOptions.concat(feedbackOptions)
  },
  allOptionsGeneric: (state, getters) => getters.allOptions.filter(option => option.service_type === 'generic'),
  allOptionsForServiceType: (state, getters) => (service_type) => {
    return getters.allOptions.filter(option => option.service_type === service_type).concat(state.otherOptions)
  },
  otherOptions: state => state.otherOptions,
  selectedOptionId: state => state.selectedOptionId,
}

const actions = {
  async getAllOptions({ commit }) {
    await http.get('client/cancellations/available_options')
      .then(res => {
        const availableOptions = res.data.result
        let otherOptions = []

        availableOptions.map((option) => {
          if(option.cancellation_reason === 'Other' || option.cancellation_reason === 'Unhappy with Service or System') {
            otherOptions.push(option)
          }
        })

        commit(t.SET_ALL_OPTIONS, availableOptions)
        commit(t.SET_OTHER_OPTIONS, otherOptions)
      })
      .catch((err) => {console.log(err)})
  },
}

const mutations = {
  [t.SET_ALL_OPTIONS] (state, availableOptions ) {
    state.allOptions = availableOptions
  },
  [t.SET_OTHER_OPTIONS] (state, otherOptions ) {
    state.otherOptions = otherOptions
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
