import packageJson from '../package.json'
import axiosClient from './http'
import CtFileDrop from '@/components/shared/CtFileDrop'
import CtAutoSave from '@/components/shared/CtAutoSave'
import ContactModal from '@/components/ContactModal'
import { initDataMapPackage } from '@core/data-map-frontend/src/main.js'

// Let Data Map know if you make breaking changes for any of the following
// Or you could do a search in here and see if it would break anything if you really wanted to :)
// /node_modules/@core/data-map-frontend

// Data Map uses these components:
// <ct-file-drop />
// <ct-auto-save />
// <contact-modal />

// Data Map uses these store interactions:
// account/peopleWithAllRequiredInfo
// checkout/addToCart
// checkout/raServiceActiveOrInCart
// jurisdictions/findByName

export function dataMapPackageInit({ Vue, store }) {
  const http = axiosClient
  const components = { CtFileDrop, CtAutoSave, ContactModal }
  initDataMapPackage({ packageJson, Vue, store, http, components })
}
