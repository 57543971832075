import * as t from '../mutations'

const STATE = {
  all: [],
}

const GETTERS = {
  getAll(state) {
    return state.all ? state.all : []
  },
}

const ACTIONS = {
  addAlert({ commit }, { all }) {
    if (all) {
      commit('setAll', { all })
    }
  },
}

const MUTATIONS = {
  setAll(state, { all }) {
    state.all = all
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
