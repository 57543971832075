export const makeToastMixin = {
  methods: {
    successToast(title, body) {
      this.toast('primary', title, body)
    },
    errorToast(title, body) {
      this.toast('danger', title, body)
    },
    warningToast(title, body) {
      this.toast('warning', title, body)
    },
    toast(option, title, body) {
      this.$bvToast.toast(body, {
        title: title,
        variant: option,
        solid: true,
      })
    },
  },
}
