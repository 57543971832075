import * as t from '../mutations'
import http from '@/http'
import {
  state as BaseState,
  actions as BaseActions,
  getters as BaseGetters,
  mutations as BaseMutations,
} from '@/store/base'

const STATE = {
  ...BaseState,
  namespace: 'products',
  productBuckets: [],
  productCategories: [],
  optionalCategories: [],
  products: [],
  currentProduct: null,
}

const GETTERS = {
  ...BaseGetters,
  productBuckets: state => state.productBuckets,
  productCategories: state => state.productCategories,
  products: state => state.products,
  currentProduct: state => state.currentProduct,
  optionalCategories: state => state.optionalCategories,
}

const ACTIONS = {
  ...BaseActions,
  async getProductBuckets({ commit }, companyId) {
    const params = {
      company_id: companyId,
    }
    const response = await http.get(`client/products/categories`, { params })
    commit(t.SET_PRODUCT_BUCKETS, response.data.result.buckets)
  },

  async getProductCategories({ commit }, routeParams) {
    const params = {
      company_id: routeParams.companyId,
      bucket_id: routeParams.bucketId,
      category_id: routeParams.categoryId,
    }
    const response = await http.get(`client/products/categories/${routeParams.bucketId}`, { params } )
    commit(t.SET_PRODUCT_CATEGORIES, response.data.result.categories)
  },

  async getOptionalCategories({ commit }, routeParams) {
    const params = {
      company_id: routeParams.companyId,
      bucket_id: routeParams.bucketId,
      category_id: routeParams.categoryId,
    }
    const response = await http.get(`client/products/categories/` + routeParams.categoryId + '/optional_categories', { params })
    commit(t.SET_OPTIONAL_PRODUCT_CATEGORIES, response.data.result)
  },

  async getProducts({ commit }, routeParams) {
    const params = {
      company_id: routeParams.companyId,
      bucket_id: routeParams.bucketId,
      category_id: routeParams.categoryId,
    }
    const response = await http.get(`client/products/categories/${routeParams.categoryId}/products`, { params })
    const products = Array.isArray(response.data.result) ? response.data.result : [response.data.result]
    commit(t.SET_PRODUCTS, products)
  },

  async loadProductByParams({ commit }, category ) {
    const product = await http.get(
      `client/products/simple_products/search_by_category_and_website`,
      {
        params: { category: category },
      })

    commit(t.SET_SELECTED_PRODUCT, product.data.result)
  },
  async loadProductByCategory({ _ }, category) {
    const product = await http.get(
      `client/products/simple_products/search_by_category_and_website`,
      {
        params: { category: category },
      })

      return product.data.result
  },
  // eslint-disable-next-line no-empty-pattern
  async searchProductCategories({}, { companyId, categoryName, limit = 1, matchType = 'exact' } ) {
    try {
      const params = {
        company_id: companyId,
        category_name: categoryName,
        limit: limit,
        match_type: matchType,
      }
      const response = await http.get(`client/products/categories/search`, { params })

      if (response.data.success) {
        return limit === 1 && response.data.response.length ?
          response.data.response[0] :
          response.data.response
      } else {
        return { error: 'No Categories Found' }
      }
    } catch (error) {
      return { error: 'No Categories Found' }
    }
  },
}

const MUTATIONS = {
  ...BaseMutations,
  [t.SET_PRODUCT_BUCKETS](state, productBuckets) {
    state.productBuckets = productBuckets
  },
  [t.SET_PRODUCT_CATEGORIES](state, productCategories) {
    state.productCategories = productCategories
  },
  [t.SET_PRODUCTS](state, products) {
    state.products = products
  },
  [t.SET_SELECTED_PRODUCT](state, product) {
    state.currentProduct = product
  },
  [t.SET_OPTIONAL_PRODUCT_CATEGORIES](state, optionalCategories) {
    state.optionalCategories = optionalCategories
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
