import _ from 'lodash'
import { transformKeys } from '@/common/modules/object'
import { Client, GET } from '@/api/client'

class Result {
  constructor(data, success = true, failure = false) {
    this.data = data
    this.success = success
    this.failure = failure
  }
}

class TokClient {
  constructor(token) {
    this.token = token
    this.response = null
  }

  getHeaders() {
    const headers = {}
    if (this.token) {
      headers.authorization = `Bearer ${this.token}`
    }
    headers['Content-Type'] = 'application/json'
    headers['Accept'] = '*/*'
    return headers
  }

  getArgs(method) {
    return {
      method: method,
      headers: this.getHeaders(),
    }
  }

  async operation({ method, path, body, camelCase = true }) {
    const args = this.getArgs(method)
    if (body) {
      args.body = body
    }
    this.response = await fetch(`${path}`, args)
    const status = this.response.status
    let json = null

    try {
      json = await this.response.json()
      json = json.response ? json.response : json
    } catch (error) {
      json = []
    }

    if (camelCase) {
      return new Result(transformKeys(json, _.camelCase), status === 200, status !== 200)
    } else {
      return new Result(json, status === 200, status !== 200)
    }
  }
}

export default class TokenizerClient {

  TOKENIZER_ERROR_RESPONSE = { success: false, data: { message: 'Tokenizer Error' } }

  constructor(store, isGuest = false, params = null) {
    this.store = store
    this.isGuest = isGuest
    this.params = params
  }

  async fetchToken() {
    let token = this.store.getters['paymentMethods/customerToken']
    const tokenExp = this.store.getters['paymentMethods/customerTokenExp']

    // use the customer token from store until expired
    if (token && tokenExp && tokenExp > new Date().getTime()) {
      return token
    }

    const client = new Client(null, this.store.getters['session/getToken'], this.store.getters['session/getExpiration'])
    const path = '/api/v3/client/account/' + (this.isGuest ? 'guest_tokenizer_authorization' : 'tokenizer_authorization')
    const result = await client.call({
      method: GET,
      path: path,
      params: this.params,
    })

    if (result?.data?.token) {
      token = result.data.token
      this.store.dispatch('paymentMethods/setCustomerToken', token)
    } else {
      throw new Error('Unable to fetch customer token')
    }

    return token
  }

  async paymentMethods(camelCase = true) {
    try {
      const client = new TokClient(await this.fetchToken())
      return await client.operation({
        path: this.tokenizerUrl('payment_methods'),
        method: 'get',
        camelCase: camelCase,
      })
    } catch(e) {
      return this.TOKENIZER_ERROR_RESPONSE
    }
  }

  async cards(camelCase = true) {
    const client = new TokClient(await this.fetchToken())
    return await client.operation({
      path: this.tokenizerUrl('cards'),
      method: 'get',
      camelCase: camelCase,
    })
  }

  async createPaymentMethod(paymentMethod, payableType) {
    try {
      this.store.dispatch('paymentMethods/loadPaymentMethods')
      let body = paymentMethod
      body.payable_type = payableType
      if (payableType === 'card') {
        body = this.formatCardBody(transformKeys(paymentMethod, _.snakeCase))
      }
      if (payableType === 'automatedClearingHouse') {
        body = this.stripOfNullValueKeys(body)
      }
      const client = new TokClient(await this.fetchToken())
      const res = await client.operation({
        path: this.tokenizerUrl('payment_methods'),
        method: 'post',
        body: JSON.stringify(body),
      })

      if (res.success && !this.store.getters['paymentMethods/isGuestPay']) {
        await this.store.dispatch('paymentMethods/refreshPaymentMethods')
      }

      return res
    } catch (e) {
      return this.TOKENIZER_ERROR_RESPONSE
    }
  }

  async updatePaymentMethod(paymentMethod, payableType) {
    try {
      this.store.dispatch('paymentMethods/loadPaymentMethods')
      let body = paymentMethod
      if (payableType === 'card') {
        body = this.formatCardBody(transformKeys(paymentMethod, _.snakeCase))
      } else if (payableType === 'automatedClearingHouse') {
        // remove agreement indicator on update
        delete body.agreement_indicator
        body = transformKeys(paymentMethod, _.snakeCase)
      }
      body = this.stripOfNullValueKeys(body)
      const client = new TokClient(await this.fetchToken())
      const res = await client.operation({
        path: this.tokenizerUrl('payment_methods/update'),
        method: 'post',
        body: JSON.stringify(body),
      })

      if (res.success) {
        await this.store.dispatch('paymentMethods/refreshPaymentMethods')
      }
      return res
    } catch (e) {
      return this.TOKENIZER_ERROR_RESPONSE
    }
  }

  async destroyPaymentMethod(id) {
    try {
      this.store.dispatch('paymentMethods/loadPaymentMethods')
      const client = new TokClient(await this.fetchToken())
      const res = await client.operation({
        path: this.tokenizerUrl('payment_methods'),
        method: 'delete',
        body: JSON.stringify({ id }),
      })

      await this.store.dispatch('paymentMethods/refreshPaymentMethods')

      return res.data
    } catch (e) {
      return this.TOKENIZER_ERROR_RESPONSE
    }
  }

  tokenizerUrl(resource) {
    const baseUrl = process.env.VUE_APP_TOKENIZER_URL
    return baseUrl + `${resource}`
  }

  // Data mapping between tokenizer address format and CorpTools address format
  addressFromTokenizer(address) {
    return {
      line1: address.address1,
      line2: address.address2,
      city: address.city,
      state_province_region: address.state,
      zip_postal_code: address.zip,
      country: address.country,
    }
  }

  addressToTokenizer(address) {
    return {
      address1: address.line1,
      address2: address.line2,
      city: address.city,
      state: address.state_province_region,
      zip: address.zip_postal_code,
      country: address.country,
    }
  }

  formatCardBody(body) {
    body.billing_address.address1 = body.billing_address.address_1
    body.billing_address.address2 = body.billing_address.address_2

    delete body.billing_address.address_1
    delete body.billing_address.address_2

    return body
  }

  stripOfNullValueKeys(obj) {
    for (const key in obj) {
      if (obj[key] === null) {
        delete obj[key]
      }
    }
    return obj
  }
}
