import * as t from '../mutations'
import http from '@/http'
import Vue from 'vue'

import { Client, GET, POST } from '@/api/client'
import {
  ACCOUNT_DOCUMENTS,
  DOCUMENTS_BULK_DOWNLOAD,
  DOCUMENTS_PAGE_DOWNLOAD,
  PAGE_ROTATIONS,
} from '@/api/v3/endpoints'

import {
  actions as BaseActions,
  getters as BaseGetters,
  mutations as BaseMutations,
  state as BaseState,
} from '@/store/base'

const STATE = {
  ...BaseState,
  namespace: 'documents',
  urls: {},
  pages: {},
  clientDocument: {},
}

const GETTERS = {
  ...BaseGetters,
  urls: state => state.urls,
  pages: state => state.pages,
  clientDocument: state => state.clientDocument,
}

const ACTIONS = {
  ...BaseActions,
  async markRead({ rootGetters }, { id }) {
    let client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
    try {
      const result = await client.call({
        method: GET,
        path: `${ACCOUNT_DOCUMENTS}/${id}/mark_read`,
      })
      return result
    } catch (error) {
      console.error(error)
      return error
    }
  },
  async removeLock({ rootGetters }, { id }) {
    const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
    try {
      return await client.call({
        method: GET,
        path: `${ACCOUNT_DOCUMENTS}/${id}/remove_lock`,
      })
    } catch (error) {
      console.error(error)
      return error
    }
  },
  async fetchDocumentByType({ commit }, { company_id, type }) {
    const response = await http.get(`client/client_documents/for_company/${company_id}/by_type/${type}`)
    const client_document = response.data.success && Object.keys(response.data.result).length !== 0 ? response.data.result : null
    commit(t.SET_CLIENT_DOCUMENT, client_document)
  },
  async deleteClientDocument({ commit }, { id }) {
    await http.delete(`client/client_documents/${id}`)

    commit(t.RESET_CLIENT_DOCUMENT, null)
  },
  async download({ rootGetters }, { id }) {
    let client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
    try {
      const result = await client.call({
        method: GET,
        path: `${ACCOUNT_DOCUMENTS}/${id}/download`,
        params: null,
      })
      return result
    } catch (error) {
      console.log(error)
    }
  },
  async getPageURL({ commit, rootGetters }, { id, pageId }) {
    let client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
    try {
      const result = await client.call({
        method: GET,
        path: `${DOCUMENTS_PAGE_DOWNLOAD}/${id}/page/${pageId}`,
      })
      const url = result.data.url
      commit(t.CACHE_CLIENT_DOCUMENT_PAGE, { pageId })
      commit(t.CACHE_CLIENT_DOCUMENT_PAGE_URL, { pageId, url })
      return result
    } catch (error) {
      console.error(error)
    }
  },
  async getDownloadUrls({ rootGetters }, { ids }) {
    let client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
    try {
      return await client.call({
        method: POST,
        path: DOCUMENTS_BULK_DOWNLOAD,
        params: { ids },
      })
    } catch (error) {
      return error
    }
  },
  async getPageRotation({ rootGetters, commit }, { pageId }) {
    let client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
    try {
      const result = await client.call({
        method: GET,
        path: `${PAGE_ROTATIONS}/${pageId}/rotation_degrees`,
      })
      const rotation = result.data.result.rotation
      commit(t.CACHE_CLIENT_DOCUMENT_PAGE, { pageId })
      commit(t.CACHE_CLIENT_DOCUMENT_PAGE_ROTATION, { pageId, rotation })
      return rotation
    } catch (error) {
      return error
    }
  },
}

const MUTATIONS = {
  ...BaseMutations,
  [t.RESET_CLIENT_DOCUMENT](state) {
    state.urls = {}
    state.pages = {}
    state.clientDocument = {}
  },
  [t.SET_CLIENT_DOCUMENT_URLS](state, { urls }) {
    state.urls = urls
  },
  [t.CACHE_CLIENT_DOCUMENT_PAGE](state, { pageId }) {
    if (!state.pages[pageId]) { Vue.set(state.pages, pageId, {}) }
  },
  [t.CACHE_CLIENT_DOCUMENT_PAGE_URL](state, { pageId, url }) {
    Vue.set(state.pages[pageId], 'url', url)
  },
  [t.CACHE_CLIENT_DOCUMENT_PAGE_ROTATION](state, { pageId, rotation }) {
    Vue.set(state.pages[pageId], 'rotation', rotation)
  },
  [t.SET_CLIENT_DOCUMENT](state, document) {
    state.clientDocument = document
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
