import Vue from 'vue'
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate'
import { min, max, required, numeric, length } from 'vee-validate/dist/rules'

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

extend('required', {
  ...required,
  message: '{_field_} is required',
})
extend('phone_number', {
  validate(value) {
    const pattern = new RegExp(/^\d+$/)

    return value.toString().length >= 10 && pattern.test(value)
  },
  message: 'Input must be only digits and at least 10 characters long',
})
extend('max', {
  validate(value, { max }) {
    return value.length <= max
  },
  params: ['max'],
  message: '{_field_} must be less than {max} characters',
})
extend('min', min)
extend('minmax', {
  validate(value, { min, max }) {
    return value.length >= min && value.length <= max
  },
  params: ['min', 'max'],
  message: '{_field_} must be between {min} and {max} characters',
})

extend('numeric', numeric)
extend('length', length)
extend('zipcode', {
  validate(value) {
    const pattern = new RegExp(/^\d{5}([-\s]\d{4})?$/, 'g')
    return pattern.test(value)
  },
  message: 'Zipcode must follow format ##### or #####-####',
})
extend('email', {
  validate(value) {
    const pattern = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z-]*[a-z])+/, 'gi')
    return pattern.test(value)
  },
  message: 'Must be a valid email address',
})
extend('ssn', {
  validate(value) {
    const pattern = new RegExp(/^(\d{3}-?\d{2}-?\d{4})$/, 'gi')
    return pattern.test(value)
  },
  message: 'Invalid SNN, must be in XXX-XX-XXXX format',
})
extend('percentage', {
  validate(value) {
    const pattern = new RegExp(/^(?:[1-9][0-9]?|100)$/)
    return pattern.test(value)
  },
  message: 'Must be an integer in the range 1-100',
})
extend('domainEmailPasswordValidator', {
  validate: (value) => {
    const pattern = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*\W).{9,}$/)
    return pattern.test(value)
  },
  message: 'Password must contain at least one digit, one lowercase letter, one uppercase letter, ' +
    'one non-alphanumeric character, and be at least 9 characters long.',
})
extend('passwordConfirmationMatches', {
  params: ['password'],
  validate(value, { password }) { return value === password },
  message: 'Passwords do not match.',
})
extend('validDomain', {
  message: 'Domain must be lowercase and cannot contain "www", "https", or special characters.',
  validate: value => {
    const domainRegex = new RegExp(/^(?!www\.)(?!https:\/\/)([a-z0-9.-]+)\.[a-z]{2,}$/)
    return domainRegex.test(value)
  },
})
