import * as t from '@/store/mutations'
import http from '@/http'

const STATE = {
  loaded: false,
  detail: {},
  dataMapDetails: {},
  error: null,
}

const GETTERS = {
  loaded:           state => state.loaded,
  detail:           state => state.detail,
  dataMapDetails:   state => state.dataMapDetails,
}

const ACTIONS = {
  async fetch({ commit }, { companyId }){
    commit(t.LOAD_COMPANY_DETAILS_START)
    const companyPath = `client/companies/${companyId}`
    let company = null
    let registrations = null
    let schema = null
    const fields = { official: [], addresses:[], all: [], general: [] }
    const defaults = { official: [], addresses:[], all: [], general: [] }

    try {
      const response = await http.get(companyPath)
      company = response.data.result
    } catch (error) {
      commit(t.LOAD_COMPANY_DETAILS_FAILED, error)
      return
    }
    try {
      const response = await http.get(`${companyPath}/registrations`)
      registrations = response.data.result
    } catch (error) {
      commit(t.LOAD_COMPANY_DETAILS_FAILED, error)
      return
    }
    try {
      const schemaResponse = await http.get(`${companyPath}/schema`)
      schema = schemaResponse.data.result.schema

      const _extractDefaultsFrom = (field) => {
        const values = {}
        field.fields.forEach(f => {
          values[f.name] = f.meta.default
        })
        return values
      }

      for (let field of schema) {
        if (['company_name', 'entity_type', 'management_type', 'company_purpose', 'home_state'].includes(field.name)) {
          fields.general.push(field)
        } else if (field.name === 'official') {
          fields.official.push(field)
        } else if(['company_principal_address', 'company_mailing_address'].includes(field.name)){
          fields.addresses.push(field)
        } else if (field.type !== 'hidden') {
          if (!fields.all.find(i => i.name === field.name)) {
            fields.all.push(field)
          }
        } else {
          Object.keys(fields).forEach(key => {
            fields[key].push(field)
          })
        }
      }

    } catch (error) {
      commit(t.LOAD_COMPANY_DETAILS_FAILED, error)
    }

    commit(t.LOAD_COMPANY_DETAILS_COMPLETED, { company, registrations, schema, fields, defaults })
  },
}

const MUTATIONS = {
  [t.LOAD_COMPANY_DETAILS_START](state){
    state.loaded = false
  },
  [t.LOAD_COMPANY_DETAILS_FAILED](state, error){
    state.error = error
  },
  [t.LOAD_COMPANY_DETAILS_COMPLETED](state, detail){
    state.detail = detail
    state.dataMapDetails = detail.company.data_map_details
    state.loaded = true
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
