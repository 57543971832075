import * as t from '../mutations'
import http from '@/http'

const STATE = {
  discounts: [],
  clickToRequest: {
    serviceDiscount: 0,
    shippingDiscount: 0,
  },
  loaded: false,
}

const GETTERS = {
  discounts: state => state.discounts,
  companyDiscounts: (state, getters) => company_id => getters.discounts.filter(discount => discount.company_id === company_id),
}

const ACTIONS = {
  async loadDiscounts({ commit }) {
    const response = await http.get('client/discounts')
    commit(t.SET_DISCOUNTS, response.data.result)
  },
}

const MUTATIONS = {
  [t.SET_DISCOUNTS](state, discounts) {
    state.discounts = discounts
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
