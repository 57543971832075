import * as t from '../mutations'
import http from '@/http'

const STATE = {
  faq_information: [],
}

const GETTERS = {
  faqInformation: state => state.faq_information,
}

const ACTIONS = {
  async fetchFaqInformation({ commit }) {
    try {
      const response = await http.get('/client/faq_categories/categories_with_pages')
      commit(t.SET_FAQ_INFORMATION, response.data.result)
    } catch (error) {
      return error
    }
  },
}

const MUTATIONS = {
  [t.SET_FAQ_INFORMATION](state, faqInformation) {
    state.faq_information = faqInformation
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}

