var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "root-flex-container" } },
    [
      _c("connection-info", { ref: "connection-info" }),
      _vm.loaded ? _c("top-navigation") : _vm._e(),
      _vm.loaded ? _c("boi-faqs-banner") : _vm._e(),
      _vm.loaded ? _c("items-requiring-attention-banner") : _vm._e(),
      _c("alert-manager"),
      _c(
        "div",
        { staticClass: "d-flex w-100", attrs: { id: "main" } },
        [
          _vm.needsVirtualPhoneSidebar
            ? _c("virtual-phone-sidebar", { ref: "virtualPhoneSidebar" })
            : _vm._e(),
          _vm.wrapInContentFlexContainer
            ? _c(
                "div",
                {
                  class: _vm.contentFlexContainerClasses,
                  attrs: { id: "content-flex-container" },
                },
                [
                  _c(
                    "b-container",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.routeLoading,
                          expression: "!routeLoading",
                        },
                      ],
                      ref: "mainContent",
                      staticClass: "main",
                    },
                    [_c("router-view")],
                    1
                  ),
                  _c(
                    "b-container",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.routeLoading,
                          expression: "routeLoading",
                        },
                      ],
                      staticClass: "nav-spinner-container",
                    },
                    [_c("ct-centered-spinner")],
                    1
                  ),
                  _c("live-help-now"),
                ],
                1
              )
            : _c("router-view"),
        ],
        1
      ),
      _vm._m(0),
      _c("footer", { staticClass: "tos-footer-container" }, [
        _vm.loaded && !_vm.isAccountWholesaler && !_vm.routeLoading
          ? _c("p", { staticClass: "tos-footer" }, [
              _c(
                "a",
                {
                  staticStyle: { color: "grey" },
                  attrs: {
                    href: _vm.websiteTosUrl,
                    target: "_blank",
                    "aria-label": "Terms of Service link",
                  },
                },
                [_vm._v("Terms of Service\n      ")]
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("audio", { attrs: { id: "remoteAudio" } }, [
      _c("p", [_vm._v("Your browser doesn't support HTML5 audio.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }