import { transformKeys } from '@/common/modules/object'
import { snakeCase } from '@/common/modules/strings'
import fetch from 'node-fetch'
import _ from 'lodash'
import store from '../store'

import { AUTH_REFRESH } from '@/api/v3/endpoints'
import axios from 'axios'

export const monitoringApiClient = axios.create({
  baseURL: `${process.env.VUE_APP_MONITORING_URL}`,

  transformRequest:
    [
      (data, headers) => {
        Object.assign(headers, {
          Authorization: `Bearer ${store.getters['session/getToken']}`,
        })

        return data
      },
      ...axios.defaults.transformRequest,
    ],
})

export const apiV3AxiosClient = axios.create({
  baseURL: '/api/v3/',

  transformRequest:
    [
      (data, headers) => {
        Object.assign(headers, {
          Authorization: `Bearer ${store.getters['session/getToken']}`,
        })

        return data
      },
      ...axios.defaults.transformRequest,
    ],
})

export default monitoringApiClient

export const POST = 'post'
export const GET = 'get'
export const PUT = 'put'
export const DELETE = 'delete'

export const refreshURL = AUTH_REFRESH

/*
  @constructor
  let client = new Client(base, token, expiration)

  @params
  base: String -  defaults to baseURL if useDefaultBase is true
  token: String - Is included in Authorization header when present
  expiration: Number - Time in seconds used with token to determine if token should be refreshed
  useDefaultBase: Boolean - Flag to use baseURL in all calls
*/
export class Client {
  constructor(
    base = null,
    token = null,
    expiration = null,
    useDefaultBase = true,
  ) {
    this.base = base
    this.token = token
    this.expiration = expiration
    this.useDefaultBase = useDefaultBase
  }

  setCredentials(token = null, expiration = null) {
    this.token = token
    this.expiration = expiration

    store.commit('session/setToken', { token })
    store.commit('session/setExpiration', { expiration })
  }

  async refresh() {
    const args = this.makeBody({ method: GET })
    const response = await fetch(`${refreshURL}`, args)
    const result = await this.makeResult(response)

    this.setCredentials(result.data.token, result.data.expiration)

    return result
  }

  /*
    @example
    client.call({ method: GET, path: '', params, body })

    @params
    method: String - GET,POST,PUT,DELETE
    path: String - Path to call (If baseURL is defined this is prepended)
    params: Object - key/values to send in request
    body: Any - When body is present and method is  PUT, body argument is assigned to request body
    transform: Object - Object can contain keys before, after - each keys value should be a method.
                Method is applied before to outgoing parameters and after on response
  */
  async call(
    { method, path, params, body },
    // authenticate = true, // commented out for later use
    transform = { before: snakeCase, after: _.camelCase }
  ) {
    const args = this.makeBody({ method, path, params, body, transform })

    let endpoint = path
    if (args.query) {
      endpoint = `${endpoint}?${args.query}`
      delete args.query
    }

    let response = null
    let result = null

    try {
      const request = new Request(endpoint, args)
      response = await fetch(request)
      result = await this.makeResult(response)
      if (response && response.status === 401) {
        global.capturePathAndClearState()
      }
    } catch (error) {
      result = error
    }

    return result
  }

  makeHeaders() {
    const result = {
      Accept: '*',
      'Content-Type': 'application/json',
    }
    if (this.token) {
      result['Authorization'] = `Bearer ${this.token}`
    }
    return result
  }

  makeBody({ method, params, body, transform }) {
    const result = {
      method: method,
      headers: this.makeHeaders(),
    }

    if (params && !isEmpty(params) && [POST, PUT].includes(method)) {
      result.body =
        transform && transform.before
          ? JSON.stringify(transformKeys(params, transform.before))
          : JSON.stringify(params)
    }

    if (params && !isEmpty(params) && [GET].includes(method)) {
      if (transform && transform.before) {
        params = transformKeys(params, transform.before)
      }
      const keys = Object.keys(params)
      result.query = keys.map(key => key + '=' + params[key]).join('&')
    }

    if (body && !isEmpty(body)) {
      result.body = body
    }

    return result
  }

  async makeResult(response) {
    let json = null

    try {
      json = await response.json()
      json = transformKeys(json, _.camelCase)
      json = json.result ? json.result : json.data ? json.data : json
    } catch (error) {
      // Just suppressing the error
    }

    const data = json ? json : response.data ? transformKeys(response.data, _.camelCase) : response

    return new Result(data, response.ok, !response.ok)
  }
}

export class Result {
  constructor(data = {}, success = true, failure = false) {
    this.data = data
    this.success = success
    this.failure = failure
  }
}

export const formattedResponse = (data = null, success = null, error = null) => {
  return {
    data: transformKeys(data, _.camelCase),
    success,
    error,
  }
}

const isEmpty = something => {
  if (_.isObjectLike(something)) {
    return something && Object.entries(something).length === 0
  } else if (_.isArrayLike(something) || _.isString(something)) {
    return something && something.length === 0
  }
  return false
}
