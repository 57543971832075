import cloneDeep from 'lodash/cloneDeep'
import axiosClient from '../../http'
import genId from 'lodash/uniqueId'
import _ from 'lodash'
import store from '../../store'

export function fillFormFromDetails(schema, details, prefill = true) {
  // prefillDetailsWithSuggestion appeared to be overwriting local company details due to
  // arrays/objects being passed by reference, so clone
  details = cloneDeep(details)

  if (prefill) details = prefillDetailsWithSuggestion(schema, details || {})

  if (details) {
    const multipleKeys = schema.filter(f => 'can_have_multiple' in f && f['can_have_multiple'] === true).map(f => f.name)
    const [formValues, suggestionFields] = getCleanDetails(details, multipleKeys)
    return [formValues, suggestionFields]
  }

  return [{}, {}]
}

function removeDisclaimerFieldIfPresent (formValues) {
  if ('disclaimer' in formValues) {
    delete formValues['disclaimer']
  }
}

function syncManagementTypeIfNecessary (formValues) {
  const managerField = formValues['official.manager']

  if (managerField && managerField.role === 'Member') {
    const newFieldName = `official.member__${genId()}`
    Object.defineProperty(formValues, newFieldName, Object.getOwnPropertyDescriptor(formValues, 'official.manager'))
    delete formValues['official.manager']
  }
}

export function appendSuggestionValues (form, formValues) {
  Object.keys(formValues).forEach(k => {
    const field = form.find(f => f.name === k)

    if (field && field.meta.suggestion && typeof(formValues[k]) === 'object') {
      if (typeof(field.meta.suggestion?.suggestionUsed) !== 'undefined') {
        formValues[k].suggestionUsed = field.meta.suggestion?.suggestionUsed
      }
    }
  })
}

export function isFilingItem(item) {
  return item.type === 'filing'
}

export function isCreditCardOrder(item) {
  return item.type === 'merchant-service'
}

export function isRegisteredAgent(item) {
  return item.type === 'registered-agent'
}

export function isShoppingCartFiling(item) {
  return item.type === 'shopping-cart-filing'
}

export function determineObjectType(item) {
  if (isFilingItem(item) || isCreditCardOrder(item)) {
    return 'order-items'
  } else {
    return 'services'
  }
}

export function formatManagementTypeAndOfficals(multipleKeys, formValues) {
  syncManagementTypeIfNecessary(formValues)
  formValues = formatPeopleData(multipleKeys, formValues)
  return formValues
}

export function formatPeopleData (multipleKeys, formValues) {
  // We loop through our form values and if it can have multiple, we have to group all similar
  // values into an array and push that into form values at the given key.
  let peopleData = {}
  const keysToDelete = []

  Object.keys(formValues).forEach(k => {
    let key = k

    if (key === 'official') {
      keysToDelete.push(key)
      return
    }

    // If this condition is true, you can assume 'k' is in the format official.*role*.*id*
    if (isOfficialUnderscoreKey(key)) {
      key = k.substring(0, k.indexOf('_'))
      keysToDelete.push(k)
    }

    if (Object.values(multipleKeys).includes(key)) {
      // The filingData people record is a multiple one.

      peopleData[key] = peopleData[key] || []
      Array.isArray(formValues[k]) ?
        formValues[k].forEach(val => peopleData[key].push(val)) :
        peopleData[key].push(formValues[k])
    } else {
      peopleData[key] = formValues[k]
    }
  })

  keysToDelete.forEach(key => {
    if (formValues[key]) {
      delete formValues[key]
    }
  })

  formValues = { ...formValues, ...peopleData }
  return formValues
}

export function normalizeOfficials(form, formValues) {
  const multipleKeys = form.filter(f => 'can_have_multiple' in f && f['can_have_multiple'] === true).map(f => f.name)
  formValues = breakOfficialArrayByRoles(multipleKeys, formValues)
  return formValues
}

export function removeCountyFields(form) {
  form.map(formItem => {
    if (formItem?.fields) {
      formItem.fields = formItem.fields.filter(field => field.name !== 'county')
      const addressIndex = formItem.fields.findIndex(field => field.name === 'address')
      if (addressIndex > -1) {
        formItem.fields[addressIndex].fields =
          formItem.fields[addressIndex].fields.filter(field => field.name !== 'county')
      }
    }
    return formItem
  })
  return form
}

export function breakOfficialArrayByRoles (multipleKeys, formValues) {
  const formattedOfficials = {}
  Object.keys(formValues).forEach(key => {
    if (key === 'official' && Array.isArray(formValues[key])) {
      formValues[key].forEach(official => {
        if (!official.role) return

        const officialKey = 'official.' + official.role.toLowerCase()
        if (formValues[officialKey]) return

        if (!formattedOfficials[officialKey]) {
          formattedOfficials[officialKey] = Array.isArray(formValues[officialKey]) ? formValues[officialKey] : []
        }

        formattedOfficials[officialKey].push(official)
      })
      delete formValues['official']
    }
  })

  formValues = { ...formValues, ...formattedOfficials }
  return formValues
}

export async function orderItemCompleted(object) {
  try {
    if (object.object_type === 'order-items') {
      await axiosClient.patch(`client/order_items/${object.object_id}`, {
        order_item: {
          completed: true,
        },
      })
    } else {
      await axiosClient.post(`client/services/set_data`, {
        id: object.object_id,
        completed: true,
      })
    }
    return [{ success: true, message: 'Submitted.' }]
  } catch {
    return [{ success: false, message: 'Submitting the form failed. Please try again.' }]
  }
}

export async function saveDetails({ form, formValues, item, formCompleted= true }) {
  try {
    let orderItemData = {}
    const multipleKeys = form.filter(f => 'can_have_multiple' in f && f['can_have_multiple'] === true).map(f => f.name)
    removeDisclaimerFieldIfPresent(formValues)
    const beforeSyncFormValues = cloneDeep(formValues)
    syncManagementTypeIfNecessary(formValues)

    if (isFilingItem(item) || isCreditCardOrder(item)) {
      const mergedFormValues = { ...item.data.default, ...formValues }
      let formattedFilingData = cloneDeep(mergedFormValues)
      formattedFilingData = formatPeopleData(multipleKeys, formattedFilingData)

      const response = await axiosClient.patch(`client/order_items/${item.id}`, {
        order_item: {
          data: { default: formattedFilingData },
          completed: formCompleted,
        },
      })

      if (response.data.success && response.data.result.data) orderItemData = response.data.result.data
    } else if (isShoppingCartFiling(item)) {
      // update the shopping cart item data
      const mergedFormValues = { ...item.data.default, ...formValues }
      let formattedFilingData = cloneDeep(mergedFormValues)
      formattedFilingData = formatPeopleData(multipleKeys, formattedFilingData)
      let cartItemData = { default: formattedFilingData }
      const cartItem = store.getters['checkout/findCartItemById'](item.id)

      if (cartItem.product_type === 'ProductBundle') {
        const bundleItemData = cartItem.data
        const subTypeProductInBundle = Object.values(bundleItemData).find(value =>
          value.product_id === item.product.id
        )
        subTypeProductInBundle.data = { default: formattedFilingData }
        cartItemData = bundleItemData
      }

      await store.dispatch('checkout/updateCartItemData', {
        id: item.id,
        data: cartItemData,
      }, { root: true })

      orderItemData = { default: formattedFilingData }
    } else {
      let formattedServiceData = cloneDeep(formValues)
      formattedServiceData = formatPeopleData(multipleKeys, formattedServiceData)

      await axiosClient.post(`client/services/set_data`, {
        id: item.id,
        service: { data: formattedServiceData },
        completed: formCompleted,
      })
    }

    formValues = beforeSyncFormValues
    appendSuggestionValues(form, formValues)
    formValues = formatPeopleData(multipleKeys, formValues)
    try {
      const mergeDetails = { ...item.company.details, ...formValues }
      const result = await axiosClient.post(`client/companies/${item.company.id}/update_company_details`, {
        details: mergeDetails,
      })

      if (result.data.success) await store.dispatch('companies/updateCompanyDetails', result.data.result)
    } catch (error) {
      // Just suppressing this because we don't need to notify clients of a potential company details update error.
    }

    return [{ success: true, message: 'Submitted.', orderItemData: orderItemData }]
  } catch {
    return [{ success: false, message: 'Submitting the form failed. Please contact customer service.' }]
  }
}

export async function createDocumentFromForm (orderItem, form) {
  const formFields = form.children.filter(field => 'required' in field)
  const containAnswers = formFields.filter(field => field._value)

  const formData = containAnswers.reduce((acc, field) => {
    const title = field.title.replace(/^\d*\.\s*/, '')
    acc[title] = field._value
    return acc
  }, {})

  try {
    await axiosClient.post(`client/client_documents/create_document_from_form`, {
      form: {
        form_id: orderItem.id,
        form_name: orderItem.name,
        form_data: formData,
      },
    })
  } catch {
    return [{ success: false, message: 'Failed to create document from form.' }]
  }
}

/**
 * TODO: replace saveDetails for new forms
 * TODO: or maybe delete, moved to datamap testing
 * Submits the inputted form values.
 *
 * @param {Array<Object>} form array of form field objects from schema_for
 * @param {Object} formValues name of fields to inputted field values
 * @param {Object} item order or service to save details for
 * @param {boolean} formCompleted
 *
 * @returns {Promise} Promise array contains object representing result of form submission.
 */
export async function alternateSaveDetails({ form, formValues, item, formCompleted = true }) {
  try {
    let responseData = {}

    const itemType = (isFilingItem(item) || isCreditCardOrder(item)) ? 'order-items' : 'services'

    const filteredFormValues = filterFormValuesBeforeSubmission(form, formValues, item, itemType)
    const companyDetails     = filterFormValuesBeforeSubmission(form, formValues, item, itemType, 'company-details')
    const response = await axiosClient.post(`client/schema/save_form`, {
      item: {
        id:         item.id,
        company_id: item.company.id,
        type:       itemType,
        data:       filteredFormValues,
        completed:  formCompleted,
      },
      details: companyDetails,
    })

    if (!response.success) {
      throw 'Save form failed'
    }

    if (itemType === 'order-items' && response.data.success && response.data.result.data) {
      responseData = response.data.result.data
    }

    return [{ success: true, message: 'Submitted.', orderItemData: responseData }]
  } catch {
    // TODO V2RPT-773 better error for failed validations the frontend can use
    return [{ success: false, message: 'Submitting the form failed. Please try again.' }]
  }
}

// Filter and format our form values correctly before submitting the form.
function filterFormValuesBeforeSubmission(form, formValues, item, itemType, filterType = '') {
  formValues = cloneDeep(formValues) // Deep clone a copy to return so we don't modify the argument.

  removeDisclaimerFieldIfPresent(formValues)

  if (filterType === 'company-details') {
    appendSuggestionValues(form, formValues)
  } else { // For order items and services.
    syncManagementTypeIfNecessary(formValues)

    if (itemType === 'order-items') {
      formValues = { ...item.data.default, ...formValues }
    }
  }

  const multipleKeys = form.filter(f => f['can_have_multiple'] === true).map(f => f.name)
  formatPeopleData(multipleKeys, formValues)

  return formValues
}

function isOfficialUnderscoreKey(key) {
  return key.includes('official') && key.includes('_')
}

export function prefillDetailsWithSuggestion(schema, details) {
  details = cloneDeep(details)

  schema.forEach(definition => {
    const addressProvided = definition?.meta?.type === 'address' && details[definition.name]?.line1

    const raServiceNotActiveOrInCart = !(definition?.meta?.suggestion?.context?.active_ra_service ||
        store.getters['checkout/raServiceInCart']) &&
      definition?.meta?.suggestion?.context?.name === 'registered-agent-service'

    if (addressProvided || (raServiceNotActiveOrInCart && definition.name !== 'filer')) return

    if (definition?.meta?.suggestion?.context?.pre_fill_content) {

      if (definition.type === 'string') {
        details[definition.name] = definition.meta.suggestion.context.pre_fill_content
        definition.suggestionUsed = true
      } else {
        if (definition?.meta?.type === 'address' &&
          details[definition.name] &&
          (details[definition.name] === 'REGISTERED_AGENT_ADDRESS' || 'line1' in details[definition.name]) &&
          'address' in definition.meta.suggestion.context.pre_fill_content) {
          details[definition.name] = {
            ...definition.meta.suggestion.context.pre_fill_content.address,
            suggestionUsed: true,
          }
        } else {
          details[definition.name] = {
            ...definition.meta.suggestion.context.pre_fill_content,
            suggestionUsed: true,
          }
        }
      }
    }
  })
  return details
}

//removes redundant keys for baseline properties with arrays for fields that can have multiple
export function getCleanDetails(details, multipleKeys) {
  const keysToFilter = []
  const result = cloneDeep(details)
  const suggestionFields = {}
  //if a multi key contains an array of items we can delete the extra _ keys of that base key
  multipleKeys.forEach(key => {
      if (details[key] && details[key].length > 0) {
          keysToFilter.push(key)
      }
  })

  for (const key of Object.keys(details)) {
      if (isOfficialUnderscoreKey(key)) {
          const baseKey = key.substring(0, key.indexOf('_'))
          if (keysToFilter.includes(baseKey)) {
              delete result[key]
          }
      }
      if (key === "entity_type" && store.getters['companies/currentCompany'].entity_type) {
        result[key] = store.getters['companies/currentCompany'].entity_type
      }
      if (result[key]?.suggestionUsed) {
        suggestionFields[key] = details[key]
      }
  }
  return [result, suggestionFields]
}

export function mergeOfficialsIntoArray(data) {
  let peopleData = {}
  const keysToDelete = []

  Object.keys(data).forEach(k => {
    let key = k

    if (isOfficialUnderscoreKey(key)) {
      key = k.substring(0, k.indexOf('_'))
      keysToDelete.push(k)

      if (!peopleData[key]) {
        peopleData[key] = Array.isArray(data[k]) ? data[k] : []
      } else {
        if (!Array.isArray(peopleData[key]) && typeof(peopleData[key]) === 'object') {
          peopleData[key] = [peopleData[key]]
        }
      }

      peopleData[key].push(data[k])
    } else {
      peopleData[key] = data[k]
    }
  })

  keysToDelete.forEach(key => {
    if (data[key]) {
      delete data[key]
    }
  })

  data = { ...data, ...peopleData }
  return data
}
