<template>
  <b-modal id="contact-modal" ref="contact-modal" :title="title" hide-footer size="lg">
    <form ref="form" @submit.prevent="saveContact">
      <validation-observer ref="observer" v-slot="{ invalid }">
        <div v-if="currentContact.contact_is_company" key="company-fields">
          <b-row>
            <b-col>
              <label>Company name</label>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="5">
              <validation-provider v-slot="{ errors }" rules="required" name="CompanyName">
                <b-form-input
                  v-model="currentContact.contact_company_name"
                  placeholder="Company Name"
                  type="text"
                  :state="errors.length > 0 ? !errors[0] : null"
                />
              </validation-provider>
            </b-col>
          </b-row>
        </div>
        <div v-else key="personal-fields">
          <b-row>
            <b-col cols="5">
              <label>First name</label>
            </b-col>
            <b-col cols="7" md="5">
              <label>Last name</label>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="5">
              <validation-provider v-slot="{ errors }" rules="required" name="FirstName">
                <b-form-input
                  v-model="currentContact.first_name"
                  placeholder="Enter first name"
                  type="text"
                  :state="errors.length > 0 ? !errors[0] : null"
                />
              </validation-provider>
            </b-col>
            <b-col cols="7" md="5">
              <validation-provider v-slot="{ errors }" :rules="isPhoneContact ? '' : 'required'" name="LastName">
                <b-form-input
                  v-model="currentContact.last_name"
                  placeholder="Enter last name"
                  type="text"
                  :state="errors.length > 0 ? !errors[0] : null"
                />
              </validation-provider>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-checkbox v-model="currentContact.contact_is_company" class="mt-1 mb-2 pl-3 ml-4">
            Contact Is a Company
          </b-checkbox>
        </b-row>
        <b-row>
          <b-col>
            <label>Email</label>
          </b-col>
        </b-row>
        <b-row v-for="(record, index) in currentContact.person_emails" :key="record.id" class="mb-1">
          <b-col cols="2" class="pr-0">
            <b-form-select v-if="index" v-model="record.kind" :options="emailKindOptions" />
            <b-form-select v-else value="primary" :options="emailKindOptions" disabled />
          </b-col>
          <b-col cols="10" md="8" class="pl-0">
            <validation-provider v-slot="{ errors }" :rules="isPhoneContact ? 'email' : 'required|email'" name="Email">
              <b-form-input
                v-model="record.email_address"
                placeholder="Enter an email"
                type="text"
                :state="errors.length > 0 ? !errors[0] : null"
              />
              <p class="small red">
                {{ errors[0] }}
              </p>
            </validation-provider>
          </b-col>
          <b-col v-if="currentContact.person_emails.length > 1" cols="2">
            <b-button
              class="red"
              variant="link"
              aria-label="remove button"
              @click="removeEmail(record)"
            >
              Remove
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-button
            variant="link"
            class="mt-2 mb-2 pl-3 ml-0"
            aria-label="add alternative email button"
            @click="addNewEmail"
          >
            <i>
              <feather-icon type="plus" />
            </i>
            Add alternative email
          </b-button>
        </b-row>
        <b-row>
          <b-col>
            <label>Phone number</label>
          </b-col>
        </b-row>
        <b-row v-for="(record, index) in currentContact.person_phones" :key="record.id">
          <b-col cols="2" class="pr-0">
            <b-form-select v-if="index" v-model="record.kind" :options="phoneKindOptions" />
            <b-form-select v-else value="primary" :options="phoneKindOptions" disabled />
          </b-col>
          <b-col cols="10" md="4" class="pl-0">
            <validation-provider v-slot="{ errors }" rules="required|phone_number" name="Phone">
              <b-form-input
                v-model="record.phone_number"
                v-mask="'###########'"
                class="mb-1"
                placeholder="Enter a phone number"
                :state="errors.length > 0 ? !errors[0] : null"
              />
              <p class="small red">
                {{ errors[0] }}
              </p>
            </validation-provider>
          </b-col>
          <b-col cols="2">
            <b-button
              v-if="isPhoneContact && !loading"
              class="mt-2"
              variant="secondary"
              :disabled="loading"
              @click="callContact(record.phone_number)"
            >
              <div v-if="!loading">
                Call
              </div>
            </b-button>
            <b-button
              v-if="currentContact.person_phones.length > 1"
              class="red"
              variant="link"
              aria-label="remove button"
              @click="removePhone(record)"
            >
              Remove
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-button
            variant="link"
            class="mt-2 mb-2 pl-3 ml-0"
            aria-label="add alternative phone button"
            @click="addNewPhone"
          >
            <i>
              <feather-icon type="plus" />
            </i>
            Add alternative phone
          </b-button>
        </b-row>
        <b-row>
          <b-col>
            <label>Address</label>
          </b-col>
        </b-row>
        <div v-for="(record, index) in currentContact.person_addresses" :key="record.id">
          <address-form
            :edit-address="record"
            :show-kind="true"
            :index="index"
            :is-phone-contact="isPhoneContact"
            @changed="addressInfoChanged"
          />
          <b-button
            v-if="currentContact.person_addresses.length > 1"
            class="red"
            variant="link"
            aria-label="remove button"
            @click="removeAddress(record, index)"
          >
            Remove
          </b-button>
          <hr>
        </div>
        <b-row>
          <b-button
            variant="link"
            class="mt-2 mb-2 pl-3 ml-0"
            aria-label="add alternative address button"
            @click="addNewAddress"
          >
            <i>
              <feather-icon type="plus" />
            </i>
            Add alternative address
          </b-button>
        </b-row>
        <div class="d-flex justify-content-center">
          <div id="btn-submit">
            <b-button
              class="mt-4 submit-btn-width"
              variant="primary"
              type="submit"
              aria-label="submit button"
              :disabled="loading"
            >
              <div v-if="!loading">
                Save Contact
              </div>
              <b-spinner v-else small />
            </b-button>
          </div>
        </div>
      </validation-observer>
    </form>
  </b-modal>
</template>

<script>
import FeatherIcon from '@/components/shared/FeatherIcon'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { email, required } from 'vee-validate/dist/rules'
import { countries } from '@/common/modules/countries'
import { usStates } from '@/common/modules/usStates'
import { mapActions, mapGetters } from 'vuex'
import AddressForm from './shared/forms/AddressForm'

extend('email', email)
extend('required', {
  ...required,
  message: 'This field is required',
})

export default {
  name: 'ContactModal',
  components: { FeatherIcon, ValidationObserver, ValidationProvider, AddressForm },
  props: {
    title: String,
    type: String,
    phoneNumber: String,
    isPhoneContact: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      formattedAddresses: null,
      newEmailIndex: 1,
      newPhoneIndex: 1,
      errors: [],
    }
  },
  computed: {
    ...mapGetters('account', ['currentContact']),
    ...mapGetters('virtualPhones', ['selectedPhone']),
    usStates() {
      return usStates.map(et => ({ text: et.stateProvinceRegion, value: et.abbreviation }))
    },
    countries: () => countries,
    emailKindOptions() {
      return [
        'Primary',
        'Alternate',
        'Attorney',
      ].map(option => ({ text: option, value: option.toLowerCase() }))
    },
    addressKindOptions() {
      return [
        'Primary',
        'Attorney',
        'Alternate',
        'Mailing',
        'Principle',
        'Billing',
      ].map(option => ({ text: option, value: option.toLowerCase() }))
    },
    phoneKindOptions() {
      return [
        'Primary',
        'Alternate',
        'Fax',
        'Attorney',
      ].map(option => ({ text: option, value: option.toLowerCase() }))
    },
  },
  watch: {
    async phoneNumber() {
      await this.setCurrentContact({ contact: this.getEmptyContact() })
    },
  },
  methods: {
    ...mapActions('account', ['createContact', 'setCurrentContact', 'updatePerson', 'deletePersonPhone', 'deletePersonAddress', 'deletePersonEmail']),
    newAddress() {
      return {
        line1: null,
        line2: null,
        zip_postal_code: null,
        city: null,
        state_province_region: null,
        country: 'US',
        kind: 'primary',
      }
    },
    addNewEmail() {
      this.currentContact.person_emails.push({ email_address: '', kind: 'alternate', index: this.newEmailIndex++ })
    },
    addNewPhone() {
      this.currentContact.person_phones.push({ phone_number: '', kind: 'alternate', index: this.newPhoneIndex++ })
    },
    addNewAddress() {
      this.currentContact.person_addresses.push(this.newAddress())
    },
    addressInfoChanged(info, index) {
      this.currentContact.person_addresses[index] = info
    },
    makeToast(variant, title, body) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
      })
    },
    async removeEmail(record) {
      if (record.id) {
        try {
          await this.deletePersonEmail({ email_id: record.id })
          this.makeToast('primary', 'Success', 'Email Deleted')
        } catch (error) {
          this.makeToast('danger', 'Error', error.response.data.error.message)
        }
      } else {
        this.currentContact.person_emails = this.currentContact.person_emails.filter(pe => pe.index !== record.index)
      }
    },
    async removePhone(record) {
      if (record.id) {
        try {
          await this.deletePersonPhone({ phone_id: record.id })
          this.makeToast('primary', 'Success', 'Phone Deleted')
        } catch (error) {
          this.makeToast('danger', 'Error', error.response.data.error.message)
        }
      } else {
        this.currentContact.person_phones = this.currentContact.person_phones.filter(pp => pp.phone_number !== record.phone_number)
      }
    },
    async removeAddress(record, index) {
      if (record.id) {
        try {
          await this.deletePersonAddress({ address_id: record.id })
          this.makeToast('primary', 'Success', 'Address Deleted')
        } catch (error) {
          this.makeToast('danger', 'Error', error.response.data.error.message)
        }
      } else {
        this.currentContact.person_addresses.splice(index, 1)
      }
    },
    close() {
      this.$emit('contact-modal-close')
      this.$refs['contact-modal'].hide()
    },
    async show(contact) {
      if (contact) {
        await this.setCurrentContact({ contact: contact })
      } else {
        await this.setCurrentContact({ contact: this.getEmptyContact() })
      }
      this.$refs['contact-modal'].show()
    },
    async saveContact() {
      const valid = await this.$refs.observer.validate()

      if (!valid) {
        return
      }

      this.loading = true

      try {
        // Check the first contact for normally required fields to accommodate phone contacts.
        const personEmailAttributes = this.currentContact.person_emails[0]?.email_address ? this.currentContact.person_emails : null
        const personAddressAttributes = this.currentContact.person_addresses[0]?.line1 ? this.currentContact.person_addresses : null

        personAddressAttributes?.forEach(a => {
          if(!a.state_province_region) a.state_province_region = a.city
          if(!a.zip_postal_code) a.zip_postal_code = "00000"
        })

        const params = {
          id: this.currentContact.id,
          first_name: this.currentContact.contact_is_company ? '' : this.currentContact.first_name,
          last_name: this.currentContact.contact_is_company ? '' : this.currentContact.last_name,
          substitute_ra_address: this.currentContact.substitute_ra_address,
          person_emails_attributes: personEmailAttributes,
          person_phones_attributes: this.currentContact.person_phones,
          person_addresses_attributes: personAddressAttributes,
          contact_is_company: this.currentContact.contact_is_company,
          contact_company_name: this.currentContact.contact_is_company ? this.currentContact.contact_company_name : null,
        }
        let successMessage = ''

        if (this.type === 'add') {
          successMessage = 'Contact added successfully!'
          await this.createContact(params)
        } else {
          successMessage = 'Contact successfully updated!'
          await this.updatePerson({ person: params })
        }

        this.$emit('update-contacts', params)

        this.makeToast('primary', 'Success', successMessage)
        this.close()
        this.loading = false
      } catch (error) {
        this.makeToast('danger', 'Error', error.response.data.error.message)
        this.loading = false
      }
      this.$emit('contact-submitted')
    },
    getEmptyContact() {
      return {
        first_name: '',
        last_name: '',
        contact_is_company: false,
        contact_company_name: '',
        person_emails: [
          {
            email_address: '',
            kind: 'primary',
          },
        ],
        person_phones: [
          {
            phone_number: this.phoneNumber || '',
            kind: 'primary',
          },
        ],
        person_addresses: [
          this.newAddress(),
        ],
      }
    },
    async callContact(phoneNumber) {
      await this.$router.push({ name: 'call',
        params: {
          virtualPhoneId: this.selectedPhone.id,
          destinationNumber: phoneNumber,
          outgoingCall: true,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.red {
  color: red
}

@media only screen and (max-width: 660px) {

}
</style>
