import * as t from '../mutations'
import http from '@/http'

const STATE = {
  ephemeralMessages: [],
}

const GETTERS = {
  ephemeralMessages: state => state.ephemeralMessages,
}

const ACTIONS = {
  async loadEphemeralMessages ({ commit }) {
    const response = await http.get('client/ephemeral_messages')
    commit(t.SET_EPHEMERAL_MESSAGES, response.data.result)
  },
  async updateStatus ({ commit }, { ephemeralMessageId }) {
    const response = await http.put(`/client/ephemeral_messages/${ephemeralMessageId}`, {})

    commit(t.UPDATE_EPHEMERAL_MESSAGES, response.data.result)
  },
  // eslint-disable-next-line no-empty-pattern
  async createMessage ({ }, { body, messageType, docId }) {
    await http.post('/client/ephemeral_messages', { body: body, message_type: messageType, doc_id: docId })
  },
}

const MUTATIONS = {
  [t.SET_EPHEMERAL_MESSAGES] (state, ephemeralMessages) {
    state.ephemeralMessages = ephemeralMessages
  },
  [t.UPDATE_EPHEMERAL_MESSAGES] (state, ephemeralMessage) {
    let ephemeralMessages = state.ephemeralMessages
    state.ephemeralMessages = ephemeralMessages.filter((em) => { return em.id !== ephemeralMessage.id })
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
