export const usStates = [
  {
    id: '96bc7c85-3c1c-4149-9dce-6822788e4559',
    abbreviation: 'ND',
    stateProvinceRegion: 'North Dakota',
  },
  { id: '22b8d340-2b6c-4555-9994-b33e4174c556', abbreviation: 'OH', stateProvinceRegion: 'Ohio' },
  { id: '69a7c6cb-9e62-498c-9819-47e1ffc44b23', abbreviation: 'OR', stateProvinceRegion: 'Oregon' },
  {
    id: '7c72cc4a-9900-4170-b7d1-213d7af1215f',
    abbreviation: 'PA',
    stateProvinceRegion: 'Pennsylvania',
  },
  {
    id: '7e151f6c-2e30-4c11-bc65-d35450b9292e',
    abbreviation: 'RI',
    stateProvinceRegion: 'Rhode Island',
  },
  {
    id: '5246ef69-537a-477a-86a9-7e095ffa8cf2',
    abbreviation: 'SC',
    stateProvinceRegion: 'South Carolina',
  },
  {
    id: '1506e9b3-2e80-4096-b279-b26e3bc7fd62',
    abbreviation: 'SD',
    stateProvinceRegion: 'South Dakota',
  },
  { id: '13c5ad50-dcd2-4a24-8889-519b51b46b1a', abbreviation: 'TX', stateProvinceRegion: 'Texas' },
  { id: '6fe8ebf3-784a-4ef4-b07f-3c364970e1ca', abbreviation: 'UT', stateProvinceRegion: 'Utah' },
  {
    id: '77d9c9af-d59d-4622-a0a7-22bb50756e6a',
    abbreviation: 'VA',
    stateProvinceRegion: 'Virginia',
  },
  {
    id: '5d62cf4c-9cac-4088-94b6-b7b2197ed74d',
    abbreviation: 'DC',
    stateProvinceRegion: 'District of Columbia',
  },
  {
    id: '7639101f-f403-42ba-9ccf-7a6aed1eb769',
    abbreviation: 'WV',
    stateProvinceRegion: 'West Virginia',
  },
  {
    id: 'fe5e6ca1-a46f-4a32-967f-fa2c638ec485',
    abbreviation: 'LA',
    stateProvinceRegion: 'Louisiana',
  },
  { id: 'd2645571-b368-4843-a5b2-6fc6289d4d90', abbreviation: 'ME', stateProvinceRegion: 'Maine' },
  {
    id: '7a8ad305-7af5-4b64-a10f-229b1ad696b4',
    abbreviation: 'MD',
    stateProvinceRegion: 'Maryland',
  },
  {
    id: '56408bb0-eaab-4755-8271-6eb8e7180af8',
    abbreviation: 'MA',
    stateProvinceRegion: 'Massachusetts',
  },
  {
    id: '4feb0de3-8861-4051-aed6-5b88803e7216',
    abbreviation: 'MI',
    stateProvinceRegion: 'Michigan',
  },
  {
    id: '51d7d3e7-0bfe-4c64-bc8f-c2cf0a9fcfe8',
    abbreviation: 'MN',
    stateProvinceRegion: 'Minnesota',
  },
  {
    id: '59b8f287-7084-41de-8f49-ccaa32685b93',
    abbreviation: 'MS',
    stateProvinceRegion: 'Mississippi',
  },
  {
    id: 'fed6a330-5b8a-4b2a-a71e-b8bb349421c4',
    abbreviation: 'MO',
    stateProvinceRegion: 'Missouri',
  },
  {
    id: '6298b701-0485-4325-a4f0-09b43afbe097',
    abbreviation: 'MT',
    stateProvinceRegion: 'Montana',
  },
  {
    id: '16860a88-fe6e-446a-be31-24d8d255bc56',
    abbreviation: 'NE',
    stateProvinceRegion: 'Nebraska',
  },
  { id: '4b7b8c63-d010-43ad-b77c-9b066450c756', abbreviation: 'NV', stateProvinceRegion: 'Nevada' },
  {
    id: 'd69121b4-ec38-4847-a936-62f79665e3c0',
    abbreviation: 'NH',
    stateProvinceRegion: 'New Hampshire',
  },
  {
    id: '9448ffc5-3276-46b0-b690-727e11320c50',
    abbreviation: 'NJ',
    stateProvinceRegion: 'New Jersey',
  },
  {
    id: '2dc40718-566a-41e7-8cf7-35d09bdf511f',
    abbreviation: 'NM',
    stateProvinceRegion: 'New Mexico',
  },
  {
    id: '6267cc34-c663-45ea-886b-9f38fab870d4',
    abbreviation: 'NY',
    stateProvinceRegion: 'New York',
  },
  {
    id: 'c620ba4b-4c56-43d1-b7d3-a13d404d7e72',
    abbreviation: 'WA',
    stateProvinceRegion: 'Washington',
  },
  {
    id: '6dcd76cc-0dca-4d46-86d0-79f2f6a99f41',
    abbreviation: 'WI',
    stateProvinceRegion: 'Wisconsin',
  },
  {
    id: '28e36586-8491-46bf-94c8-3c633fae4876',
    abbreviation: 'CO',
    stateProvinceRegion: 'Colorado',
  },
  {
    id: '42e94b01-3d9f-4d9f-9f1e-569ca4a7a270',
    abbreviation: 'CT',
    stateProvinceRegion: 'Connecticut',
  },
  {
    id: '3b71df54-04f5-4552-91f1-9cd710ac7938',
    abbreviation: 'DE',
    stateProvinceRegion: 'Delaware',
  },
  { id: '821e9978-41d4-48da-909d-11d33eddb187', abbreviation: 'HI', stateProvinceRegion: 'Hawaii' },
  { id: '2c335e66-e05e-4538-863d-b6cc075cc462', abbreviation: 'ID', stateProvinceRegion: 'Idaho' },
  {
    id: 'c686f9ed-6152-4a8a-9487-a35cb4113a40',
    abbreviation: 'IL',
    stateProvinceRegion: 'Illinois',
  },
  {
    id: 'a608c9d9-6c9f-43f6-a79d-ce0c0c4e4cc0',
    abbreviation: 'IN',
    stateProvinceRegion: 'Indiana',
  },
  { id: 'e0ec3d45-52b3-4c02-b021-545164007efe', abbreviation: 'IA', stateProvinceRegion: 'Iowa' },
  { id: 'ae9c156b-a626-4527-b8d4-9d01a044a77a', abbreviation: 'KS', stateProvinceRegion: 'Kansas' },
  {
    id: 'f04d4905-20a8-4b25-a035-d607cead97b1',
    abbreviation: 'KY',
    stateProvinceRegion: 'Kentucky',
  },
  {
    id: 'f8e2514b-da9f-47ee-a8b3-de8f452a9e8c',
    abbreviation: 'AL',
    stateProvinceRegion: 'Alabama',
  },
  {
    id: 'f1db4e42-6a04-4f9c-aefb-17c70f481793',
    abbreviation: 'OK',
    stateProvinceRegion: 'Oklahoma',
  },
  {
    id: 'fc39ffbf-a4d5-4420-82a6-9a80f235171d',
    abbreviation: 'VT',
    stateProvinceRegion: 'Vermont',
  },
  {
    id: '054f7889-8ebe-42be-ae3c-09d88acaffc4',
    abbreviation: 'FL',
    stateProvinceRegion: 'Florida',
  },
  {
    id: 'f90c4613-8c58-49fd-b2ac-e1c521b25985',
    abbreviation: 'GA',
    stateProvinceRegion: 'Georgia',
  },
  { id: '23f83d35-dfe2-4e37-a7f4-668b3681abed', abbreviation: 'AK', stateProvinceRegion: 'Alaska' },
  {
    id: '8c538afb-73b2-428d-8c13-7dc09809f911',
    abbreviation: 'AR',
    stateProvinceRegion: 'Arkansas',
  },
  {
    id: '9f3cf515-105d-45de-ae1a-8c90bc2cb06c',
    abbreviation: 'WY',
    stateProvinceRegion: 'Wyoming',
  },
  {
    id: '072dccf7-678d-466f-b072-fc2876c6b79c',
    abbreviation: 'CA',
    stateProvinceRegion: 'California',
  },
  {
    id: '78aa7e5d-524b-4ced-ad32-cb2345b39312',
    abbreviation: 'NC',
    stateProvinceRegion: 'North Carolina',
  },
  {
    id: '944f9a02-5994-4489-9fbe-39132e14753c',
    abbreviation: 'TN',
    stateProvinceRegion: 'Tennessee',
  },
  {
    id: 'b3e5e76e-41e4-45dc-9b06-2df8147881c9',
    abbreviation: 'AZ',
    stateProvinceRegion: 'Arizona',
  },
  {
    id: '52d536aa-ab16-4a69-a4f6-9daa707444f6',
    abbreviation: 'AS',
    stateProvinceRegion: 'American Samoa',
  },
  { id: 'dd802cd4-8dd3-4e33-a00b-48ac8ae3fdb9', abbreviation: 'GU', stateProvinceRegion: 'Guam' },
  {
    id: 'c9565820-d936-4088-9316-ece70d1c894c',
    abbreviation: 'MP',
    stateProvinceRegion: 'Northern Mariana Islands',
  },
  {
    id: 'aca16623-eca1-4c5b-a8d6-0463bf61efbe',
    abbreviation: 'PR',
    stateProvinceRegion: 'Puerto Rico',
  },
  {
    id: '2a647b07-15df-43ee-9ab5-6e4c61868184',
    abbreviation: 'VI',
    stateProvinceRegion: 'U.S. Virgin Islands',
  },
].sort((a, b) => {
  return a.stateProvinceRegion.toUpperCase().localeCompare(b.stateProvinceRegion.toUpperCase())
})
