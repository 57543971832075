import http from '@/http'

const STATE = {
  isPurchaseCanaryWebsite: false,
  website: {},
  newTosUrl: 'https://www.corporatefilingsllc.com/terms-of-service',
}

const GETTERS = {
  website: state => state.website,
  isPurchaseCanaryWebsite: state => state.website.is_purchase_canary_test,
  newTosUrl: state => state.newTosUrl,
}

const ACTIONS = {
  async getWebsite({ commit }, { id }) {
    const response = await http.get(`client/websites/${id}`)

    commit('setWebsite', response.data.result)
    commit('setIsPurchaseCanaryWebsite', response.data.result?.is_purchase_canary_test || false)
  },
}

const MUTATIONS = {
  setWebsite(state, website) {
    state.website = website
  },
  setIsPurchaseCanaryWebsite(state, isCanaryWebsite) {
    state.isPurchaseCanaryWebsite= isCanaryWebsite
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
