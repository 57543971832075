import * as t from '../mutations'
import axiosClient from '@/http'
import http from '@/http'

const STATE = {
  namespace: 'requestDocument',
  selectedDocumentId: null,
  selectedDocumentCompanyId: null,
  documents: [],
  selectedMailingAddress: null,
  shippingType: 'USPS First Class',
  shippingDescription: 'Tracking Included',
  documentRequestsEnabledOnWebsite: false,
  documentImageUrl: null,
  uspsVerifiedAddress: null,
  companyDiscounts: [],
  serviceAndShipping: {
    serviceFee: [],
    serviceDiscount: 0,
    serviceTotal: 0,
    shippingFee: 0,
    shippingDiscount: 0,
    shippingTotal: 0,
    error: null,
  },
}

const GETTERS = {
  selectedDocumentId: state => state.selectedDocumentId,
  selectedDocumentCompanyId: state => state.selectedDocumentCompanyId,
  shippingType: state => state.shippingType,
  shippingDescription: state => state.shippingDescription,
  documentRequestsEnabledOnWebsite: state => state.documentRequestsEnabledOnWebsite,
  documents: state => state.documents,
  selectedMailingAddress: state => state.selectedMailingAddress,
  documentImageUrl: state => state.documentImageUrl,
  uspsVerifiedAddress: state => state.uspsVerifiedAddress,
  companyDiscounts: (_state, getters, _rootState, rootGetters) => {
    return rootGetters['discounts/companyDiscounts'](getters.documents[0].companyId)
  },
  serviceAndShipping: state => state.serviceAndShipping,
  singleDocumentAndIsPackageOrOversize: (state) => {
    return (state.documents.length === 1 && (state.documents[0].isPackage || state.documents[0].isOversized))
  },
  singleDocumentAndNoShippingQuote: (state) => {
    return state.documents.length === 1 && state.serviceAndShipping.shippingFee === 0
  },
  addUpServiceFee: (state) => {
    return state.serviceAndShipping.serviceFee.reduce((totalPrice, curr) => totalPrice + curr.price, 0)
  },
}

const ACTIONS = {
  async create({ commit }, { ids, toAddress, serviceAndShipping, selectedPayableId }) {
    try {
      const response = await http.post(`client/client_documents/request_document`,
        {
          ids: ids,
          to_address: toAddress,
          service_and_shipping: serviceAndShipping,
          token: selectedPayableId,
        }
      )
      commit(t.SET_SHIPPING_TYPE_BY_COUNTRY, toAddress['country'])
      return response.data
    } catch (error) {
      return error
    }
  },
  setSelectedDocumentId({ commit }, id) {
    commit(t.SET_SELECTED_DOCUMENT_ID, id)
  },
  setSelectedDocumentCompanyId({ commit }, companyId) {
    commit(t.SET_SELECTED_DOCUMENT_COMPANY_ID, companyId)
  },
  setServiceFee({ commit, state }) {
    const price = state.documents.map(document => ({ price: document.clientDocumentRequestFee.standardFee, doc_id: document.id }))

    commit(t.SET_SERVICE_FEE, price)
    commit(t.SET_SERVICE_TOTAL, price.reduce((sum, currentDoc) => sum + currentDoc.price, 0))
  },
  async calculateShipping({ commit, getters }, { ids, toAddress }) {
    try {
      const response = await axiosClient.get('client/client_documents/shipping_quote/', {
        params:{
          ids,
          zip_code: toAddress['zip_postal_code'],
          country: toAddress['country'],
          city: toAddress['city'],
        },
      })

      const shipping_quote = response.data.result.shipping_quote.response

      commit(t.SET_SHIPPING_COST, shipping_quote['quote'])
      commit(t.SET_SHIPPING_TOTAL, shipping_quote['quote'] - getters.serviceAndShipping.shippingDiscount)
      commit(t.SET_SHIPPING_TYPE, shipping_quote['name'])
      commit(t.SET_SHIPPING_ERROR, shipping_quote['error_message'])

      return shipping_quote
    } catch {
      commit(t.SET_SHIPPING_COST, 0)
      commit(t.SET_SHIPPING_TOTAL, 0)
      return null
    }
  },
  async setDocumentRequestsEnabledOnWebsite({ commit }) {
    const response =  await axiosClient.get('client/client_documents/website_enabled')
    commit(t.SET_DOCUMENT_REQUESTS_ENABLED_ON_WEBSITE, response.data.result.response)
  },
  setDocuments({ commit }, docs){
    commit(t.SET_DOCUMENTS, docs)
  },
  setSelectedMailingAddress({ commit }, address){
    commit(t.SET_SELECTED_MAILING_ADDRESS, address)
  },
  setDocumentImageUrl({ commit }, url){
    commit(t.SET_DOCUMENT_IMAGE_URL, url)
  },
  setUspsVerifiedAddress({ commit }, address){
    commit(t.SET_USPS_VERIFIED_ADDRESS, address)
  },
  setCompanyDiscounts({ commit, getters }) {
    commit(t.SET_COMPANY_DISCOUNTS, getters.companyDiscounts)
  },
  async verifyAddress({ commit }, { id, toAddress }){
    try{
      const response =  await axiosClient.get('client/client_documents/validate_address/', {
        params:{
          id,
          to_address: toAddress,
        },
      })

      if(response.data.result.response.success != false){
        commit(t.SET_USPS_VERIFIED_ADDRESS, response.data.result.response.response.usps_address)
      }
      else{
        commit(t.SET_USPS_VERIFIED_ADDRESS, null)
      }
    } catch (error) {
      return error
    }
  },
  async viewDocumentRequest({ commit }, id){
    try{
      const response =  await axiosClient.get('client/client_documents/view_document_request/', {
        params:{
          id,
        },
      })
      const result = response.data.result
      const request = result.client_document_request

      const doc = result.document
      doc.companyName = doc.company_name

      const to_address = request.to_address
      to_address.contact_name = to_address.name

      commit(t.SET_DOCUMENTS, [doc])
      commit(t.SET_SELECTED_MAILING_ADDRESS, to_address)
      commit(t.SET_SHIPPING_TYPE_BY_COUNTRY, to_address.country)

      return response.data
    } catch (error) {
      return error
    }
  },
  async setServiceDiscount({ commit, getters }) {
    if(getters.documents.length === 1){
      try {
        const response = await axiosClient.get('client/client_documents/document_request_discount/', {
          params:{
            account_id: '',
            discounts: getters.companyDiscounts,
            fee_amount: getters.serviceAndShipping.serviceFee[0].price,
            kind: 'click-to-request_' + getters.documents[0].type.toLowerCase().split(' ').join('_'),
          },
        })

        const discountAmount = response.data.result.document_request_discount.success ? response.data.result.document_request_discount : 0
        commit(t.SET_SERVICE_DISCOUNT, discountAmount)
        commit(t.SET_SERVICE_TOTAL, getters.serviceAndShipping.serviceFee[0].price - discountAmount)
      } catch (error) {
        return error
      }
    }
  },
  async setShippingDiscount({ commit, getters }) {
     if (getters.documents.length === 1) {
       try {
         const response = await axiosClient.get('client/client_documents/shipping_discount/', {
           params:{
             account_id: '',
             discounts: getters.companyDiscounts,
             fee_amount: getters.serviceAndShipping.shippingFee,
             kind: 'click-to-request_shipping_and_postage',
           },
         })
         const discountAmount = response.data.result.shipping_discount.success ? response.data.result.shipping_discount : 0
         commit(t.SET_SHIPPING_DISCOUNT, discountAmount)
         commit(t.SET_SHIPPING_TOTAL, getters.serviceAndShipping.shippingFee - discountAmount)
       } catch (error) {
         return error
       }
     }
  },
}

const MUTATIONS = {
  [t.SET_SELECTED_DOCUMENT_ID](state, id) {
    state.selectedDocumentId = id
  },
  [t.SET_SELECTED_DOCUMENT_COMPANY_ID](state, companyId) {
    state.selectedDocumentCompanyId = companyId
  },
  [t.SET_SHIPPING_COST](state, cost){
    state.serviceAndShipping.shippingFee = cost
  },
  [t.SET_SHIPPING_TYPE](state, type){
      state.shippingType = type
  },
  [t.SET_SHIPPING_TYPE_BY_COUNTRY](state, country) {
    let normalizedCountry = country.toUpperCase()
    normalizedCountry = normalizedCountry.replace(/\./g, '')

    const validUSValue = ['US', 'UNITED STATES', 'USA']
    let type = validUSValue.includes(normalizedCountry) ? 'USPS First Class' : 'UPS Worldwide Saver'

    state.shippingType = type
  },
  [t.SET_SERVICE_FEE](state, price){
    state.serviceAndShipping.serviceFee = price
  },
  [t.SET_DOCUMENT_REQUESTS_ENABLED_ON_WEBSITE](state,result){
    state.documentRequestsEnabledOnWebsite = result
  },
  [t.SET_DOCUMENTS](state, docs){
    state.documents = docs
  },
  [t.SET_SELECTED_MAILING_ADDRESS](state,address){
    state.selectedMailingAddress = address
  },
  [t.SET_DOCUMENT_IMAGE_URL](state, url){
    state.documentImageUrl = url
  },
  [t.SET_USPS_VERIFIED_ADDRESS](state,address){
    state.uspsVerifiedAddress = address
  },
  [t.SET_COMPANY_DISCOUNTS](state, discounts){
    state.companyDiscounts = discounts
  },
  [t.SET_SERVICE_DISCOUNT](state, amount){
    state.serviceAndShipping.serviceDiscount = amount
  },
  [t.SET_SHIPPING_DISCOUNT](state, amount){
    state.serviceAndShipping.shippingDiscount = amount
  },
  [t.SET_SERVICE_TOTAL](state, amount){
    state.serviceAndShipping.serviceTotal = amount
  },
  [t.SET_SHIPPING_TOTAL](state, amount){
    state.serviceAndShipping.shippingTotal = amount
  },
  [t.SET_SHIPPING_ERROR](state, error){
    state.serviceAndShipping.error = error
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
