import * as t from '../mutations'
import http from '@/http'

const STATE = {
  domainSuggestions: [],
}

const GETTERS = {
  domainSuggestions: state => state.domainSuggestions,
}

const ACTIONS = {
  async fetchDomainSuggestions({ commit }, companyName) {
    try {
      const res = await http.post('client/domain_ai/suggest_domains', {
        company_name: companyName,
      })
      commit(t.SET_DOMAIN_SUGGESTIONS, res.data.response)
    } catch (error) {
      console.error(error)
    }
  },
  async clearSuggestions({ commit }) {
    commit(t.SET_DOMAIN_SUGGESTIONS, [])
  },
}

const MUTATIONS = {
  [t.SET_DOMAIN_SUGGESTIONS](state, suggestions) {
    state.domainSuggestions = suggestions
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}

