const LiveHelpNowBuilder = function() {
  this.init = function(params) {
    if (! this.lhnJsSdk) {
      this.outputLogs = false
      this.hasStarted = false
      this.registerEventHandlers()
      this.create(params || {})
      this.waitForSdk()

      this.startAutoRefreshHack()  // gross...
    }
  }

  this.create = function(params) {
    params = params || {}
    window.lhnJsSdkInit = function () {
      let lhnJsSdk = window.lhnJsSdk
      lhnJsSdk.setup = {
        application_id: "8d374ac2-1c6c-458a-9bb1-574e29d0505c",
        application_secret: "vk4ddirrffor6jxjlhtsg7xlkc/xsnn8lh58ltwyqapvsnnprv",
      }

      lhnJsSdk.controls = [{
        type: "hoc",
        id: "5b18a4c6-02c3-44b2-9258-07e5e6d9215f",
      }]

      if (params.dictionary) { lhnJsSdk.dictionary = params.dictionary }
      if (params.options) { lhnJsSdk.options = params.options }
      if (params.fieldData) { lhnJsSdk.fieldData = params.fieldData }
    };

    (function (d, s) {
      var newjs, lhnjs = d.getElementsByTagName(s)[0]
      newjs = d.createElement(s)
      newjs.src = "https://developer.livehelpnow.net/js/sdk/lhn-jssdk-current.min.js"
      lhnjs.parentNode.insertBefore(newjs, lhnjs)
    }(document, "script"))
  }

  // waits for window.lhnJsSdk to be loaded, then call sdkReady()
  // NOTE: the docs do say that lhnJsSdkReady() will be called when the sdk is ready, but that doesn't seem to be the case...
  // when I tried it, window.lhnJsSdk was still undefined when lhnJsSdkReady() was called.
  this.waitForSdk = function() {
    if (! window.lhnJsSdk) {
      this.log('waiting for sdk')
      setTimeout(() => { this.waitForSdk() }, 100)
    }
    else {
      this.log('sdk loaded', window.lhnJsSdk)
      this.lhnJsSdk = window.lhnJsSdk
      this.sdkReady()
    }
  }

  // called when sdk is done loading and ready
  this.sdkReady = function() {
    this.log('sdkReady')
    this.start()
  }

  // Called once everything has had a few seconds to make sure it's ready
  this.start = function() {
    this.hasStarted = true
    this.watchForLhnDom()
  }

  // Called when the page is loaded or the route changes
  this.handlePageLoad = function() {
    this.log('page load')
    this.refresh()
  }

  // determine if the lhnHocButton is available
  this.lhnDomIsReady = function() {
    this.lhnHocButton = document.getElementById('lhnHocButton')
    return !! this.lhnHocButton
  }

  // wait for the lhnHocButton to be available, then call onLhnDomReady()
  this.watchForLhnDom = function() {
    if (! this.lhnDomIsReady()) {
      setTimeout(() => { this.watchForLhnDom() }, 100)
    }
    else {
      this.onLhnDomReady()
    }
  }

  // called once lhnHocButton is found
  this.onLhnDomReady = function() {
    this.log('onLhnDomReady')
    this.refresh()
  }

  // refresh all custom styling
  this.refresh = function() {
    if (! this.lhnDomIsReady()) { return }

    // Only apply styling if the lhnHocButton is available
    let lhnHocButton = this.lhnHocButton
    if (lhnHocButton) {
      // If the screen is too small, reduce the zoom of the lhnHocButton
      let zoomAmount = window.innerWidth < 768 ? 0.7 : 1
      lhnHocButton.style.zoom = zoomAmount

      // Grab one of the nav items to get the height of the bottom nav
      let navItem = document.querySelector('.nav.fixed-bottom .nav-item')
      if (navItem) {
        let bottomNavHeight = navItem.offsetHeight

        // If the bottomNavHeight is 0, it isn't ready yet, so try again in 1 second
        if (bottomNavHeight == 0) {
          setTimeout(() => { this.refresh() }, 100)
          return
        }

        // Adjust the bottomNavHeight to account for the zoom
        bottomNavHeight *= 1 / zoomAmount

        lhnHocButton.style.marginBottom = (bottomNavHeight + 5) + 'px'
      }
      else {
        lhnHocButton.style.marginBottom = '0px'
      }
    }
  }

  // All attempts so far to tie into page changes by monitoring Vue router or window.location have failed,
  // so I'm resorting to brute force refreshing the styles every so often instead...
  this.startAutoRefreshHack = function() {
    this.refresh()
    setTimeout(() => { this.startAutoRefreshHack() }, 1000)
  }


  //-------- EVENT HANDLERS -----------------------------


  this.registerEventHandlers = function() {
    var that = this

    window.lhnJsSdkEmbeddedOpened = function(...args) { that.sdkEmbeddedOpened(...args) } // This function will be called when the embedded window is opened
    window.lhnJsSdkEmbeddedClosed = function(...args) { that.sdkEmbeddedClosed(...args) } // This function will be called when the embedded window is closed
    window.lhnStatusUpdate = function(...args) { that.statusUpdate(...args) } // This function will be called when the online/offline status of the department or account changes
    window.lhnJsSdkInviteReceived = function(...args) { that.sdkInviteReceived(...args) } // This function will be called when a new chat invitation is received.
    window.lhnJsSdkInviteAccepted = function(...args) { that.sdkInviteAccepted(...args) } // This function will be called when a chat invitation is accepted.
    window.lhnJsSdkInviteRejected = function(...args) { that.sdkInviteRejected(...args) } // This function will be called when a chat invitation is rejected.
    window.lhnJsSdkSmsChatShown = function(...args) { that.sdkSmsChatShown(...args) } // This function will be called when the sms chat is launched from the chat window.
    window.lhnJsSdkPreChatShown = function(...args) { that.sdkPreChatShown(...args) } // This function will be called when the prechat form is shown.
    window.lhnJsSdkPreChatSubmitted = function(...args) { that.sdkPreChatSubmitted(...args) } // This function will be called when the prechat form is submitted.
    window.lhnJsSdkChatStarted = function(...args) { that.sdkChatStarted(...args) } // This function will be called when the chat has begun.
    window.lhnJsSdkChatNewMessage = function(...args) { that.sdkChatNewMessage(...args) } // This function will be called when a new chat message is received.
    window.lhnJsSdkChatTimeout = function(...args) { that.sdkChatTimeout(...args) } // This function will be called when a chat timeout option is triggered.
    window.lhnJsSdkChatEnded = function(...args) { that.sdkChatEnded(...args) } // This function will be called when chat has been ended
    window.lhnJsSdkOfflineShown = function(...args) { that.sdkOfflineShown(...args) } // This function will be called when the offline form is shown.
    window.lhnJsSdkOfflineSubmitted = function(...args) { that.sdkOfflineSubmitted(...args) } // This function will be called when the offline form is submitted.
    window.lhnJsSdkSurveyShown = function(...args) { that.sdkSurveyShown(...args) } // This function will be called when a survey has been shown
    window.lhnJsSdkSurveySubmitted = function(...args) { that.sdkSurveySubmitted(...args) } // This function will be called when a survey has been submitted
    window.lhnPromo = function(...args) { that.promo(...args) } // This function will be called when a promo is received
    window.lhnJsSdkKbSearch = function(...args) { that.sdkKbSearch(...args) } // This function will be called when a kb search is occuring
    window.lhnJsSdkTicketShown = function(...args) { that.sdkTicketShown(...args) } // This function will be called when a ticket form is shown
    window.lhnJsSdkTicketSubmitted = function(...args) { that.sdkTicketSubmitted(...args) } // This function will be called when a ticket form is submitted
    window.lhnJsSdkCallbackShown = function(...args) { that.sdkCallbackShown(...args) } // This function will be called when a callback form is shown
    window.lhnJsSdkCallbackSubmitted = function(...args) { that.sdkCallbackSubmitted(...args) } // This function will be called when a callback form is submitted
    window.lhnConversion = function(...args) { that.conversion(...args) } // This function will be called when a conversion is triggered

    /* Only for Help Bot Chats */
    window.lhnJsSdkBotInputReceived = function(question, answer) { that.sdkBotInputReceived(question, answer) } // This function will be called when a Help Bot question is answered

    window.addEventListener('resize', () => { this.refresh() })

    // Can't get these to work...
    // window.addEventListener('hashchange', () => { this.handlePageLoad() })
    // window.addEventListener('popstate', () => { this.handlePageLoad() })
  }

  this.sdkEmbeddedOpened = function() { this.log('sdkEmbeddedOpened') }

  this.sdkEmbeddedClosed = function() { this.log('sdkEmbeddedClosed') }

  this.statusUpdate = function() { this.log('statusUpdate', this.lhnJsSdk.isOnline) }

  this.sdkInviteReceived = function(message, delay, type) { this.log('sdkInviteReceived', message, delay, type) }

  this.sdkInviteAccepted = function() { this.log('sdkInviteAccepted') }

  this.sdkInviteRejected = function() { this.log('sdkInviteRejected') }

  this.sdkSmsChatShown = function() { this.log('sdkSmsChatShown') }

  this.sdkPreChatShown = function() { this.log('sdkPreChatShown') }

  this.sdkPreChatSubmitted = function() { this.log('sdkPreChatSubmitted') }

  this.sdkChatStarted = function() { this.log('sdkChatStarted') }

  this.sdkChatNewMessage = function(message_id, message, message_type) { this.log('sdkChatNewMessage', message_id, message, message_type) }

  this.sdkChatTimeout = function(message) { this.log('sdkChatTimeout', message) }

  this.sdkChatEnded = function(chat_session_id) { this.log('sdkChatEnded', chat_session_id) }

  this.sdkOfflineShown = function() { this.log('sdkOfflineShown') }

  this.sdkOfflineSubmitted = function() { this.log('sdkOfflineSubmitted') }

  this.sdkSurveyShown = function() { this.log('sdkSurveyShown') }

  this.sdkSurveySubmitted = function() { this.log('sdkSurveySubmitted') }

  this.promo = function(message) { this.log('promo', message) }

  this.sdkKbSearch = function(search_term) { this.log('sdkKbSearch', search_term) }

  this.sdkTicketShown = function() { this.log('sdkTicketShown') }

  this.sdkTicketSubmitted = function() { this.log('sdkTicketSubmitted') }

  this.sdkCallbackShown = function() { this.log('sdkCallbackShown') }

  this.sdkCallbackSubmitted = function() { this.log('sdkCallbackSubmitted') }

  this.conversion = function(conversion_id, conversion_value) { this.log('conversion', conversion_id, conversion_value) }

  this.sdkBotInputReceived = function(question, answer) { this.log('sdkBotInputReceived', question, answer) }

  this.log = function(...args) {
    if(this.outputLogs) { console.log(...args) }
  }
}

export default LiveHelpNowBuilder