import * as t from '../mutations'
import http from '@/http'
import { monitoringApiClient } from '@/api/client'
import store from '@/store'

const STATE = {
  monitoringTrialEligible: false,
  monitoringService: null,
  monitoringServiceActive: false,
  monitoringEmailAddress: "",
  monitoringCandidates: [],
  monitoringReports: [],
  monitoringExclusions: [],
  monitoringIdentities: [],
  monitoringNotificationsSchedules: [],
  monitoringIdentitiesByType: {},
  monitoringIdentityMaxAllowed: 0,
}

const GETTERS = {
  monitoringTrialEligible: state => state.monitoringTrialEligible,
  monitoringService: state => state.monitoringService,
  monitoringServiceActive: state => !!state.monitoringService,
  monitoringEmailAddress: state => state.monitoringEmailAddress,
  monitoringReports: state => state.monitoringReports,
  monitoringNotificationsSchedules: state => state.monitoringNotificationsSchedules,
  monitoringCandidates: state => state.monitoringCandidates,
  monitoringExclusions: state => state.monitoringExclusions,
  monitoringIdentities: state => state.monitoringIdentities,
  monitoringIdentitiesByType: state => state.monitoringIdentitiesByType,
  monitoringIdentityMaxAllowed: state => state.monitoringIdentityMaxAllowed,
}

const ACTIONS = {
  async determineTrialEligibilityMonitoring({ commit }, company_id) {
    const result = await http.get(`/client/trial_eligibility/${company_id}/monitoring`)
    const monitoring_eligible = result.data.response.is_eligible

    commit(t.SET_MONITORING_TRIAL_ELIGIBILITY, monitoring_eligible)
  },
  async fetchMonitoringService({ commit }, ownerID) {
    const result = await http.get('client/services/active_monitoring_service', {
      params: {
        owner_id: ownerID,
      },
    })
    const service = result.data.result.service
    commit(t.SET_MONITORING_SERVICE, service)
    commit(t.SET_MONITORING_SERVICE_ACTIVE, !!service)
  },
  async fetchMonitoringEmailAddress({ commit }) {
    const response = await http.get(`client/monitoring_service/monitoring_email_address`)
    commit(t.SET_MONITORING_EMAIL_ADDRESS, response.data.result.email_address)
  },
  async fetchMonitoringCandidates({ commit }) {
    const response = await http.get(`client/monitoring_service/monitoring_candidates`)
    const monitoring_candidates = response.data.result.records
    commit(t.SET_MONITORING_CANDIDATES, monitoring_candidates)
  },
  async updateEmailAddress(_, address) {
    await http.post(`client/monitoring_service/update_email`, { email_address: address })
  },
  setTrialEligibility({ commit }, eligible) {
    commit(t.SET_MONITORING_TRIAL_ELIGIBILITY, eligible)
  },
  async storeSessionToken(_) {
    await http.post('client/monitoring_service/store_session', {
      token: store.getters['session/getToken'],
    })
  },
  async fetchMonitoringIdentityMaxAllowed({ commit }, accountID) {
    const response = await monitoringApiClient.get(`api/v1/monitoring-identity/max/${accountID}`)
    commit(t.SET_MONITORING_IDENTITY_MAX, response.data.max)
  },
  async updateMonitoringExclusions(_, exclusionRecord) {
    await monitoringApiClient.post(`api/v1/monitoring-exclusions/${exclusionRecord.accountID}`, {
      id: exclusionRecord.id,
      account_id: exclusionRecord.account_id,
      exclusions: exclusionRecord.exclusions,
      identity_type: exclusionRecord.identity_type,
    })
  },
  async removeMonitoringExclusion(_, exclusionData) {
    await monitoringApiClient.delete(`/api/v1/monitoring-exclusions/${exclusionData.exclusionID}/${exclusionData.accountID}`)
  },
  async fetchMonitoringExclusions({ commit }, accountID) {
    try {
      const result = await monitoringApiClient.get(`api/v1/monitoring-exclusions/${accountID}`)
      commit(t.SET_MONITORING_EXCLUSIONS, result.data)
    } catch {
      commit(t.SET_MONITORING_EXCLUSIONS, [])
    }
  },
  async updateMonitoringIdentity(_, monitoringIdentity) {
    try {
      await monitoringApiClient.post('api/v1/monitoring-identity', {
        id: monitoringIdentity.id,
        account_id: monitoringIdentity.account_id,
        type: monitoringIdentity.type,
        value: monitoringIdentity.value,
        active: monitoringIdentity.active,
        global_id: monitoringIdentity.global_id || '',
        meta: monitoringIdentity.meta || {},
      })
    } catch (error) {
      if (error.response) {
        throw error.response.data
      } else {
        throw new Error('Network Error')
      }
    }
  },
  async removeMonitoringIdentity({ _ }, removeMonitoringIdentityData ) {
    await monitoringApiClient.delete(`/api/v1/monitoring-identity/${removeMonitoringIdentityData.monitoringIdentityID}/${removeMonitoringIdentityData.accountID}`)
  },
  async fetchMonitoringIdentities({ commit }, accountID) {
    try {
      const result = await monitoringApiClient.get(`api/v1/monitoring-identity/${accountID}`)
      commit(t.SET_MONITORING_IDENTITIES, result.data)
    } catch {
      commit(t.SET_MONITORING_IDENTITIES, [])
    }
  },
  async fetchMonitoringReports({ commit }, accountID) {
    try {
      const response = await monitoringApiClient.get(`api/v1/monitoring-reports/${accountID}`)
      commit(t.SET_MONITORING_REPORTS, response.data.data)
    } catch {
      commit(t.SET_MONITORING_REPORTS, [])
    }
  },
  async fetchMonitoringIdentitiesByType({ commit }, monitoringIdentityData) {
    try {
      const result = await monitoringApiClient.get(`api/v1/monitoring-identity/${monitoringIdentityData.accountID}/${monitoringIdentityData.identityType}`)

      const identityData = {
        type: monitoringIdentityData.identityType,
        identities: result.data,
      }
      commit(t.SET_MONITORING_IDENTITIES_BY_TYPE, identityData)    } catch {
      commit(t.SET_MONITORING_IDENTITIES_BY_TYPE, {}, monitoringIdentityData.identityType)
    }
  },
  async fetchMonitoringNotifcationsSchedule({ commit }, accountID) {
    try {
      const result = await monitoringApiClient.get(`api/v1/monitoring-reports/schedule/${accountID}`)

      commit(t.SET_MONITORING_NOTIFICATION_SCHEDULES, result.data)
    } catch {
      commit(t.SET_MONITORING_NOTIFICATION_SCHEDULES, [])
    }
  },
  async formatIdentities({ _ }, identities) {
    return identities?.map(i => i = { ...i, value: i.value, isEditMode: false})
  },
}

const MUTATIONS = {
  [t.SET_MONITORING_TRIAL_ELIGIBILITY](state, eligible) {
    state.monitoringTrialEligible = eligible
  },
  [t.SET_MONITORING_SERVICE](state, service) {
    state.monitoringService = service
  },
  [t.SET_MONITORING_SERVICE_ACTIVE](state, active) {
    state.monitoringServiceActive = active
  },
  [t.SET_MONITORING_EMAIL_ADDRESS](state, address) {
    state.monitoringEmailAddress = address
  },
  [t.SET_MONITORING_CANDIDATES](state, records) {
    // If records are not set, non were found. Return early to avoid undefined errors
    if (records === undefined) {
      return
    }
    state.monitoringCandidates = records
  },
  [t.SET_MONITORING_EXCLUSIONS](state, exclusions) {
    state.monitoringExclusions = exclusions
  },
  [t.SET_MONITORING_IDENTITIES](state, identities) {
    state.monitoringIdentities = identities
  },
  [t.SET_MONITORING_IDENTITIES_BY_TYPE](state, identityData) {
    state.monitoringIdentitiesByType[identityData.type] = identityData.identities
  },
  [t.SET_MONITORING_IDENTITY_MAX](state, max) {
    state.monitoringIdentityMaxAllowed = max
  },
  [t.SET_MONITORING_REPORTS](state, reports) {
    state.monitoringReports = reports
  },
  [t.SET_MONITORING_NOTIFICATION_SCHEDULES](state, schedule) {
    state.monitoringNotificationsSchedules = schedule
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
