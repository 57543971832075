export const textToWords = (input, ignored = [' ', '']) => {
  return String(input)
    .toLocaleLowerCase()
    .split(/\s+/g)
    .filter(aWord => aWord.length > 0 && ignored.indexOf(aWord) == -1)
    .map(aWord => aWord.trim())
}

export const inputContainsKeyword = (input, keyword) => {
  const theInput = textToWords(input)
  const keywords = textToWords(keyword)
  const garbage = theInput.length === 0

  if (garbage) {
    return false
  } else {
    return theInput.filter(aWord => keywords.includes(aWord)).length > 0
  }
}

export const humanize = input => {
  input = input
    .split(/[-_ ]/g)
    .map(word => {
      return ['a','an','the','and','of','for'].includes(word) ? word : `${word[0].toLocaleUpperCase()}${word.substr(1)}`
    })
    .join(' ')
  input = input[0].toLocaleUpperCase() + input.substr(1)
  return input
}

export const spaceless = input => input.replace(' ', '')

export const camelize = input => input.replace(/(_\w)/g, m => m[1].toLocaleUpperCase())

export const snakeCase = function(input) {
  var upperChars = input.match(/([A-Z])/g)
  if (!upperChars) {
    return input
  }

  var str = input.toString()
  for (var i = 0, n = upperChars.length; i < n; i++) {
    str = str.replace(new RegExp(upperChars[i]), '_' + upperChars[i].toLowerCase())
  }

  if (str.slice(0, 1) === '_') {
    str = str.slice(1)
  }

  return str
}

export const simplifiedNumber = input => {
  let result = input
  let suffix = ''
  let nf = Intl.NumberFormat()

  if (input >= 100000000) {
    suffix = 'M'
    result = nf.format(result).split(',')[0]
  } else if (input >= 100000) {
    suffix = 'K'
    result = nf.format(result).split(',')[0]
  } else {
    result = nf.format(result)
  }

  return `${result}${suffix}`
}

export const pad = (input, str = ' ', length = 4) => {
  const result = []
  const chars = String(input).split('')
  let i = 0
  for (let index = 0; index < chars.length; index++) {
    const element = chars[index]
    if (!result[i]) {
      result.push(element)
    } else if (result[i].length < length) {
      result[i] = `${result[i]}${element}`
    } else {
      result.push(element)
      i++
    }
  }
  return result.join(str)
}

export const separate = (input, separator = '/', length = 2) => {
  if (input.length <= length) {
    return input
  } else {
    const result = []
    result.push(input.substring(0, length))
    result.push(input.substring(length, length + length))
    return result.join(separator)
  }
}

export const last4 = input => {
  const numbers = String(input).split('')
  return numbers.slice(numbers.length - 4, numbers.length).join('')
}

export const mapping = function(hashMap, convertKeysToSymbols = false) {
  let result = this
  let hm = {}
  if (convertKeysToSymbols) {
    Object.keys(hashMap).forEach(key => {
      hm[`:${key}`] = hashMap[key]
    })
  } else {
    hm = { ...hashMap }
  }
  for (const key in hm) {
    result = result.replace(new RegExp(String(key), 'g'), hm[key])
  }
  return result
}

String.prototype.mapping = mapping

export const isNotEmptyOrNull = input => {
  const isNotNull = input != null
  const isNotAnEmptyString = input != ''
  const hasLength = String(input).length != 0
  return isNotNull && isNotAnEmptyString && hasLength
}
