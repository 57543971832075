import * as t from '../mutations'
import http from '@/http'
import { Client, GET } from '@/api/client'
import { ACCOUNT_DASHPANEL } from '@/api/v3/endpoints'
import _ from 'lodash'

const STATE = {
  unreadDocuments: 0,
  unpaidInvoices: 0,
  servicesRequiringAttentionCount: 0,
  orderItemsRequiringAttentionCount: 0,
  pendingVehicleOrders: 0,
  companiesRequiringCtaIds: [],
  pendingFilings: 0,
  incompleteStagelineSteps: 0,
  upcomingComplianceEvents: 0,
  isMontanaAccount: false,
  isSubscriptionAccount: false,
  isEntityTypeDomains: false,
  activeLawOnCallServices: [],
  activeDomains: [],
  orderItemsRequiringVerification: [],
  lawOnCallOffered: false,
  discounts: [],
  clickToRequest: {
    serviceDiscount: 0,
    shippingDiscount: 0,
  },
  loaded: false,
}

const GETTERS = {
  discounts: state => state.discounts,
  companyDiscounts: (state, getters) => company_id => getters.discounts.filter(discount => discount.company_id === company_id),
  // following getters are cached
  unreadDocuments: state => state.unreadDocuments,
  unpaidInvoices: state => state.unpaidInvoices,
  servicesRequiringAttentionCount: state => state.servicesRequiringAttentionCount,
  orderItemsRequiringAttentionCount: state => state.orderItemsRequiringAttentionCount,
  companiesRequiringCtaIds: state => state.companiesRequiringCtaIds,
  pendingFilings: state => state.pendingFilings,
  pendingVehicleOrders: state => state.pendingVehicleOrders,
  incompleteStagelineSteps: state => state.incompleteStagelineSteps,
  upcomingComplianceEvents: state => state.upcomingComplianceEvents,
  dashpanelItemCount: state => {
    return state.unreadDocuments +
      state.unpaidInvoices +
      state.servicesRequiringAttentionCount +
      state.orderItemsRequiringAttentionCount +
      state.pendingFilings +
      state.pendingVehicleOrders +
      state.incompleteStagelineSteps
  },
  isMontana: state => state.isMontana,
  isSubscriptionAccount: state => state.isSubscriptionAccount,
  isEntityTypeDomains: state => state.isEntityTypeDomains,
  activeLawOnCallServices: state => _.uniqBy(state.activeLawOnCallServices, 'stateProvinceRegion'),
  activeDomains: state => state.activeDomains,
  lawOnCallOffered: state => state.lawOnCallOffered,
}

const ACTIONS = {
  async loadDiscounts({ commit }) {
    const response = await http.get('client/discounts')
    commit(t.SET_DISCOUNTS, response.data.result)
  },
  async refresh({ commit, rootGetters }) {
    const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
    try {

      const response = await client.call({
        method: GET,
        path: ACCOUNT_DASHPANEL,
        skipAuthorization: false,
        params: null,
      })

      const summary = response.data.summary
      commit(t.SET_ACTIVE_LAW_ON_CALL_SERVICES, summary.activeLawOnCallServices)
      commit(t.SET_ACTIVE_DOMAINS, summary.activeDomains)
      commit(t.SET_LAW_ON_CALL_OFFERED, summary.lawOnCallOffered)
      commit(t.SET_IS_SUBSCRIPTION_ACCOUNT, summary.isSubscriptionAccount)
      commit(t.SET_IS_MONTANA, summary.isMontanaAccount)
      commit(t.SET_PENDING_VEHICLE_ORDERS, summary.pendingVehicleOrders)
      commit(t.SET_PENDING_FILINGS, summary.pendingFilings)
      commit(t.SET_INCOMPLETE_STAGELINE_STEPS, summary.incompleteStagelineSteps)
      commit(t.SET_ORDER_ITEMS_REQUIRING_ATTENTION_COUNT, summary.orderItemsRequiringAttention)
      commit(t.SET_SERVICES_REQUIRING_ATTENTION_COUNT, summary.servicesRequiringAttention)
      commit(t.SET_COMPANIES_REQUIRING_CTA_IDS, summary.companiesRequiringCtaIds)
      commit(t.SET_UNREAD_DOCUMENTS, summary.unreadDocuments)
      commit(t.SET_UNPAID_INVOICES, summary.unpaidInvoices)
      return response
    } catch (error) {
      return error
    }
  },
  async refreshUnreadDocCount({ commit }) {
    const response = await http.get('client/account/unread_documents_count')
    commit(t.SET_UNREAD_DOCUMENTS, response.data.result.unread_documents_count.unread_documents)
  },
  async refreshCompaniesRequiringCtaIds({ commit }) {
    const response = await http.get('client/account/companies_requiring_cta')
    commit(t.SET_COMPANIES_REQUIRING_CTA_IDS, response.data.result.companies_requiring_cta.companies_requiring_cta_ids)
  },
  addDomain({ getters, commit }, domain) {
    const domains = [...getters.activeDomains, domain]
    commit(t.SET_ACTIVE_DOMAINS, domains)
  },
  suspendDomain({ getters, commit }, domain) {
    const domains = getters.activeDomains.filter(d => d.id === domain.id)
    commit(t.SET_ACTIVE_DOMAINS, domains)
  },
  incrementOraCount({ commit }, count) {
    commit(t.INCREMENT_ORDER_ITEMS_REQUIRING_ATTENTION_COUNT, count)
  },
  async setIsEntityTypeDomains({ commit }) {
    try {
      const response = await http.get('client/companies', { params: { limit: 1000 } })
      const companies = response.data.result
      const isEntityDomains = companies.every(company => company?.entity_type === 'Domains')
      commit(t.SET_IS_ENTITY_TYPE_DOMAINS, isEntityDomains)
    } catch (error) {
      commit(t.SET_IS_ENTITY_TYPE_DOMAINS, error)
    }
  },
}

const MUTATIONS = {
  [t.SET_DISCOUNTS](state, discounts) {
    state.discounts = discounts
  },
  [t.SET_UNREAD_DOCUMENTS](state, unreadDocuments) {
    state.unreadDocuments = unreadDocuments
  },
  [t.SET_UNPAID_INVOICES](state, unpaidInvoices) {
    state.unpaidInvoices = unpaidInvoices
  },
  [t.SET_SERVICES_REQUIRING_ATTENTION_COUNT](state, count) {
    state.servicesRequiringAttentionCount = count
  },
  [t.SET_PENDING_VEHICLE_ORDERS](state, count) {
    state.pendingVehicleOrders = count
  },
  [t.SET_ORDER_ITEMS_REQUIRING_ATTENTION_COUNT](state, count) {
    state.orderItemsRequiringAttentionCount = count
  },
  [t.INCREMENT_ORDER_ITEMS_REQUIRING_ATTENTION_COUNT](state, count) {
    state.orderItemsRequiringAttentionCount = ((state.orderItemsRequiringAttentionCount || 0) + count)
  },
  [t.SET_COMPANIES_REQUIRING_CTA_IDS](state, count) {
    state.companiesRequiringCtaIds = count
  },
  [t.SET_PENDING_FILINGS](state, pendingFilings) {
    state.pendingFilings = pendingFilings
  },
  [t.SET_INCOMPLETE_STAGELINE_STEPS](state, incompleteStagelineSteps){
    state.incompleteStagelineSteps = incompleteStagelineSteps
  },
  [t.SET_UPCOMING_COMPLIANCE_EVENTS](state, upcomingComplianceEvents) {
    state.upcomingComplianceEvents = upcomingComplianceEvents
  },
  [t.SET_IS_MONTANA](state, isMontana) {
    state.isMontana = isMontana
  },
  [t.SET_IS_ENTITY_TYPE_DOMAINS](state, isEntityTypeDomains) {
    state.isEntityTypeDomains = isEntityTypeDomains
  },
  [t.SET_IS_SUBSCRIPTION_ACCOUNT](state, isSubscriptionAccount) {
    state.isSubscriptionAccount = isSubscriptionAccount
  },
  [t.SET_IS_ENTITY_TYPE_DOMAINS](state, isEntityTypeDomains) {
    state.isEntityTypeDomains = isEntityTypeDomains
  },
  [t.SET_ACTIVE_LAW_ON_CALL_SERVICES](state, activeLawOnCallServices) {
    state.activeLawOnCallServices = activeLawOnCallServices
  },
  [t.SET_LAW_ON_CALL_OFFERED](state, lawOnCallOffered) {
    state.lawOnCallOffered = lawOnCallOffered
  },
  [t.SET_ACTIVE_DOMAINS](state, activeDomains) {
    state.activeDomains = activeDomains
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
