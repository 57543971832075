import http from '@/http'
import {
  SET_DIGITAL_WALLET_SURVEY_VOTED,
  SET_DIGITAL_WALLET_SURVEY_KEEP_OPEN,
} from '../mutations'

const STATE = {
  voted: false,
  keepSurveyOpen: false,
}

const GETTERS = {
  voted: state => state.voted,
  keepSurveyOpen: state => state.keepSurveyOpen,
}

const ACTIONS = {
  async setDigitalWalletSurveyVoted({ commit }, person_id) {
    commit(SET_DIGITAL_WALLET_SURVEY_KEEP_OPEN, false)
    if (!STATE.voted) {
      const res = await http.get(`/client/digital_wallet_surveys/voted?person_id=${person_id}`)
      const is_voted = res.data.result.voted
      commit(SET_DIGITAL_WALLET_SURVEY_VOTED, is_voted)
    }
  },
  async vote({ commit }, data) {
    const res = await http.post(`client/digital_wallet_surveys`, {
      person_id: data.person_id,
      vote: data.vote,
    })

    if (res.data?.success && res.data?.result?.voted) {
      commit(SET_DIGITAL_WALLET_SURVEY_VOTED, true)
      commit(SET_DIGITAL_WALLET_SURVEY_KEEP_OPEN, true)
    }
  },
}

const MUTATIONS = {
  [SET_DIGITAL_WALLET_SURVEY_VOTED](state, isVoted) {
    state.voted = isVoted
  },
  [SET_DIGITAL_WALLET_SURVEY_KEEP_OPEN](state, keepOpen) {
    state.keepSurveyOpen = keepOpen
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
