import * as t from '../mutations'
import http from '@/http'

const nullConnectionInfo = {
  match: null,
  db: null,
  type: null,
}

const STATE = {
  routeLoading: false,
  connectionInfo: null,
}

const GETTERS = {
  getRouteLoading: state => state.routeLoading,
  connectionInfo: state => state.connectionInfo,
  connectedToProduction: (_state, getters) => getters.connectionInfo.type === 'production',
}

const ACTIONS = {
  setRouteLoading({ commit }, { isLoading = false }) {
    commit(t.SET_ROUTE_LOADING, isLoading)
  },
  async loadConnectionInfo({ commit }) {
    commit(t.SET_CONNECTION_INFO, nullConnectionInfo)
    const res = await http.get('/client/connection_info')
    const info = res.data.response
    commit(t.SET_CONNECTION_INFO, info)
  },
}

const MUTATIONS = {
  [t.SET_ROUTE_LOADING](state, isLoading) {
    state.routeLoading = isLoading
  },
  [t.SET_CONNECTION_INFO](state, connectionInfo) {
    state.connectionInfo = connectionInfo
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
