import store from '@/store'
import http from '@/http'

// Use this method to find an existing client interaction record or create a new one if needed
// Can pass in an interaction to log during creation instead of making two separate calls
// ObjectId and ObjectTable (using Model Name) represent actual records in the DB.
// incompleteLogFromToday can be used for cases where you want to be able to track interactions
// throughout a single day until the interaction gets marked as complete.
// If you use incompleteLogFromToday, you will want a way to mark the interaction as complete to be
// able to log multiple separate interactions in a single day.
export const createOrFindClientInteractionLog = async ({
  category,
  subCategory,
  interaction = null,
  objectId = null,
  objectTable = null,
  companyId = null,
  incompleteLogFromToday = false,
  version = null,
  completeLog = false,
}) => {
  const currentDate = incompleteLogFromToday
    ? new Date().toISOString().split('T')[0]
    : null

  if (interaction != null) {
    interaction.adminUser = !!sessionStorage.getItem('admin-logged-in')
  }

  const params = {
    category: category,
    sub_category: subCategory,
    website_id: store.getters['website/website'].id,
    ...(interaction != null && { interaction }),
    ...(objectId != null && { object_id: objectId }),
    ...(objectTable != null && { object_table: objectTable }),
    ...(companyId != null && { company_id: companyId }),
    ...(currentDate && { interaction_date: currentDate }),
    ...(version != null && { version: version }),
    ...(completeLog && { complete_log: completeLog }),
  }

  const response = await http.post(`client/client_interaction_logs/create_or_find_interaction`, params)

  if (response.data.success) return response.data.response
}

// Used to log a single interaction
export const logClientInteraction = async ({
  clientInteractionLogId,
  interaction,
}) => {
  interaction.adminUser = !!sessionStorage.getItem('admin-logged-in')

  const response = await http.post(`client/client_interaction_logs/${clientInteractionLogId}/log_interaction`, {
    interaction: interaction,
  })

  if (response.data.success) return response.data.response
}

// Used to mark the interaction as complete
// Can pass in an interaction to log during creation instead of making two separate calls
// ObjectId and ObjectTable represent actual records in the DB,
// but will want to use the Model name for the ObjectTable
// can also pass in an invoice_id to be able to be able to tie a sales_invoice to the interaction
export const completeInteraction = async ({
  id,
  interaction,
  completed = false,
  object_table = null,
  object_id = null,
  invoice_id = null,
}) => {
  interaction.adminUser = !!sessionStorage.getItem('admin-logged-in')

  const params = {
    interaction,
    completed,
    ...(object_table && object_id && { object_table, object_id }),
    ...(invoice_id && { invoice_id }),
  }

  const response = await http.post(`client/client_interaction_logs/${id}/complete_interaction`, params)

  if (response.data.success) return response.data.response
}

// Used to log standard interactions
// View Page, button that redirects to new page and standard button click
export const logInteractionByTypeAndName = async ({
  id,
  type,
  name,
  subName = null,
  additionalParams = null,
}) => {
  const interaction = {
    name: name,
    ...(subName && { subName }),
  }

  // Can pass in an additionalParams object to add in custom params for specific things you want to log
  if (additionalParams && typeof additionalParams === 'object') {
    // Iterate over key-value pairs and add them to the interaction object
    Object.entries(additionalParams).forEach(([key, value]) => {
      interaction[key] = value
    })
  }

  switch (type) {
    case 'view':
      interaction.action = 'view'
      break
    case 'button':
      interaction.type = 'button'
      break
    case 'button-redirect':
      interaction.type = 'button'
      interaction.action = 'redirect'
      break
    case 'button-submit':
      interaction.type = 'button'
      interaction.action = 'submit'
      break
    default:
      break
  }

  await logClientInteraction({ clientInteractionLogId: id, interaction })
}

// Used to track progress on a specific page.
// currently just used on VTO for example to track the current and completed sections
export const logProgress = async ({ clientInteractionLogId, progress }) => {
  const response = await http.post(`client/client_interaction_logs/${clientInteractionLogId}/log_progress`,
    {
      progress: progress,
    })

  if (response.data.success) return response.data.result
}
