import { humanize, camelize } from './strings'
import _ from 'lodash'

export const camelizeKeys = source => {
  const original = Object.keys(source)
  original.forEach(key => {
    source[camelize(key)] = source[key]
    delete source[key]
  })
}

export const humanizedKeys = source => {
  return Object.keys(source).forEach(key => humanize(key))
}

/*
  Attempts to transform the inputs object or array of object keys
  by applying the transforming to each key or returns the original input
*/
export const transformKeys = (input, transformer = _.camelCase) => {
  let output = null
  if (_.isArray(input)) {
    return input.map(el => transformKeys(el, transformer))
  } else if (_.isObjectLike(input)) {
    output = {}
  } else {
    return input
  }
  const keys = Object.keys(input)
  keys.forEach(key => {
    const isUUID = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
      key
    )

    if (_.isObjectLike(input[key]) || _.isArray(input[key])) {
      output[isUUID ? key : transformer(key)] = transformKeys(input[key], transformer)
    } else {
      output[isUUID ? key : transformer(key)] = input[key]
    }
  })
  return output
}

window.transformKeys = transformKeys

export const cloneKeys = (input, keys) => {
  const result = new Object()

  keys.forEach(key => {
    result[String(key)] = input[String(key)]
  })
  if (Object.keys(result).length != keys.length) {
    throw Object.keys(input)
  }
  return result
}

window.cloneKeys = cloneKeys

const convertDotNamesToArrays = (input) => {
  const data = {}
  const keys = Object.keys(input)
  keys.forEach((k) => {
      const res = k.split('.')
      const aKey = res[0]
      if(res.length > 1){
          if(!data[aKey]){
              data[aKey]=[]
          }
          data[aKey].push(input[k])
          data[aKey] = data[aKey].flat()

      } else {
          data[k]=input[k]
      }
  })
  return data
}

window.convertDotNamesToArrays = convertDotNamesToArrays
