var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "auto-save-container" }, [
    _c("div", { staticClass: "auto-save-content" }, [
      _c("picture", [
        _c("source", {
          attrs: {
            srcset: require("@images/illustrations/stageline/autosave.webp"),
            type: "image/webp",
          },
        }),
        _c("img", {
          attrs: {
            src: require("@images/illustrations/stageline/autosave.gif"),
            alt: "Autosave",
            width: _vm.size,
            height: _vm.size,
          },
        }),
      ]),
      _c("p", { staticClass: "auto-save-title" }, [
        _vm._v("\n      Autosaving..." + _vm._s(_vm.content) + "\n    "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }