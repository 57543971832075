import * as t from '../mutations'
import TokenizerClient from '@/common/modules/tokenizer-client'
import store from '@/store'

import {
  state as BaseState,
  actions as BaseActions,
  getters as BaseGetters,
  mutations as BaseMutations,
} from '@/store/base'

const STATE = {
  ...BaseState,
  automatedClearingHouses: [],
  cards: [],
  digitalWallets: [],
  customerToken: null,
  customerTokenExp: null,
  infoAgreement: false,
  isExpiredCardConversion: false,
  isGuestPay: false,
  loadingPaymentMethods: false,
  selectedPaymentMethod: null,
  selectedPayableType: 'card',
  currentTotal: 0,
  exceedsAchTransactionLimit: false,
}

const GETTERS = {
  ...BaseGetters,
  automatedClearingHouses: state => state.automatedClearingHouses,
  cards: state => state.cards,
  customerToken: state => state.customerToken,
  customerTokenExp: state => state.customerTokenExp,
  digitalWallets: state => state.digitalWallets,
  hasExpiredCards: state => state.cards.some(c => c.expired === true),
  hasExpiredSoonCards: state => state.cards.some(c => c.expiring_soon === true),
  infoAgreement: state => state.infoAgreement,
  isExpiredCardConversion: state => state.isExpiredCardConversion,
  isGuestPay: state => state.isGuestPay,
  isSelectedExpired: state => state.selectedPaymentMethod?.expired === true,
  loadingPaymentMethods: state => state.loadingPaymentMethods,
  selectedPaymentMethod: state => state.selectedPaymentMethod,
  selectedPayableType: state => state.selectedPayableType,
  currentTotal: state => state.currentTotal,
  exceedsAchTransactionLimit: state => state.exceedsAchTransactionLimit,
}

const ACTIONS = {
  ...BaseActions,
  async refreshPaymentMethods({ dispatch }) {
    dispatch('loadPaymentMethods')
    const tokenizerClient = new TokenizerClient(store)
    const res = await tokenizerClient.paymentMethods(false)

    if (res.success) {
      dispatch('setAutomatedClearingHouses', res.data.automated_clearing_houses || [])
      dispatch('setCards', res.data.cards || [])
      // TODO: implement digital wallets, e.g. Paypal, ApplePay, etc
      // dispatch('setDigitalWallets', res.data.digitalWallets || [])
    } else {
      dispatch('setCustomerToken', null) // indicator for retries
    }

    dispatch('loadedPaymentMethods')
  },
  loadPaymentMethods({ commit }) {
    commit(t.SET_AR_LOADING_PAYMENT_METHODS, true)
  },
  loadedPaymentMethods({ commit }) {
    commit(t.SET_AR_LOADING_PAYMENT_METHODS, false)
  },
  setAutomatedClearingHouses({ commit }, achs) {
    if (Array.isArray(achs)) {
      commit(t.SET_AR_AUTOMATED_CLEARING_HOUSES, achs)
    }
  },
  setCards({ commit }, cards) {
    if (Array.isArray(cards)) {
      commit(t.SET_AR_CARDS, cards)
    }
  },
  setDigitalWallets({ commit }, digitalWallets) {
    if (Array.isArray(digitalWallets)) {
      commit(t.SET_AR_DIGITAL_WALLETS, digitalWallets)
    }
  },
  setCustomerToken({ commit, dispatch }, token) {
    dispatch('setCustomerTokenExpiration')
    commit(t.SET_AR_CUSTOMER_TOKEN, token)
  },
  setCustomerTokenExpiration({ commit }) {
    let timeHourFromNow = new Date(new Date().getTime() + 59 * 60000).getTime()
    commit(t.SET_AR_CUSTOMER_TOKEN_EXPIRATION, timeHourFromNow)
  },
  setInfoAgreement({ commit }, agreement) {
    commit(t.SET_AR_INFO_AGREEMENT, agreement)
  },
  setIsExpiredCardConversion({ commit }, expiredCardConversion) {
    commit(t.SET_AR_EXPIRED_CARD_CONVERSION, expiredCardConversion)
  },
  setIsGuestPay({ commit }, isGuestPay) {
    commit(t.SET_AR_IS_GUEST_PAY, isGuestPay)
  },
  resetPaymentMethod({ dispatch }) {
    dispatch('setPaymentMethod', { payable: null, type: 'card' })
  },
  setPaymentMethod({ dispatch }, { payable, type }) {
    dispatch('setSelectedPaymentMethod', payable)
    dispatch('setSelectedPayableType', type)
  },
  setSelectedPaymentMethod({ commit }, payable) {
    commit(t.SET_SELECTED_PAYMENT_METHOD, payable)
  },
  setSelectedPayableType({ commit }, type) {
    if (type !== null) {
      commit(t.SET_AR_SELECTED_PAYABLE_TYPE, type)
    }
  },
  setCurrentTotal({ commit }, newValue) {
    commit(t.SET_AR_CURRENT_TOTAL, newValue)
  },
  setExceedsAchTransactionLimit({ commit }, bool) {
    commit(t.SET_AR_EXCEEDS_ACH_TRANSACTION_LIMIT, bool)
  },
  resetCurrentTotal({ dispatch }) {
    dispatch('setCurrentTotal', 0)
    dispatch('setExceedsAchTransactionLimit', false)
  },
}

const MUTATIONS = {
  ...BaseMutations,
  [t.SET_AR_AUTOMATED_CLEARING_HOUSES](state, achs) {
    state.automatedClearingHouses = achs
  },
  [t.SET_AR_CARDS](state, cards) {
    state.cards = cards
  },
  [t.SET_AR_CUSTOMER_TOKEN](state, customerToken) {
    state.customerToken = customerToken
  },
  [t.SET_AR_CUSTOMER_TOKEN_EXPIRATION](state, hourFromNowDate) {
    state.customerTokenExp = hourFromNowDate
  },
  [t.SET_AR_DIGITAL_WALLETS](state, digitalWallets) {
    state.digitalWallets = digitalWallets
  },
  [t.SET_AR_LOADING_PAYMENT_METHODS](state, loading) {
    state.loadingPaymentMethods = loading
  },
  [t.SET_AR_INFO_AGREEMENT](state, agreement) {
    state.infoAgreement = agreement
  },
  [t.SET_AR_EXPIRED_CARD_CONVERSION](state, isExpiredCardConversion) {
    state.expiredCardConversion = isExpiredCardConversion
  },
  [t.SET_AR_IS_GUEST_PAY](state, isGuestPay) {
    state.isGuestPay = isGuestPay
  },
  [t.SET_SELECTED_PAYMENT_METHOD](state, payable) {
    state.selectedPaymentMethod = payable
  },
  [t.SET_AR_SELECTED_PAYABLE_TYPE](state, type) {
    state.selectedPayableType = type
  },
  [t.SET_AR_CURRENT_TOTAL](state, newVal) {
    state.currentTotal = newVal
  },
  [t.SET_AR_EXCEEDS_ACH_TRANSACTION_LIMIT](state, bool) {
    state.exceedsAchTransactionLimit = bool
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
