export const EMAIL_MISSING = { email: ['must be filled'] }
export const PASSWORD_MISSING = { password: ['must be filled'] }
export const EMAIL_NOT_FOUND = { email: ['Not found'] }
export const RESET_EMAIL_SENT = { email: ['Reset email sent'] }
export const INVALID_LOGIN = {
  email: ['Incorrect email or password'],
  password: ['Incorrect email or password'],
}

export const INVALID_REQUEST = 'Invalid request'
export const INVALID_ACTION = 'Invalid action'
export const SERVER_ERROR = 'There was a problem communicating with the server'

export const INVALID_NAMESPACE = 'Namespace not defined on store module extending base.js'

export const MISSING_IDENTIFIER = 'missing'
