import * as t from '../mutations'
import http from '@/http'
import store from "@/store"

const STATE = {
  quoteStatus: false,
  signatureId: null,
  merchantServiceEligibility: false,
  showProcessingAd: false,
}

const GETTERS = {
  quoteStatus: state => state.quoteStatus,
  signatureId: state => state.signatureId,
  merchantServiceEligibility: state => state.merchantServiceEligibility,
  showProcessingAd: state =>  state.showProcessingAd,
}

const ACTIONS = {
  async getSignatureIdForQuoteDocument ({ commit }, { documentID }) {
    await http.get(`client/credit_card_processing/credit_card_processing_details_for_document_id`, { params: { document_id: documentID } })
      .then((response) => {
        commit(t.SET_SIGNATURE_ID, response.data.result?.signature_id)
    })
  },
  async getEligibilityForCreditCardProcessing ({ commit }, { companyId }) {
    await http.get(`client/credit_card_processing/company_eligible_to_order_merchant_service`, { params: { company_id: companyId } })
      .then((response) => {
        commit(t.SET_MERCHANT_SERVICE_ELIGIBILITY, response.data.result?.eligible)
      })
  },
  async getQuoteStatus ({ commit }, { documentID }) {
    await http.get(`client/client_documents/${documentID}`).then((response) => {
      if (response.data.result.status === 'accepted') {
        commit(t.SET_QUOTE_STATUS, true)
      }
    })
  },
  async acceptOrDeclineQuote ({ commit }, { documentID, selectedRoute }) {
    await http.get(`client/client_documents/${documentID}/${selectedRoute}`).then(() => {
      commit(t.SET_QUOTE_STATUS, true)
    })
  },
  async getProcessingAdStatus({ commit }, ownerID) {
    //Check if client/website can even order card processing
    const product = await store.dispatch('checkout/fetchProduct', {
      productKind: 'simple_product',
      productCategory: 'merchant-service',
    })
    if (!product.length) {
      commit(t.SET_PROCESSING_AD_STATUS, false)
      return
    }

    http.get('client/credit_card_processing/processing_ad_status', { params: { owner_id: ownerID } })
      .then((response) => {
        commit(t.SET_PROCESSING_AD_STATUS, response.data.result?.show_ad)
      }).catch(commit(t.SET_PROCESSING_AD_STATUS, false))
  },
  async setProcessingAdStatus({ commit }, { ownerID, status, showInFuture }) {
    commit(t.SET_PROCESSING_AD_STATUS, status)
    await http.post('client/credit_card_processing/processing_ad_status', {
      owner_id: ownerID,
      show_in_future: showInFuture,
    })
  },
}

const MUTATIONS = {
  [t.SET_QUOTE_STATUS] (state, quoteStatus) {
    state.quoteStatus = quoteStatus
  },
  [t.SET_SIGNATURE_ID] (state, signatureId) {
    state.signatureId = signatureId
  },
  [t.SET_MERCHANT_SERVICE_ELIGIBILITY] (state, eligible) {
    state.merchantServiceEligibility = eligible
  },
  [t.SET_PROCESSING_AD_STATUS] (state, shouldShow) {
    state.showProcessingAd = shouldShow
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
