import * as t from '../mutations'
import { Client, GET, POST } from '@/api/client'
import {
  ACCOUNT,
  AUTH_CREATE,
  AUTH_FORGOT_PASSWORD,
  WEBSITE_KEYCLOAK_CONFIG,
} from '@/api/v3/endpoints'
import { RESET } from '@/store/actions'
import * as Cookies from 'js-cookie'
import { transformKeys } from '@/common/modules/object'

import {
  actions as BaseActions,
  getters as BaseGetters,
  mutations as BaseMutations,
  state as BaseState,
} from '@/store/base'

import _ from 'lodash'

const STATE = {
  ...BaseState,
  namespace: 'session',
  token: null,
  expiration: null,
  accountId: null,
  accountEmail: null,
  websiteId: null,
  redirectUrl: null,
  keycloak: null,
  keycloakAuthenticated: false,
  keycloakConfig: undefined,
  initialized: {
    session: false,
    stageline: false,
    companies: false,
  },
  isAdminImpersonatingClient: false,
}

const GETTERS = {
  ...BaseGetters,
  getToken(state) {
    return state.token || Cookies.get('token') || Cookies.get('secure_token')
  },
  getExpiration(state) {
    return state.expiration
  },
  getAccountId(state) {
    return state.accountId
  },
  getAccountEmail(state) {
    return state.accountEmail
  },
  getWebsiteId(state) {
    return state.websiteId
  },
  getRedirectUrl(state) {
    return state.redirectUrl
  },
  getInitialized(state) {
    return state.initialized
  },
  getKeycloak(state) {
    return state.keycloak
  },
  getKeycloakAuthenticated(state) {
    return state.keycloakAuthenticated
  },
  getKeycloakConfig(state) {
    return state.keycloakConfig
  },
  isInitialized: (state) => (storeName) => {
    return state.initialized[storeName] || false
  },
  isAdminImpersonatingClient (state) {
    return state.isAdminImpersonatingClient
  },
}

const ACTIONS = {
  ...BaseActions,
  async refresh({ getters }) {
    const client = new Client(null, getters.getToken, getters.getExpiration)
    await client.refresh()
  },

  setAdminImpersonation({ commit }, isImpersonating) {
    commit(t.SET_ADMIN_IMPERSONATION, isImpersonating)
    localStorage.setItem('adminImpersonatingClient', isImpersonating.toString())
  },
  checkAdminImpersonation({ commit }) {
    const isImpersonating = localStorage.getItem('adminImpersonatingClient') === 'true'
    commit(t.SET_ADMIN_IMPERSONATION, isImpersonating)
  },

  initializeAdminImpersonationFromUrl({ dispatch }) {
    const urlParams = new URLSearchParams(window.location.search)
    const isAdmin = urlParams.get('admin') === 'true'

    if (isAdmin) {
      dispatch('setAdminImpersonation', true)
    } else {
      dispatch('checkAdminImpersonation')
    }
  },

  async updateAccount({ commit, dispatch, getters }) {
    const client = new Client(null, getters.getToken, getters.getExpiration)
    const result = await client.call(
      {
        method: GET,
        path: ACCOUNT,
        params: {},
      },
      false
    )

    if (result.failure || !result) {
      dispatch(RESET)
      return result
    }

    await dispatch('account/loadAccount', transformKeys(result.data.account, _.snakeCase), { root: true })
    const current = transformKeys(result.data.account, _.camelCase)
    commit('setAccountId', { accountId: current.id })
    commit('setWebsiteId', { websiteId: current.websiteId })
    commit('setAccountEmail', { accountEmail: current.ownerEmail })
    commit('account/setAll', { all: [current] }, { root: true })
  },
  async create({ commit, dispatch }, { emailAddress, password, websiteId, subdomain }) {
    try {
      const client = new Client()
      const result = await client.call(
        {
          method: POST,
          path: AUTH_CREATE,
          params: {
            email_address: emailAddress,
            password: password,
            website_id: websiteId,
            website_subdomain: subdomain,
          },
        },
        false
      )

      if (result && result.success) {
        commit('setToken', { token: result.data.token })
        commit('setExpiration', { expiration: result.data.expiration })
        client.setCredentials(result.data.token, result.data.expiration)
        await dispatch('app/loadConnectionInfo', commit, { root: true })

        const details = await client.call({
          method: GET,
          path: ACCOUNT,
          params: null,
        })

        const current = transformKeys(details.data.account, _.camelCase)
        commit('setAccountId', { accountId: current.id })
        commit('setWebsiteId', { websiteId: current.websiteId })
        commit('setAccountEmail', { accountEmail: current.ownerEmail })
        commit('account/setAll', { all: [current] }, { root: true })

        return result
      } else {
        throw result
      }
    } catch (error) {
      dispatch(RESET)
      return error
    }
  },
  async forgotPassword(context, { email, websiteSubdomain }) {
    const client = new Client()
    try {
      return await client.call({
        method: POST,
        path: AUTH_FORGOT_PASSWORD,
        params: {
          email_address: email,
          website_subdomain: websiteSubdomain,
        },
      }, false)
    } catch (error) {
      return error
    }
  },
  async setPreviousLink({ commit }, { previousLink }) {
    await commit('setPreviousLink', { previousLink })
  },
  async setRedirectUrl({ commit }, { redirectUrl }) {
    await commit('setRedirectUrl', { redirectUrl })
  },
  async setKeycloak({ commit }, { keycloak }) {
    await commit('setKeycloak', { keycloak })
  },
  async setKeycloakAuthenticated({ commit }, { keycloakAuthenticated }) {
    await commit('setKeycloakAuthenticated', { keycloakAuthenticated })
  },
  async setKeycloakConfig({ commit }, { keycloakConfig }) {
    await commit('setKeycloakConfig', { keycloakConfig })
  },
  async fetchKeycloakConfig({ dispatch, getters }, { realm }) {
    const client = new Client(null, getters.getToken, getters.getExpiration)
    const result = await client.call(
      {
        method: POST,
        path: WEBSITE_KEYCLOAK_CONFIG,
        params: {
          realm: realm,
        },
      },
      false
    )

    if (result && result.success) {
      await dispatch("setKeycloakConfig", { keycloakConfig: result.data.response.config })
    } else {
      throw result
    }
  },
  async setInitialized({ commit, getters }, { storeName, value }) {
    commit(t.SET_INITIALIZED, { ...getters.getInitialized, [storeName]: value })
  },
}

const MUTATIONS = {
  ...BaseMutations,
  [t.SET_TOKEN]: (state, { token }) => {
    state.token = token
  },
  [t.SET_EXPIRATION]: (state, { expiration }) => {
    state.expiration = expiration
  },
  [t.SET_ACCOUNT_ID]: (state, { accountId }) => {
    state.accountId = accountId
  },
  [t.SET_ACCOUNT_EMAIL]: (state, { accountEmail }) => {
    state.accountEmail = accountEmail
  },
  [t.SET_WEBSITE_ID]: (state, { websiteId }) => {
    state.websiteId = websiteId
  },
  [t.SET_PREVIOUS_LINK]: (state, { previousLink }) => {
    state.previousLink = previousLink
  },
  [t.SET_REDIRECT_URL]: (state, { redirectUrl }) => {
    state.redirectUrl = redirectUrl
  },
  [t.SET_KEYCLOAK]: (state, { keycloak }) => {
    state.keycloak = keycloak
  },
  [t.SET_KEYCLOAK_AUTHENTICATED]: (state, { keycloakAuthenticated }) => {
    state.keycloakAuthenticated = keycloakAuthenticated
  },
  [t.SET_KEYCLOAK_CONFIG]: (state, { keycloakConfig }) => {
    state.keycloakConfig = keycloakConfig
  },
  [t.SET_INITIALIZED]: (state, initialized) => {
    state.initialized = initialized
  },
  [t.SET_ADMIN_IMPERSONATION]: (state, isImpersonating) => {
    state.isAdminImpersonatingClient = isImpersonating
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
