import http from '@/http'
import * as t from '@/store/mutations'

const STATE = {
  vouchers: [],
  accountDomainVouchers: [],
}

const GETTERS = {
  vouchers: state => state.vouchers,
  accountDomainVouchers: state => state.accountDomainVouchers,
  voucherByProductCategory: (_state, getters) => productCategory => {
    return getters.vouchers.find(voucher => voucher.product.category === productCategory)
  },
  unRedeemedVoucherByProductCategory: (_state, getters) => productCategory => {
    return getters.vouchers.find(voucher => voucher.product.category === productCategory &&
      !voucher.redeemed
    )
  },
  vouchersByProductCategoryCompanyId: (_state, getters) => (productCategory, companyId) => {
    return getters.vouchers.filter(voucher => voucher.product.category === productCategory &&
      voucher.company.id === companyId
    )
  },
  unRedeemedVoucherByProductCategoryCompanyId: (_state, getters) => (productCategory, companyId) => {
    return getters.vouchers.find(voucher => voucher.product.category === productCategory &&
      !voucher.redeemed && voucher.company.id === companyId
    )
  },
  companyIdFromBusinessDomainVouchers: (_state, getters) => {
    return getters.accountDomainVouchers.find(voucher => !voucher.redeemed)?.company_id
  },
}

const ACTIONS = {
  async fetchCompanyVouchers({ commit }, companyId) {
    const response = await http.get(`client/companies/${companyId}/vouchers`)

    if (response.data.success) {
      commit(t.SET_VOUCHERS, response.data.result)
    } else {
      commit(t.SET_VOUCHERS, [])
    }
  },
  async fetchAccountDomainVouchers({ commit }) {
    const response = await http.get(`client/account/business_domain_vouchers`)

    if (response.data.success) {
      commit(t.SET_ACCOUNT_DOMAIN_VOUCHERS, response.data.result.business_domain_vouchers)
    } else {
      commit(t.SET_ACCOUNT_DOMAIN_VOUCHERS, [])
    }
  },
}

const MUTATIONS = {
  [t.SET_VOUCHERS](state, vouchers) {
    state.vouchers = vouchers
  },
  [t.SET_ACCOUNT_DOMAIN_VOUCHERS](state, vouchers) {
    state.accountDomainVouchers = vouchers
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
