import http from '@/http'
import store from '@/store'
import { camelCase } from 'lodash'

const actions = {
  async determineTrialEligibility({ dispatch }, { company_id, product_category }) {
    const result = await http.get(`/client/trial_eligibility/${company_id}/${product_category}`)
    const is_eligible = result.data.response.is_eligible

    const moduleName = camelCase(product_category)
    const actionPath = `${camelCase(product_category)}/setTrialEligibility`
    const actionExists = store.hasModule(moduleName) && store._actions[actionPath]

    if (actionExists) {
      dispatch(actionPath, is_eligible, { root: true })
    } else {
      return is_eligible
    }
  },

  async determineTrialEligibilityBatch({ _dispatch }, { company_id, product_categories }) {
    const result = await http.post(`/client/trial_eligibility/${company_id}`, {
      product_categories: product_categories,
    })

    return result.data.response
  },

}

export default {
  namespaced: true,
  actions,
}
