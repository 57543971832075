import { Client, POST, PUT } from '@/api/client'
import { cloneKeys } from '@/common/modules/object'
import { INVALID_NAMESPACE, MISSING_IDENTIFIER } from '@/common/modules/errors'
import Pluralize from 'pluralize'

import _ from 'lodash'

/*
  Usage:

    import {
        state as BaseState,
        getters as BaseGetters,
        mutations as BaseMutations,
        actions as BaseActions,
    } from '../base'

  const state = {
      ...BaseState,
      namespace: yourModuleName
  }

  const getters = {
      ...BaseGetters,
  }

  export const mutations = {
      ...BaseMutations,
  }

  const actions = {
      ...BaseActions
  }

*/

export const wrap = (input, namespace, rootState, dispatch) => {
  if (rootState.moduleSchema[namespace].belongsTo) {
    rootState.moduleSchema[namespace].belongsTo.forEach(parent => {
      Object.keys(parent).forEach(key => {
        if (key == 'base' || !input[parent[key]]) {
          // console.log(`Invalid relationship! namespace:${namespace} ${parent[key]} is not valid`)
          // console.log('-->', namespace, key, parent, input[parent[key]])
        } else {
          if (key != 'base') {
            input[key] = async () => {
              console.log(`${namespace}${key}/fetchId`, { id: input[parent[key]] })
              const r = await dispatch(`${key}/fetchId`, { id: input[parent[key]] }, { root: true })
              return r
            }
          }
        }
      })
    })
  }
  if (rootState.moduleSchema[namespace].hasMany) {
    rootState.moduleSchema[namespace].hasMany.forEach(parent => {
      Object.keys(parent).forEach(key => {
        input[Pluralize(key)] = async () => {
          return await dispatch(
            `${key}/findKeyValues`,
            { kv: { [parent[key]]: input.id } },
            { root: true }
          )
        }
      })
    })
  }

  return input
}

export const state = {
  all: [],
  namespace: 'base', //In submodules be sure to override the namespace by defining namespace *after* spreading the base state
}

export const getters = {
  getAll: state => {
    // console.log(`[${state.namespace}.getAll]`, state.all.map((a) => a))
    return state.all
  },
  getNamespace: state => {
    return state.namespace
  },
}

export const mutations = {
  setAll: (state, { all }) => {
    // console.log(`[${state.namespace}.setAll]`, all)
    state.all = all
  },
  setNamespace: (state, { namespace }) => {
    state.namespace = namespace
  },
}

export const actions = {
  create: async function ({ state, rootState, rootGetters, dispatch, commit }, { record }) {
    const namespace = state.namespace
    const endpoints = rootState.moduleSchema[namespace].endpoints
    const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
    if (endpoints) {
      const endpoint = endpoints.create || endpoints.fetchId
      if (endpoint) {
        // console.log({ [namespace]: record })
        const response = await client.call({
          method: POST,
          path: endpoint.path,
          params: { [namespace]: record },
        })
        if (response && response.success) {
          commit(
            `${namespace}/setAll`,
            {
              all: [...state.all.filter(r => r.id != record.id), response.data],
            },
            { root: true }
          )
          return wrap(response.data, namespace, rootState, dispatch)
        } else {
          throw response.data
        }
      }
    }
  },
  update: async function ({ state, rootState, rootGetters, dispatch, commit }, { record }) {
    const namespace = state.namespace
    const endpoints = rootState.moduleSchema[namespace].endpoints
    const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])

    if (endpoints) {
      const endpoint = endpoints.update || endpoints.fetchId
      if (endpoint) {
        // console.log(`${endpoint.path.mapping(record, true)}/${record.id}`)
        const response = await client.call({
          method: PUT,
          path: `${endpoint.path.mapping(record, true)}/${record.id}`,
          params: { [namespace]: record },
        })

        if (response && response.success) {
          commit(
            `${namespace}/setAll`,
            {
              all: [...state.all.filter(r => r.id != record.id), response.data],
            },
            { root: true }
          )
          return wrap(response.data, namespace, rootState, dispatch)
        } else {
          throw response.data
        }
      }
    }
  },
  destroy: async function ({ state, rootState, rootGetters, dispatch, commit }, { id }) {
    const recordId = id
    const namespace = state.namespace
    const endpoints = rootState.moduleSchema[namespace].endpoints
    const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])

    if (endpoints) {
      const endpoint = endpoints.destroy || endpoints.fetchId
      if (endpoint) {
        const response = await client.call({
          method: 'delete',
          path: `${endpoint.path}/${recordId}`,
        })
        if (response && response.success) {
          commit(`${namespace}/setAll`, { all: state.all.filter(r => r.id != id) }, { root: true })
          return wrap(response.data, namespace, rootState, dispatch)
        } else {
          throw response.data
        }
      }
    }
  },

  /*
    const example = [
        { id: 1, firstName: 'foo', lastName: 'bar' },
        { id: 2, firstName: 'john', lastName: 'doe' },
        { id: 3, firstName: 'john', lastName: 'blah' },
    ]

    store.commit('moduleName/getId', {id: 1}
    => { id: 1, firstName: 'foo', lastName: 'bar' }

    store.commit('moduleName/getId', {id: 'blah'}
    => null
  */

  getId: async ({ getters, state, rootState, dispatch }, { id }) => {
    // console.log(`${state.namespace}/getId/${id}`)
    const result = getters.getAll.filter(record => record.id === id)[0]

    return result ? wrap(result, state.namespace, rootState, dispatch) : result
  },

  fetchId: async function ({ rootState, rootGetters, state, dispatch }, { id, params }) {
    const namespace = state.namespace
    const endpoints = rootState.moduleSchema[namespace].endpoints
    const recordId = id
    const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
    //const existing = state.all.filter((r) => r.id == id)[0]
    // if (existing) {
    //   return wrap(existing, state.namespace, rootState, dispatch)
    // }
    // console.log(`${namespace}/fetchId, { ${id}}`)
    if (endpoints) {
      const endpoint = endpoints.fetchId
      if (endpoint) {
        // console.log(namespace, endpoint)
        try {
          const args = {
            method: endpoint.method,
            path: `${endpoint.path}/${recordId}`,
          }

          if (params) {
            args.params = params
          }

          const result = await client.call(args)

          delete result.data.company
          const record = await this.dispatch(`${namespace}/createOrReplace`, {
            record: result.data,
          })
          return wrap(record, namespace, rootState, dispatch)
        } catch (error) {
          return error
        }
      } else {
        throw 'Endpoint not defined!'
      }
    } else {
      throw 'Endpoint not defined!'
    }
  },
  findKeyValues: async ({ state, rootState }, { kv }) => {
    // console.log(`${state.namespace}/findKeyValues/`, kv)
    const searchKeys = []

    if (!kv) {
      return []
    }
    const namespace = state.namespace
    const attributes = rootState.moduleSchema[namespace].attributes
      ? Object.keys(rootState.moduleSchema[namespace].attributes)
      : Object.keys(kv)

    if (attributes) {
      //Only search the defined keys
      Object.keys(kv).forEach(key => (attributes.includes(key) ? searchKeys.push(key) : null))
    }
    const notMatched = input =>
      searchKeys
        .map(key => input[key] == kv[key])
        .flat()
        .includes(false)

    const result = state.all.filter(record => notMatched(record) == false)
    return result
  },

  createOrReplace: async ({ state, commit, rootState, dispatch }, { record }) => {
    const namespace = state.namespace
    const primaryKey = rootState.moduleSchema[namespace].uuid
    // console.log(`${namespace}.createOrReplace(`, record, '}')
    if (!record) {
      throw `Unrecognized payload sent to ${namespace}.createOrReplace({record:${record}})`
    }

    if (!primaryKey) {
      throw `${namespace} has no uuid defined in the moduleSchema`
    }

    const uuidKey = record.id ? 'id' : `${namespace}Id`

    if (!namespace) {
      throw INVALID_NAMESPACE
    }

    if (record[uuidKey]) {
      record.id = record[uuidKey]
    }

    if (!record[primaryKey]) {
      throw `uuid:${primaryKey} is ${MISSING_IDENTIFIER} on ${namespace} record:${JSON.stringify(
        record
      )}`
    }

    //validate?
    let namespaceKeys = Object.keys(rootState.moduleSchema[namespace].attributes)

    const kv = cloneKeys(record, namespaceKeys)
    if (Object.keys(kv).length != namespaceKeys.length) {
      throw Object.keys(kv)
    }
    //A match is if an existing records id and the new records id's match
    //If createOrReplaceAttributes are present in the moduleSchema for this namespace, all attributes must match
    const createOrReplaceAttributes = rootState.moduleSchema[namespace].createOrReplaceAttributes
      ? rootState.moduleSchema[namespace].createOrReplaceAttributes
      : Object.keys(rootState.moduleSchema[namespace].uuid)

    const notMatched = input =>
      createOrReplaceAttributes
        .map(function (key) {
          return input[key] === kv[key]
        })
        .flat()
        .includes(false)

    const update = state.all.filter(record => notMatched(record) == true)

    commit(`${namespace}/setAll`, { all: [...update, kv] }, { root: true })

    //createOrReplace nested children that belongTo this namespace

    if (rootState.moduleSchema[namespace].hasMany && rootState.moduleSchema[namespace].hasMany.length > 0) {
      rootState.moduleSchema[namespace].hasMany.forEach(hasMany => {
        Object.keys(hasMany).forEach(childNamespace => {
          const childrenNamespace = Pluralize(childNamespace)
          if (record[childrenNamespace] && Array.isArray(record[childrenNamespace])) {
            record[childrenNamespace].forEach(async childRecord => {
              childRecord[`${namespace}Id`] = record.id
              await dispatch(
                `${childNamespace}/createOrReplace`,
                { record: childRecord },
                { root: true }
              )
            })
          }
        })
      })
    }

    //createOrReplace nested parents this record belong(s)To
    if (rootState.moduleSchema[namespace].belongsTo && rootState.moduleSchema[namespace].belongsTo.length > 0) {
      rootState.moduleSchema[namespace].belongsTo.forEach(belongsTo => {
        Object.keys(belongsTo).forEach(async parentNamespace => {
          if (record[parentNamespace] && _.isFunction(record[parentNamespace])) {
            let parent = record[parentNamespace]
            if (_.isFunction(parent)) {
              parent = await parent()
            }

            //console.log('create nested parent', record, parent, _.isFunction(parent))
            await dispatch(`${parentNamespace}/createOrReplace`, { record: parent }, { root: true })
          }
        })
      })
    }

    return state.all
      .filter(someRecord => record.id === someRecord.id)
      .map(r => wrap(r, namespace, rootState, dispatch))[0]
  },

  fetchAll: async function ({ state, rootState, rootGetters, dispatch }, params = null) {
    const namespace = state.namespace
    const endpoints = rootState.moduleSchema[namespace].endpoints
    const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
    const all = []

    if (endpoints) {
      const endpoint = endpoints.fetchAll
      if (endpoint) {
        const args = {
          method: endpoint.method,
          path: params ? endpoint.path.mapping(params, true) : endpoint.path,
        }
        if (params) {
          args.params = params
        }

        args.path = params ? endpoint.path.mapping(params, true) : endpoint.path
        const response = await client.call(args)

        if (response && response.success) {
          for (let i = 0; i < response.data.length; i++) {
            const record = response.data[i]
            const result = await dispatch(
              `${namespace}/createOrReplace`,
              {
                record,
              },
              { root: true }
            )
            all.push(result)
          }

          return all
        } else {
          throw response.data
        }
      }
    } else {
      // throw ('fetchAll endpoint undefined for ', namespace)
      state.all.forEach(record => {
        const item = wrap(record, namespace, rootState, dispatch)
        all.push(item)
      })

      return all
    }
  },
}
