/* SCHEMA:
    "id": "11ccc86b-6a64-4239-897b-14fdb21eafa1",
    "agency_id": "64e8ae5a-dd27-4d0d-994e-05b6761a2120",
    "name": "compliance filing",
    "locality": "both",
    "description": "Decennial Reports",
    "dependent_filing_names": [],
    "dependent_filings_info": []
*/

import * as t from '../mutations'
import http from '@/http'
import { Client, GET } from '@/api/client'
import { FILING_METHODS, FILING_AND_AGENCY_DATA } from '@/api/v3/endpoints'

const STATE = {
  schemas: [],
  schemaLoaded: true,
}

const GETTERS = {
  schemas: state => state.schemas,
  schemaFor: state => ({ companyId, id }) =>
    state.schemas.find(s => s.companyId === companyId && s.id === id),
  schemaLoaded: state => state.schemaLoaded,

  combinedSchemaFor(state, getters) {
    // TODO: this should put all hidden fields into all of the sub-categories
    return ({ companyId, ids }) => {
      const fields = {
        ...ids.reduce((acc, id) => ({ ...acc, [id]: [] }), {}),
        people: [],
        all: [],
      }

      for (let id of ids) {
        const schemaRecord = getters.schemaFor({ companyId, id })

        if (!schemaRecord || !Array.isArray(schemaRecord.schema)) {
          continue
        }

        for (let schema of schemaRecord.schema) {
          for (let field of schema.fields) {
            if (field.meta.type === 'address') {
              if (!fields[id].find(i => i.name === field.name)) {
                fields[id].push(field)
              }
            } else if (field.meta.type === 'person' || field.name === 'management_type') {
              if (!fields.people.find(i => i.name === field.name)) {
                fields.people.push(field)
              }
            } else if (field.type !== 'hidden') {
              if (!fields.all.find(i => i.name === field.name)) {
                fields.all.push(field)
              }
            } else {
              Object.keys(fields).forEach(key => {
                fields[key].push(field)
              })
            }
          }
        }
      }

      for (let [key, val] of Object.entries(fields)) {
        if (!val.filter(i => i.type !== 'hidden').length) {
          fields[key] = []
        }
      }

      return fields
    }
  },
}

const ACTIONS = {
  async fetchFilingMethods({ rootGetters }, { params }) {
    try {
      const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
      return await client.call({
        method: GET,
        path: `/api/v3/client/${FILING_METHODS}`,
        params: params,
      })
    } catch (error) {
      return error
    }
  },
  async fetchFilingAndAgency({ rootGetters }, { params }) {
    try {
      const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
      return await client.call({
        method: GET,
        path: FILING_AND_AGENCY_DATA,
        params: params,
      })
    } catch (error) {
      return error
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async fetchMailFilingMethod({}, { companyId, filingName, filingSubName, jurisdictionId }) {
    const response = await http.get(`filings/mailing_filing_method`, {
      params: {
        company_id: companyId,
        jurisdiction_id: jurisdictionId,
        name: filingName,
        sub_name: filingSubName,
      },
    })

    return response.data.success ? response.data.filing_method : []
  },
  async loadSchema({ commit, getters }, { id, companyId, forceReload = false }) {
    if (!forceReload && getters.schemaFor({ id, companyId })) {
      return
    }

    commit(t.LOAD_FM_SCHEMA_START)

    const response = await http.get(`filing_methods/${id}/schema`, {
      params: { company_id: companyId },
    })

    commit(t.LOAD_FM_SCHEMA_FINISHED, {
      id,
      companyId,
      schema: response.data.result.schema,
    })
  },

  async loadSchemas({ dispatch }, { companyId, ids }) {
    for (let id of ids) {
      await dispatch('loadSchema', { id, companyId })
    }
  },
}

const MUTATIONS = {
  [t.LOAD_FM_SCHEMA_START](state) {
    state.schemaLoaded = false
  },

  [t.LOAD_FM_SCHEMA_FINISHED](state, schema) {
    state.schemas.unshift(schema)
    state.schemaLoaded = true
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}

