import * as t from '../mutations'

const STATE = {
  stagedFiles: [],
  filesUploading: false,
  filesUploaded: false,
}

const GETTERS = {
  stagedFiles:        state => state.stagedFiles,
  filesUploading:     state => state.filesUploading,
  filesUploaded:      state => state.filesUploaded,
}

const ACTIONS = {
  resetFileDrop({ commit }) {
    commit(t.RESET_FILE_DROP)
  },
  addStagedFiles({ commit }, { files }) {
    commit(t.ADD_STAGED_FILES, files)
  },
  setFilesUploading({ commit }, value) {
    commit(t.SET_FILES_UPLOADING, value)
  },
  setFilesUploaded({ commit }, value) {
    commit(t.SET_FILES_UPLOADED, value)
  },
}

const MUTATIONS = {
  [t.RESET_FILE_DROP](state) {
    state.stagedFiles         = []
    state.filesUploading      = false
    state.filesUploaded       = false
  },
  [t.ADD_STAGED_FILES](state, files) {
    state.stagedFiles = [...state.stagedFiles, ...files]
  },
  [t.SET_FILES_UPLOADING](state, value) {
    state.filesUploading = value
  },
  [t.SET_FILES_UPLOADED](state, value) {
    state.filesUploaded = value
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
