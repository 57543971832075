<template>
  <div
    class="auto-save-container"
  >
    <div
      class="auto-save-content"
    >
      <picture>
        <source
          :srcset="require('@images/illustrations/stageline/autosave.webp')"
          type="image/webp"
        >
        <img
          :src="require('@images/illustrations/stageline/autosave.gif')"
          alt="Autosave"
          :width="size"
          :height="size"
        >
      </picture>
      <p class="auto-save-title">
        Autosaving...{{ content }}
      </p>
    </div>
  </div>
</template>

<script>
/*
Corptools Auto Save Component

Props:
  - size (equal width and height -> square)
    - size parameter needs unit of measurement (e.g., 75px)
    - defaults at 100px
  - content
    - examples of use 'Saved' -> Autosaving... Saved
    - defaults to empty string

Example usage:
<ct-auto-save v-if="savingData" :size="'75px'" :content="'Saved'" />

*/

export default {
  name: 'CtAutoSave',
  props: {
    size: {
      type: String,
      default: '100px',
    },
    content: {
      type: String,
      default: '',
    },
  },
}
</script>
<style lang="scss" scoped>
@mixin flexbox($flex-direction: row) {
  display: flex;
  flex-flow: $flex-direction nowrap;
  justify-content: flex-start;
  align-items: center;
}

div.auto-save-container {
  width: 100%;
  padding-top: 0.8em;
  @include flexbox($flex-direction: column);

  div.auto-save-content {
    @include flexbox();

    p.auto-save-title {
      min-width: 110px;
      margin: 0;
      font-size: 1.0em;
      color: #A9A9A9;
    }
  }
}
</style>

