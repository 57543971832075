import * as t from '../mutations'
import http from '@/http'

const STATE = {
  lawOnCallTrialEligible: false,
  legalSpecs: {},
  legalSpecsLoaded: false,
  tosDocs: {},
  tosDocsLoaded: false,
}

const GETTERS = {
  lawOnCallTrialEligible: state => state.lawOnCallTrialEligible,
  legalSpecs: state => state.legalSpecs,
  tosDocs: state => state.tosDocs,
  offerLawOnCall: (state, _getters, _rootState, rootGetters) => {
    // Move logic to a getter to reduce infinite loop, and recursion errors causing browser crash in development
    const activeRAServices = rootGetters['companies/activeRAServices']
    const cartProductsByKind = rootGetters['checkout/cartProductsByKind']('registered_agent_product')
    if (!activeRAServices) return false

    if (!cartProductsByKind.length > 0) return false

    return activeRAServices.find(service => service.jurisdiction.state_province_region === 'Utah') ||
      cartProductsByKind.find(p => p.jurisdiction === 'Utah')
  },
}

const ACTIONS = {
  async loadLegalSpecs({ commit }, { jurisdictionIds }) {
    commit(t.LOAD_LEGAL_SPECS_STARTED)

    if (jurisdictionIds instanceof String) { jurisdictionIds = [jurisdictionIds] }

    const response = await http.get('client/legal_specs/index_structured', {
      params: {
        jurisdiction_ids: jurisdictionIds,
      },
    })

    const legalSpecs = response.data.result.legal_specs

    commit(t.SET_LEGAL_SPECS, legalSpecs)
    commit(t.LOAD_LEGAL_SPECS_FINISHED)
  },
  async loadTosDocs({ commit }, { jurisdictionIds }) {
    commit(t.LOAD_TOS_DOCS_STARTED)

    if (jurisdictionIds instanceof String) { jurisdictionIds = [jurisdictionIds] }

    const response = await http.get('client/tos_documents', {
      params: {
        jurisdiction_ids: jurisdictionIds,
      },
    })

    const tosDocuments = response.data.result.tos_documents

    commit(t.SET_TOS_DOCS, tosDocuments)
    commit(t.LOAD_TOS_DOCS_FINISHED)
  },
  async determineLawOnCallTrialEligibility({ commit }, company_id) {
    const result = await http.get(`/client/trial_eligibility/${company_id}/law-on-call`)
    const is_eligible = result.data.response.is_eligible

    commit(t.SET_TRIAL_ELIGIBILITY, is_eligible)
  },
  setTrialEligibility({ commit }, eligible) {
    commit(t.SET_TRIAL_ELIGIBILITY, eligible)
  },
}

const MUTATIONS = {
  [t.LOAD_LEGAL_SPECS_STARTED](state) {
    state.legalSpecsLoaded = false
  },
  [t.LOAD_LEGAL_SPECS_FINISHED](state) {
    state.legalSpecsLoaded = true
  },
  [t.SET_LEGAL_SPECS](state, legalSpecs) {
    state.legalSpecs = legalSpecs
  },
  [t.LOAD_TOS_DOCS_STARTED](state) {
    state.tosDocsLoaded = false
  },
  [t.LOAD_TOS_DOCS_FINISHED](state) {
    state.tosDocsLoaded = true
  },
  [t.SET_TOS_DOCS](state, tosDocs) {
    state.tosDocs = tosDocs
  },
  [t.SET_TRIAL_ELIGIBILITY](state, eligible) {
    state.lawOnCallTrialEligible = eligible
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
