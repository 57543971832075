import * as t from '../mutations'
import http from '@/http'
import { setClientEnvironmentData } from '@/common/modules/clientEnvironmentData'

const initialState = () => ({
  // *********************** Common properties *****************************************************
  clientFeedbackOptions: [],
  clientFeedbackSources: [],
  clientData: null,
  textAreaCharacterMax: 500,
  allClientFeedbackByCompanyId: [],

  // *********************** Sidebar-specific properties *******************************************
  showClientFeedbackSidebar: true,
  sidebarSourceId: null,
  allClientFeedbackForSidebar: [],

  // *********************** End of Stage-specific properties **************************************
  clientSavedFeedback: false,
  clientFeedbackChoice: '',
  endOfStageSourceId: null,
  allClientFeedbackForEndOfStage: [],
  currentClientFeedbackObj: null,
  processedClientFeedback: true,
  showEndOfStageClientFeedbackTool: true,
})

const STATE = initialState()

const GETTERS = {
  // *********************** Common properties *****************************************************
  clientData:                     state => state.clientData,
  clientFeedbackOptions:          state => state.clientFeedbackOptions,
  clientFeedbackSources:          state => state.clientFeedbackSources,
  textAreaCharacterMax:           state => state.textAreaCharacterMax,
  allClientFeedbackByCompanyId:   state => state.allClientFeedbackByCompanyId,

  // *********************** Sidebar-specific properties *******************************************
  sidebarSourceId:                state => state.sidebarSourceId,
  allClientFeedbackForSidebar:    state => state.allClientFeedbackForSidebar,
  showClientFeedbackSidebar:      (_state, getters) => {
    if (!getters.sidebarSourceId) return false
    return !getters.allClientFeedbackForSidebar.some(cf => cf.object_id === getters.currentStepId)
  },

  // *********************** End of Stage-specific properties **************************************
  allClientFeedbackForEndOfStage:   state => state.allClientFeedbackForEndOfStage,
  currentClientFeedbackObj:         state => state.currentClientFeedbackObj,
  clientFeedbackChoice:             state => state.clientFeedbackChoice,
  clientSavedFeedback:              state => state.clientSavedFeedback,
  endOfStageSourceId:               state => state.endOfStageSourceId,
  processedClientFeedback:          state => state.processedClientFeedback,
  showEndOfStageClientFeedbackTool: (_state, getters) => {
    return !!getters.endOfStageSourceId
  },

  // *********************** Mapped from other stores **********************************************
  currentCompanyId: (_state, _getters, _rootState, rootGetters) => rootGetters['companies/currentCompanyId'],
  currentStageId:   (_state, _getters, _rootState, rootGetters) => {
    return rootGetters['stageline/currentStage']?.id
  },
  currentStepId:    (_state, _getters, _rootState, rootGetters) => {
    return rootGetters['stageline/currentStep']?.id
  },
}

const ACTIONS = {
  // *********************** Common properties *****************************************************
  async setClientFeedbackOptions({ commit }) {
    try {
      const url = 'client/stageline/client_feedback_options'
      const response = await http.get(url)
      const result = response.data.result

      commit(t.SET_CLIENT_FEEDBACK_OPTIONS, result)
    } catch(_error) {
      // suppress error
    }
  },
  async setClientFeedbackSources({ commit, dispatch }) {
    try {
      const url = 'client/stageline/client_feedback_sources'
      const response = await http.get(url)
      const result = response.data.result

      commit(t.SET_CLIENT_FEEDBACK_SOURCES, result)

      await dispatch('setClientFeedbackSourceForCategory', { result, category: 'step' })
      await dispatch('setClientFeedbackSourceForCategory', { result, category: 'stage' })
    } catch (_error) {
      // suppress error
    }
  },
  setClientFeedbackSourceForCategory: ({ commit, getters }, { result, category }) => {
    if (result.length) {
      const findSource = getters.clientFeedbackSources.find(fbSource => fbSource.category === category)

      if (findSource?.id) {
        const mutationType = category === 'step' ?
          t.SET_CLIENT_FEEDBACK_SIDEBAR_SOURCE_ID :
          t.SET_CLIENT_FEEDBACK_END_OF_STAGE_SOURCE_ID

        commit(mutationType, findSource.id)
      }
    }
  },
  setClientFeedbackClientData({ commit }) {
    const clientData = setClientEnvironmentData()

    commit(t.SET_CLIENT_FEEDBACK_CLIENT_DATA, clientData)
  },
  async setAllClientFeedbackByCompanyId({ commit, getters, dispatch }) {
    try {
      const url = `client/stageline/${getters.currentCompanyId}/client_feedback`
      const response = await http.get(url)
      const result = response.data.result

      commit(t.SET_ALL_CLIENT_FEEDBACK_BY_COMPANY_ID, result)

      await dispatch('setAllClientFeedbackForCategory', { result, category: 'step' })
      await dispatch('setAllClientFeedbackForCategory', { result, category: 'stage' })
    } catch (_error) {
      // suppress error
    }
  },
  setAllClientFeedbackForCategory: ({ commit, getters }, { result, category }) => {
    if (result.length) {
      const cfSource = getters.clientFeedbackSources.find(source => source.category === category)
      const filtered = result.filter(cf => cf.sl_client_feedback_sources_id === cfSource.id)
      const mutationType = category === 'step' ?
        t.SET_ALL_CLIENT_FEEDBACK_FOR_SIDEBAR :
        t.SET_ALL_CLIENT_FEEDBACK_FOR_END_OF_STAGE

      commit(mutationType, filtered)
    }
  },
  async saveClientFeedback({ getters, commit, dispatch }, params) {
    commit(t.SET_PROCESSED_CLIENT_FEEDBACK, false)

    try {
      const clientFeedback = {
        client_data:  getters.clientData,
        comment:      params['comment'] || null,
        liked:        params['liked'],
        object_id:    params['source_category'] === 'stage' ? getters.currentStageId : getters.currentStepId,
        object_table: params['source_category'],
        options_id:   params['options_id'] || null,
        source_id:    params['source_category'] === 'stage' ? getters.endOfStageSourceId : getters.sidebarSourceId,
      }
      const url = `client/stageline/${getters.currentCompanyId}/client_feedback`
      const response = await http.post(url, clientFeedback)

      if (response.data.success) {
        await dispatch('setAllClientFeedbackByCompanyId')

        if(params['source_category'] === 'stage') {
          dispatch('setAllClientFeedbackForEndOfStage')
          dispatch('setCurrentClientFeedbackByStageId')
        }
      }
    } catch(_error) {
      // suppressing error
    }

    commit(t.SET_PROCESSED_CLIENT_FEEDBACK, true)
  },
  async updateClientFeedback({ getters, commit, dispatch }, params) {
    commit(t.SET_PROCESSED_CLIENT_FEEDBACK, false)

    try {
      const clientFeedback = {
        comment:      params['comment'] || null,
        liked:        params['liked'],
        options_id:   params['options_id'] || null,
      }
      const url = `client/stageline/${getters.currentCompanyId}/client_feedback/${getters.currentClientFeedbackObj['id']}`
      const response = await http.put(url, clientFeedback)

      if (response.data.success) {
        if(response.data.result.object_table === 'sl_stages') {
          await dispatch('updateEndOfStageFeedbackLists', response.data.result)
          dispatch('initializeClientFeedbackValues')
        }
      }
    } catch(_error) {
      // suppressing error
    }

    commit(t.SET_PROCESSED_CLIENT_FEEDBACK, true)
  },

  // *********************** Sidebar-specific properties *******************************************
  setClientFeedbackShowSidebar({ commit, getters }) {
    const foundRecord = getters.allClientFeedbackForSidebar.some(cf => cf.object_id === getters.currentStepId)
    commit(t.SET_CLIENT_FEEDBACK_SHOW_SIDEBAR, !foundRecord)
  },

  // *********************** End of Stage-specific properties **************************************
  async updateEndOfStageFeedbackLists({ dispatch }) {
    await dispatch('setAllClientFeedbackByCompanyId')
    dispatch('setAllClientFeedbackForEndOfStage')
    dispatch('setCurrentClientFeedbackByStageId')
  },
  setAllClientFeedbackForEndOfStage({ commit, getters }){
    if (getters.allClientFeedbackByCompanyId.length) {
      const filtered = getters.allClientFeedbackByCompanyId.filter(cf => cf.object_table === 'sl_stages')
      commit(t.SET_ALL_CLIENT_FEEDBACK_FOR_END_OF_STAGE, filtered)
    }
  },
  setCurrentClientFeedbackByStageId({ commit, getters }) {
    if(getters.allClientFeedbackForEndOfStage.length) {
      const feedbackObj = getters.allClientFeedbackForEndOfStage.find(fbObj => fbObj.object_id === getters.currentStageId)
      commit(t.SET_CURRENT_CLIENT_FEEDBACK_OBJ, feedbackObj)
    }
  },
  initializeClientFeedbackValues({ dispatch, getters }) {
    const data = getters.currentClientFeedbackObj
    let choice = ''

    if(data) {
      if(data.sl_client_feedback_options_id || data.comment?.length){
        dispatch('setClientSavedFeedback', true)
        return
      }

      choice = data.liked ? 'thumbs-up' : 'thumbs-down'
    }
    dispatch('setClientFeedbackChoice', choice)
    dispatch('setClientSavedFeedback', false)
  },
  setClientFeedbackChoice({ commit }, choice) {
    commit(t.SET_CLIENT_FEEDBACK_CHOICE, choice)
  },
  setClientSavedFeedback({ commit }, saved) {
    commit(t.SET_CLIENT_SAVED_FEEDBACK, saved)
  },
}

const MUTATIONS = {
  // *********************** Common properties *****************************************************
  [t.SET_CLIENT_FEEDBACK_OPTIONS](state, options) {
    state.clientFeedbackOptions = options
  },
  [t.SET_CLIENT_FEEDBACK_SOURCES](state, sources) {
    state.clientFeedbackSources = sources
  },
  [t.SET_CLIENT_FEEDBACK_CLIENT_DATA](state, clientData) {
    state.clientData = clientData
  },
  [t.SET_ALL_CLIENT_FEEDBACK_BY_COMPANY_ID](state, list) {
    state.allClientFeedbackByCompanyId = list
  },

  // *********************** Sidebar-specific properties *******************************************
  [t.SET_CLIENT_FEEDBACK_SHOW_SIDEBAR](state, value) {
    state.showClientFeedbackSidebar = value
  },
  [t.SET_CLIENT_FEEDBACK_SIDEBAR_SOURCE_ID](state, source) {
    state.sidebarSourceId = source.id
  },
  [t.SET_ALL_CLIENT_FEEDBACK_FOR_SIDEBAR](state, list) {
    state.allClientFeedbackForSidebar = list
  },

  // *********************** End of Stage-specific properties **************************************
  [t.SET_CLIENT_FEEDBACK_END_OF_STAGE_SOURCE_ID](state, id) {
    state.endOfStageSourceId = id
  },
  [t.SET_ALL_CLIENT_FEEDBACK_FOR_END_OF_STAGE](state, list) {
    state.allClientFeedbackForEndOfStage = list
  },
  [t.SET_CLIENT_SAVED_FEEDBACK](state, saved) {
    state.clientSavedFeedback = saved
  },
  [t.SET_CURRENT_CLIENT_FEEDBACK_OBJ](state, feedBackObj) {
    state.currentClientFeedbackObj = feedBackObj
  },
  [t.SET_CLIENT_FEEDBACK_CHOICE](state, choice) {
    state.clientFeedbackChoice = choice
  },
  [t.SET_PROCESSED_CLIENT_FEEDBACK](state, processed) {
    state.processedClientFeedback = processed
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
