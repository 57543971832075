import * as t from '../mutations'

import {
  state as BaseState,
  actions as BaseActions,
  getters as BaseGetters,
  mutations as BaseMutations,
} from '@/store/base'
import http from '@/http'

const STATE = {
  ...BaseState,
  feedNotifications: [],
  feedExpiration: null,
  feedUnreadCount: 0,
}

const GETTERS = {
  ...BaseGetters,
  feedNotifications: state => state.feedNotifications,
  feedExpiration: state => state.feedExpiration,
  feedUnreadCount: state => state.feedNotifications.filter(notification => notification.status === 'unread').length,
}

const ACTIONS = {
  ...BaseActions,
  refreshFeedNotifications: async function ({ dispatch, rootGetters, state }) {
    if (!rootGetters['paymentMethods/isGuestPay'] && (!state.feedExpiration || state.feedExpiration < new Date().getTime())) {
      try {
        const res = await http.get(`client/feed_notifications`)

        dispatch('setFeedNotifications', res.data.response || [])
        dispatch('setFeedExpiration')
      } catch (e) {
        console.error('Error while fetching feed notifications', e)
      }
    }
  },
  updateNotificationStatus: async function ({ dispatch }, { notificationId, notificationStatus }) {
    const res = await http.patch(`client/feed_notifications/update_status`, {
      accounts_feed_notification_id: notificationId,
      status: notificationStatus,
    })

    dispatch('setFeedNotificationStatus', { notificationId: res.data.response[0].accounts_feed_notification_id, notificationStatus: res.data.response[0].status })
  },
  markAllNotificationsAsRead: async function ({ dispatch }) {
    const res = await http.patch(`client/feed_notifications/mark_all_as_read`)

    dispatch('setFeedNotifications', res.data.response || [])
    dispatch('setFeedExpiration')
  },
  setFeedNotifications({ commit }, notifications) {
    if (Array.isArray(notifications)) {
      commit(t.SET_FEED_NOTIFICATIONS, notifications)
    }
  },
  setFeedExpiration({ commit }) {
    let time30MinutesFromNow = new Date(new Date().getTime() + 30 * 60000).getTime()
    commit(t.SET_FEED_EXPIRATION, time30MinutesFromNow)
  },
  setFeedNotificationStatus({ state }, { notificationId, notificationStatus }) {
    const notification = state.feedNotifications.find(notification => notification.accounts_feed_notification_id === notificationId)

    if (notification) {
      notification.status = notificationStatus
    } else {
      console.error('Notification not found')
    }
  },
}

const MUTATIONS = {
  ...BaseMutations,
  [t.SET_FEED_NOTIFICATIONS](state, notifications) {
    state.feedNotifications = notifications
  },
  [t.SET_FEED_EXPIRATION](state, time30MinutesFromNow) {
    state.feedExpiration = time30MinutesFromNow
  },

}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
